<template>
  <div>
    <div
      class="d-flex flex-column botoes"
      v-for="(botao, i) in iniciativa.botoes"
      v-bind:key="i"
    >
      <div v-if="botao.texto">
        <v-btn
          text
          color="blue"
          v-if="botao.type == 'URL'"
          @click="urlBotao(botao?.url)"
          class="call-section"
        >
          <v-icon left>mdi-open-in-new</v-icon>
          {{ botao.texto }}
        </v-btn>
        <v-btn
          text
          color="blue"
          v-if="botao.type == 'PHONE_NUMBER'"
          class="call-section"
        >
          <v-icon left>mdi-phone</v-icon>
          {{ botao.texto }}
        </v-btn>
        <div v-if="botao.type == 'QUICK_REPLY'" class="d-flex flex-grow">
          <v-btn class="botao-resposta-rapida blue--text">
            {{ botao.texto }}
          </v-btn>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    iniciativa: null,
  },
  methods: {
    urlBotao(url) {
      if (!url) return;
      window.open(url, "_blank");
    },
  },
};
</script>

<style lang="scss" scoped>
.botoes {
  margin: 0.25rem 0;
  .call-section {
    background-color: whitesmoke;
    border-radius: 5px;
  }

  .call-section,
  .botao-resposta-rapida {
    height: auto !important;
    width: 100%;
    flex: unset;
    min-height: 36px;
    flex-grow: unset;
    white-space: unset;
    box-shadow: none;
    padding: 0.275rem 0.5rem;
  }
}
</style>

<style lang="scss">
.call-section,
.botao-resposta-rapida {
  span {
    flex: unset;
  }
}
</style>