<template>
  <!-- GRAFICO EM BARRA - CONTRATOS POR ORIGEM-->
  <v-card
    class="rounded-lg d-flex align-center pt-3 mr-7 mb-7"
    style="width: 450px"
    :style="contratos?.length > 10 ? { height: '610px' } : { height: '550px' }"
  >
    <div v-if="carregandoContrato" class="loading">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-col
      v-if="
        contratos?.length > 0 && !erroCarregarContrato && !carregandoContrato
      "
      class="pb-0"
    >
      <v-chart class="bar-chart" :option="grafico.origem_contrato" />
      <div class="d-flex justify-center" v-if="contratos?.length > 10">
        <v-btn text color="primary" @click="carregarTodosValores()">
          ver mais
        </v-btn>
      </div>
    </v-col>

    <v-alert
      style="width: fit-content"
      type="error"
      outlined
      class="text-center mx-10"
      v-if="erroCarregarContrato && !carregandoContrato"
    >
      Não foi possível carregar!
      <v-btn
        color="primary"
        class="ml-1 mt-2"
        @click="carregaContratosPorOrigem(dataSelecionada)"
      >
        Tentar novamente
      </v-btn>
    </v-alert>

    <v-alert
      type="info"
      class="ml-13"
      outlined
      style="width: fit-content"
      v-if="
        contratos?.length == 0 && !erroCarregarContrato && !carregandoContrato
      "
    >
      Nenhum dado para a data selecionada.
    </v-alert>
    <VerMaisGraficos
      ref="verMaisDialog"
      :dataReferencia="dataReferencia"
      :grafico="'origem'"
    />
  </v-card>
</template>

<script>
import VerMaisGraficos from "../componentes/VerMaisGrafico.vue";
import dashboardService from "@/services/dashboards";
import { mapGetters } from "vuex";

export default {
  props: {
    dataSelecionada: null,
    dataReferencia: null,
  },
  components: {
    VerMaisGraficos,
  },
  data: () => ({
    grafico: {
      origem_contrato: {
        tooltip: {
          formatter: "{b}",
        },
        grid: {
          left: 120,
        },
        title: {
          text: "Contrato por Origem",
          left: "center",
        },
        xAxis: {
          type: "value",
          axisLabel: {
            formatter: (item) => item.toLocaleString("pt-BR"),
            show: true,
          },
        },
        yAxis: {
          type: "category",
          data: [],
          inverse: true,
          axisLabel: {
            show: true,
            fontSize: 16,
            overflow: "truncate",
            width: 100,
          },
        },
        series: [
          {
            data: [],
            type: "bar",
            label: {
              normal: {
                position: "right",
                distance: 10,
                show: true,
                formatter: (item) => item.value.toLocaleString("pt-BR"),
              },
            },
          },
        ],
      },
    },
    contratos: null,
    carregandoContrato: false,
    erroCarregarContrato: false,
  }),
  created() {
    if (!this.dataSelecionada) {
      this.carregaContratosPorOrigem("ultima_atualizacao");
    } else {
      this.carregaContratosPorOrigem(this.dataSelecionada);
    }
  },
  methods: {
    async carregaContratosPorOrigem(data) {
      this.carregandoContrato = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        data_referencia: data,
        parametro: "origem",
      };
      try {
        const { data } = await dashboardService.getCarteiraClientes(payload);
        this.contratos = data.origem;
        this.adicionaValorGrafico();
        this.carregandoContrato = false;
        this.erroCarregarContrato = false;
      } catch {
        this.carregandoContrato = false;
        this.erroCarregarContrato = true;
      }
    },
    adicionaValorGrafico() {
      if (!this.contratos) return;
      let contratosOrdenados = [...this.contratos];

      // limpa os dados antes de adicionar
      this.grafico.origem_contrato.yAxis.data = [];
      this.grafico.origem_contrato.series[0].data = [];

      contratosOrdenados.sort((a, b) => (a.total < b.total ? 1 : -1));

      //faz o FOR dentro dos contratos e adiciona no gráfico
      contratosOrdenados.slice(0, 10).forEach((origem) => {
        if (origem.total > 0) {
          this.grafico.origem_contrato.yAxis.data.push(origem.nome);
          this.grafico.origem_contrato.series[0].data.push(origem.total);
        }
      });
    },
    carregarTodosValores() {
      this.$refs.verMaisDialog.abrirDialog();
    },
  },
  watch: {
    dataSelecionada() {
      this.carregaContratosPorOrigem(this.dataSelecionada);
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregaContratosPorOrigem(this.dataSelecionada);
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style scoped lang="scss">
.loading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.tamanhoCardGrafico {
  height: 260px;
  width: 400px;
}

.bar-chart {
  height: 540px;
  width: 400px;
}
</style>
