<template>
  <div>
    <div class="table-section">
      <p class="section-title">Variáveis Pré-definidas</p>
      <TabelaVariaveis :cabecalhos="cabecalhosPreDefinidas" :variaveis="variaveisPreDefinidas"
        :carregando="carregandoVariaveis" />

    </div>
    <div class="table-section" v-if="variaveisDinamicas.length">
      <p class="section-title">Variáveis Dinâmicas</p>
      <TabelaVariaveis :cabecalhos="cabecalhosVariaveis" :variaveis="variaveisDinamicas" @abrirDialog="abrirDialog"
        @alterarStatus="alterarStatus" @excluir="excluirVariavel" :carregando="carregandoVariaveis" />
    </div>
    <DialogVariavel ref="dialogModificar" @adicionar="criarVariavel" @editar="editarVariavel" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import TabelaVariaveis from './TabelaVariaveis.vue';
import DialogVariavel from "../components/dialogs/DialogVariavel.vue";

export default {
  components: {
    TabelaVariaveis,
    DialogVariavel,
  },
  data() {
    return {
      cabecalhosPreDefinidas: [
        {
          text: 'Nome',
          align: 'start',
          value: 'nome',
        },
        { text: 'Exemplo', value: 'exemplo' },
      ],
      cabecalhosVariaveis: [
        {
          text: 'Nome',
          align: 'start',
          value: 'nome',
        },
        { text: 'Referência', value: 'referencia' },
        { text: 'Atualizado em', value: 'atualizado_em' },
        { text: '', value: 'actions', sortable: false },
      ],
    }
  },
  computed: {
    ...mapGetters("variavelRegua", {
      carregandoVariaveis: "carregando",
      variaveisPreDefinidas: "variaveisPreDefinidas",
      variaveisDinamicas: "variaveisDinamicas",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  async created() {
    await this.atualizarVariaveis();
  },
  methods: {
    ...mapActions("variavelRegua", {
      pegarVariaveis: "pegar",
      criarVariavel: "criar",
      editarVariavel: "editar",
      excluirVariavel: "excluir",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    atualizarVariaveis() {
      this.pegarVariaveis({
        empresa_id: this.empresaSelecionada.id,
        tipo_regua: this.$route.query.tipo,
        limite: 10000,
      });
    },
    abrirDialog(opcoes) {
      return this.$refs.dialogModificar.abrir(opcoes);
    },
    alterarStatus(variavel) {
      let variavelInativa = { ...variavel };
      variavelInativa.ativa = !variavel.ativa;
      this.editarVariavel(variavelInativa);
    },
  }
}
</script>

<style lang="scss" scoped>
.table-section {
  width: 1000px;
  max-width: 100%;
  margin: 40px auto;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  line-height: 2rem;
}

tr .action-button {
  visibility: hidden;
}

tr:hover .action-button {
  visibility: visible;
}
</style>