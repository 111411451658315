/** Tipos de eventos da régua. */
const TIPO_EVENTO = [
  'SMS',
  'EMAIL',
  'PUSH',
  'LIGACAO_CELULAR',
  'LIGACAO_FIXO',
  'WHATSAPP',
  'RCS',
  'COMANDO',
];

export {
  TIPO_EVENTO,
}
