<template>
  <v-dialog
    v-model="dialog"
    :width="tipoEvidencia == 'EMAIL' ? 720 : 600"
    :scrollable="tipoEvidencia == 'WHATSAPP' || tipoEvidencia == 'EMAIL'"
    @click:outside="fecharDialog()"
  >
    <v-card>
      <v-card-title>
        <div class="card-titulo">
          <div class="text-h5">Evidência de {{ tituloDaEvidencia }}</div>
          <v-btn
            color="primary"
            width="211"
            text
            :disabled="naoExisteEvidencia || !dadosEvidencia"
            @click="mudarVisualizacao()"
            v-if="tipoEvidencia != 'COMANDO'"
          >
            VISUALIZAR {{ mostrarJson ? "CONTEÚDO" : "JSON" }}
          </v-btn>
        </div>
      </v-card-title>
      <v-card-text>
        <div
          v-if="tipoDoConteudo == 'json' || tipoEvidencia == 'COMANDO'" 
          :style="tipoDoConteudo !='whatsapp' ? 'height: 60vh' : ''"
          class="div-padrao"
        >
          <v-progress-circular
            :size="40"
            indeterminate
            v-if="estaCarregando"
          >
          </v-progress-circular>
          <vue-json-pretty
            :data="dadosEvidencia"
            v-else
            showIcon
            showLineNumber
            showLength
            :deep="3"
          />
        </div>
        <div v-else class="div-padrao flex-column" style="min-height: 60vh">
          <p v-if="naoExisteEvidencia">
            Não foi encontrada uma evidência para esta notificação
          </p>
          <v-progress-circular
            v-else-if="tipoDoConteudo == 'carregando'"
            :size="40"
            indeterminate
          >
          </v-progress-circular>
          <FrameEmail
            v-if="tipoDoConteudo == 'email'"
            :evidencia="dadosEvidencia"
            :template="templateEmail"
          />
          <FramePush
            v-else-if="tipoDoConteudo == 'push'"
            :evidencia="dadosEvidencia"
          />
          <FrameSMS
            v-else-if="tipoDoConteudo == 'sms'"
            :evidencia="dadosEvidencia"
          />
          <IniciativaWhatsapp 
            v-else-if="tipoDoConteudo == 'whatsapp'"
            :iniciativa="dadosEvidencia?.iniciativa_whatsapp"
          />
        </div>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fecharDialog()">Fechar</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import FrameEmail from "./FrameEmail.vue";
import FramePush from "./FramePush.vue";
import FrameSMS from "./FrameSMS.vue";
import IniciativaWhatsapp from "@/views/reguas/components/previewIniciativaWhatsapp/PreviewIniciativaWhatsapp.vue";
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
    FrameEmail,
    FramePush,
    FrameSMS,
    IniciativaWhatsapp,
  },
  props: ["service"],
  data() {
    return {
      dialog: false,
      idEvidencia: null,
      tipoEvidencia: null,
      mostrarJson: false,
      conteudoEvidencia: null,
      estruturaEvidencia: null,
      dadosEvidencia: null,
      naoExisteEvidencia: false,
      estaCarregando: false,
      templateEmail: null,
    };
  },
  computed: {
    tipoDoConteudo() {
      if (this.estaCarregando) return "carregando";
      if (this.mostrarJson) return "json";
      if (this.tipoEvidencia == "EMAIL") return "email";
      if (["SMS", "FAST_SMS"].includes(this.tipoEvidencia)) return "sms";
      if (this.tipoEvidencia == "PUSH") return "push";
      if (this.tipoEvidencia == "WHATSAPP") return "whatsapp";
      return "outro canal";
    },
    tituloDaEvidencia() {
      switch (this.tipoEvidencia) {
        case "EMAIL":
          return "EMAIL";
        case "SMS":
          return "SMS";
        case "FAST_SMS":
          return "FAST SMS";
        case "PUSH":
          return "PUSH";
        case "WHATSAPP":
          return "Whatsapp";
        case "COMANDO":
          return "Comando";
        default:
          return "";
      }
    },
  },
  methods: {
    mudarVisualizacao() {
      this.mostrarJson = !this.mostrarJson;
    },
    async buscarDados(id, tipo) {
      this.idEvidencia = id;
      this.tipoEvidencia = tipo;

      this.service
      .getEvidencia(id)
      .then((resposta) => {
        if (resposta?.status == 200) {
          this.dadosEvidencia = resposta?.data;
          this.templateEmail = resposta?.data?.email_base64 ? Buffer.from(resposta.data.email_base64, 'base64') : null
        } else {
          this.naoExisteEvidencia = true;
        }
      })
      .finally(() => {
        this.estaCarregando = false;
      });
    },
    resetarVariaveis() {
      this.mostrarJson = false;
      this.naoExisteEvidencia = false;
      this.dadosEvidencia = null;
      this.templateEmail = null;
    },
    abrirDialog(id, tipo) {
      this.dialog = true;
      this.estaCarregando = true;
      this.buscarDados(id, tipo);
    },
    fecharDialog() {
      this.dialog = false;
      setTimeout(() => {
        this.resetarVariaveis();
      }, 1000);
    },
  },
};
</script>

<style scoped lang="scss">
.vjs-tree {
  height: 60vh;
  overflow-y: auto;
  overflow-x: hidden;
}

::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}

.card-titulo {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-content: center;
  width: 100%;
}

.div-padrao {
  display: flex;
  justify-content: center;
  align-items: center;
  width:100%;
  p {
    margin-bottom: 0;
  }
}
</style>
