<template>
  <div>
    <v-app-bar app
      ><span class="text-h6 font-weight-bold ml-3"
        >{{ $route.meta.title }}
      </span>
    </v-app-bar>
    <v-container>
      <v-skeleton-loader
        v-if="loading"
        class="mx-auto"
        width="1000"
        type="table-tbody"
      ></v-skeleton-loader>
      <v-data-table
        class="elevation-1"
        v-show="!loading"
        :headers="headers"
        :items="faqsFiltradas"
        :options.sync="options"
        :server-items-length="paginator.num_results"
        loading-text="Carregando FAQs"
        :must-sort="false"
        locale="pt-BR"
        hide-default-footer
      >
        <template v-slot:top>
          <div class="px-2">
            <v-col cols="4">
              <v-select
                mandatory
                hide-details
                v-model="filtros.categoria"
                label="Categoria"
                :items="categorias"
              >
              </v-select>
            </v-col>
          </div>
        </template>
        <template v-slot:body="{ items }">
          <draggable
            @start="startDrag($event)"
            @end="drag = false"
            @update="salvaNovaOrdem"
            tag="tbody"
            v-model="faqsFiltradas"
            v-bind="dragOptions"
            draggable=".item"
          >
            <v-hover
              v-for="faq in items"
              class="item"
              :key="faq.id"
              v-slot="{ hover }"
            >
              <tr :class="{ 'text-gray-700': !podeEditarFaq(faq) }">
                <td class="pl-0">
                  <div
                    class="d-flex align-center"
                    :class="{ 'draggable-item': hover && podeEditarFaq(faq) }"
                  >
                    <v-icon v-show="hover && podeEditarFaq(faq)"
                      >mdi-drag-vertical</v-icon
                    >
                    <span :class="{ 'ml-6': !hover || !podeEditarFaq(faq) }"
                      >#{{ faq.id }}</span
                    >
                  </div>
                </td>
                <td>{{ faq.categoria }}</td>
                <td>
                  {{
                    chavesEmpresa.find(
                      (chave) => chave.value == faq.chave_empresa
                    )?.text || ""
                  }}
                </td>
                <td class="break-all">{{ faq.pergunta }}</td>
                <td class="">
                  <div class="d-flex align-center justify-space-between">
                    <v-tooltip top max-width="60vh">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs">{{
                          faq.resposta | truncateString(100)
                        }}</span>
                      </template>
                      <span class="break-all">{{ faq.resposta }}</span>
                    </v-tooltip>
                    <v-spacer></v-spacer>
                    <div
                      style="width: 40px"
                      class="grow d-flex justify-end"
                      v-if="podeEditarFaq(faq)"
                    >
                      <v-menu>
                        <template v-slot:activator="{ on }">
                          <v-btn v-on="on" v-show="hover" icon
                            ><v-icon>mdi-dots-vertical</v-icon></v-btn
                          >
                        </template>
                        <v-list class="pa-0">
                          <v-list-item>
                            <v-btn plain @click="abrirDialogFaq(faq)">
                              EDITAR
                            </v-btn>
                          </v-list-item>
                          <v-list-item>
                            <v-btn plain @click="excluirFaq(faq)">
                              REMOVER
                            </v-btn>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </div>
                  </div>
                </td>
              </tr>
            </v-hover>
          </draggable>
        </template>
      </v-data-table>
    </v-container>
    <FloatingBtn @click="abrirDialogFaq()" />
    <DialogFaq
      :chaves="chavesEmpresa"
      :categorias="categorias"
      ref="DialogFaq"
    />
    <DialogDeletaFaq ref="DialogDeletaFaq"></DialogDeletaFaq>
  </div>
</template>

<script>
import DialogDeletaFaq from "./components/DialogDeletaFaq.vue";
import { checkCredentials } from "@/assets/js/utils";
import { mapActions, mapGetters, mapMutations } from "vuex";
import draggable from "vuedraggable";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";
import DialogFaq from "./components/DialogFaq.vue";

export default {
  components: {
    draggable,
    FloatingBtn,
    DialogFaq,
    DialogDeletaFaq,
  },
  data: () => {
    return {
      loading: false,
      faqsFiltradas: [],
      options: {},
      filtros: {},
      drag: false,
      headers: [
        { text: "", value: "id", sortable: false, align: "start", width: "4%" },
        {
          text: "Categoria",
          value: "categoria",
          sortable: false,
          align: "start",
          width: "10%",
        },
        {
          text: "Perfil",
          value: "chave_empresa",
          sortable: false,
          align: "start",
          width: "10%",
        },
        {
          text: "Pergunta",
          value: "pergunta",
          sortable: false,
          align: "start",
          width: "30%",
        },
        {
          text: "Resposta",
          value: "resposta",
          sortable: false,
          align: "start",
          width: "50%",
        },
      ],
    };
  },
  async created() {
    this.options = {
      itemsPerPage: 1000,
    };
  },
  mounted() {},
  methods: {
    ...mapActions("faq", ["getFaqs", "ordenarFaqs", "deletaFaq"]),
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapMutations("faq", ["setFaqs"]),
    async carregaFaqs() {
      try {
        this.loading = true;
        const params = this.makeGetParams();
        await this.getFaqs(params);
        this.setQueryParams();
        this.filtrarFaqs();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
        this.$vuetify.goTo("header");
      }
    },
    startDrag(event) {
      this.drag = false;
    },
    setQueryParams() {
      this.$router.push({ query: { ...this.filtros } }).catch(() => {});
    },
    podeEditarFaq(faq) {
      const faqPerfilEmpresa =
        this.empresaSelecionada.perfis_empresa?.some((perfil) => {
          return perfil.chave == faq.chave_empresa;
        }) ||
        faq.chave_empresa == this.empresaSelecionada.chave ||
        false;
      const podeEditarTodasFaqs = checkCredentials([
        "admin",
        "editar_faq_melembre",
      ]);
      const podeEditarFaqsEmpresa = checkCredentials([
        "admin",
        "editar_faq_empresa",
      ]);
      let podeEditar = false;
      if (podeEditarTodasFaqs) podeEditar = true;
      else if (faqPerfilEmpresa && podeEditarFaqsEmpresa) podeEditar = true;
      else podeEditar = false;
      return podeEditar;
    },
    makeGetParams() {
      const { itemsPerPage: limite, page: pagina } = this.options;
      return { limite, pagina, ordem: "ordem" };
    },

    async salvaNovaOrdem(event) {
      const faq = this.faqsFiltradas[event.newIndex];
      const ordem_antiga = faq.ordem;
      const nova_ordem =
        event.newIndex < event.oldIndex
          ? this.faqsFiltradas[event.newIndex + 1].ordem
          : this.faqsFiltradas[event.newIndex - 1].ordem;
      try {
        await this.ordenarFaqs({ id: faq.id, ordem_antiga, nova_ordem });
        this.showSnackBar({
          text: `FAQs ordenadas com sucesso!`,
        });
      } catch (e) {
        this.showSnackBar({
          text: `Falha ao ordenar as FAQs!`,
        });
        console.error(e);
      }
    },
    filtrarFaqs() {
      const faqsFiltradas = this.faqs.filter((faq) => {
        if (faq.categoria === this.filtros.categoria) {
          return true;
        } else if (
          (faq.categoria == "" || !faq.categoria) &&
          this.filtros.categoria == "Sem categoria"
        ) {
          return true;
        }
      });
      this.faqsFiltradas = faqsFiltradas;
    },
    abrirDialogFaq(faq = {}) {
      this.$refs.DialogFaq.open(structuredClone(faq), this.filtros.categoria);
    },
    async excluirFaq(faq) {
      const decision =
        await this.$refs.DialogDeletaFaq.openAndWaitForDecision();
      if (!decision) return;
      try {
        await this.deletaFaq(faq);
        this.showSnackBar({
          text: "Faq deletada com sucesso",
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({ text: "Falha ao deletar faq", type: "error" });
      }
    },
  },
  computed: {
    ...mapGetters("faq", ["paginator"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    ...mapGetters("faq", { faqsFromStore: "faqs" }),
    faqs: {
      get() {
        return this.faqsFromStore;
      },
      set(value) {
        this.setFaqs(value);
      },
    },
    dragOptions() {
      return {
        animation: 200,
        disabled: false,
        ghostClass: "ghost",
        handle: ".draggable-item",
      };
    },
    chavesEmpresa() {
      if (this.empresaSelecionada.perfis_empresa?.length > 0) {
        return this.empresaSelecionada.perfis_empresa.map((perfil) => {
          return {
            text: perfil.nome_longo,
            value: perfil.chave,
          };
        });
      } else {
        return [
          {
            text: this.empresaSelecionada.nome,
            value: this.empresaSelecionada.chave,
          },
        ];
      }
    },
    categorias() {
      const faqsComChaveEmpresaOuSemChave = this.faqs.filter((faq) => {
        return this.chavesEmpresa?.some((chave) => {
          return chave.value == faq.chave_empresa || !faq.chave_empresa;
        });
      });
      const categoriasSet = new Set(
        faqsComChaveEmpresaOuSemChave.map((faq) =>
          faq.categoria === "" ? "Sem categoria" : faq.categoria
        )
      );
      return Array.from(categoriasSet).sort();
    },
  },
  watch: {
    options: {
      deep: true,
      async handler(value) {
        await this.carregaFaqs(value);
      },
    },
    filtros: {
      deep: true,
      handler() {
        this.setQueryParams();
        this.filtrarFaqs();
      },
    },
    faqs: {
      deep: true,
      handler() {
        this.filtrarFaqs();
        if (!this.filtros.categoria) {
          this.filtros = {
            categoria: this.$route.query.categoria || this.categorias[0],
          };
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.draggable-item {
  cursor: grab;
}

.ghost {
  opacity: 0.5;
  background: #e7f7ff;
}
</style>
