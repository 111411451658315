<template>
  <div style="max-width: 1000px" class="mx-auto">
    <div
      v-if="
        !faturaParcial?.subtotal &&
        !carregandoItensFatura &&
        !carregandoFaturaParcial &&
        !erroCarregarFaturaParcial &&
        !erroCarregarItensFatura &&
        !faturaParcialNaoEncontrada
      "
      class="mx-auto d-flex align-center mt-6 elevation-0"
      style="height: 400px; background-color: transparent !important"
    >
      <v-alert
        type="info"
        class="mx-auto my-10 justify-center"
        outlined
        style="width: fit-content"
      >
        Ainda não há nenhum dado para ser mostrado.
      </v-alert>
    </div>

    <v-alert
      v-if="erroCarregarFaturaParcial || erroCarregarItensFatura"
      type="error"
      class="mx-auto my-10"
      outlined
      style="width: fit-content"
    >
      <div class="d-flex flex-column align-center justify-center">
        <div class="mb-2">Não foi possível carregar a fatura parcial.</div>
        <v-btn class="ml-n8" color="primary" @click="carregaFaturaParcial()">
          Tentar novamente
        </v-btn>
      </div>
    </v-alert>

    <v-alert
      v-if="faturaParcialNaoEncontrada"
      type="info"
      class="mx-auto my-10"
      outlined
      style="width: fit-content"
    >
      <div class="d-flex justify-center">
        <div>Não há uma fatura ativa no momento</div>
      </div>
    </v-alert>

    <v-progress-circular
      indeterminate
      v-if="carregandoFaturaParcial || carregandoItensFatura"
      color="primary"
      class="d-flex mx-auto my-16"
      size="60"
    ></v-progress-circular>

    <v-row
      class="mt-6 mb-4 d-flex elevation-0"
      color="transparent"
      v-if="
        !carregandoFaturaParcial &&
        !carregandoItensFatura &&
        itensFaturaFiltrado &&
        faturaParcial?.subtotal &&
        !erroCarregarFaturaParcial &&
        !faturaParcialNaoEncontrada
      "
    >
      <v-col v-if="faturaParcial?.subtotal">
        <div class="ml-1">
          <p class="mb-0 font-weight-bold text-h4">Fatura Atual</p>
          <p class="mes-fatura text-subtitle-2 mb-0 font-weight-regular">
            {{ mesFaturaParcial | moment("MMMM [de] YYYY") }} (#{{
              this.faturaParcial.id
            }})
          </p>
          <div class="mb-7">
            <p class="mb-0 font-weight-bold text-h3 mt-7 total">
              {{ faturaParcial?.subtotal | fomatadorReal }}
            </p>
            <p class="subtitle-ml mb-0 font-weight-regular">
              Apurado até
              {{ this.faturaParcial.periodo_fim | moment("DD [de] MMMM") }}
              <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on"
                    >mdi-help-circle-outline</v-icon
                  >
                </template>
                <span>O valor é calculado no final do dia.</span>
              </v-tooltip>
            </p>
          </div>
          <div class="d-flex">
            <div v-if="franquia">
              <p class="mb-0 font-weight-bold text-h5">
                {{ franquia?.total | fomatadorReal }}
              </p>
              <p class="subtitle-ml mb-0 font-weight-regular">
                {{ franquia.quantidade | numero }}
                {{ franquia.quantidade > 1 ? "orquestrados" : "orquestrado" }}
              </p>
              <p
                class="subtitle-ml mb-0 font-weight-regular"
                v-if="
                  franquia?.valor_unitario &&
                  empresaSelecionada?.tipo_calculo_franquia == 'DIRETA'
                "
              >
                {{
                  this.franquia?.valor_unitario ? descricaoValorFranquia : ""
                }}
              </p>
              <template v-if="faturaParcial.descontos > 1">
                <p class="mb-0 font-weight-bold text-h5">
                  {{ -parseFloat(faturaParcial.descontos) | fomatadorReal }}
                </p>
                <p class="subtitle-ml mb-0 font-weight-regular">
                  Desconto em fatura
                </p>
              </template>
            </div>
            <div class="d-flex flex-column justify-center align-center mx-4">
              <div class="divisor_fatura"></div>
              <span class="divisor_plus">+</span>
              <div class="divisor_fatura"></div>
            </div>
            <div>
              <p class="mb-0 font-weight-bold text-h5">
                {{ totalEventos.total | fomatadorReal }}
              </p>
              <p class="subtitle-ml mb-0 font-weight-regular">
                {{ totalEventos.eventos | numero }} Eventos
              </p>
            </div>
          </div>
        </div>
      </v-col>
      <v-col
        v-if="!carregandoItensFatura && itensFaturaFiltrado && faturaParcial"
        class="pa-0 d-flex flex-column"
      >
        <GraficoServicos
          ref="dialogFecharFatura"
          :itensFatura="itensFaturaFiltrado"
          :carregando="false"
        />
        <v-btn
          small
          text
          color="primary"
          class="ml-auto mr-6"
          @click="abrirDialogPrecificacao()"
          >Precificação</v-btn
        >
      </v-col>
    </v-row>

    <GraficoPorDia
      v-if="
        faturaParcial?.analitico &&
        !carregandoFaturaParcial &&
        !erroCarregarFaturaParcial &&
        !faturaParcialNaoEncontrada
      "
      :notificacoes="analiticoFiltrado"
      :dataFatura="faturaParcial.periodo_fim"
      :inicioFatura="faturaParcial.periodo_inicio"
      :parcial="true"
    />
    <v-divider class="mx-auto"></v-divider>
    <v-alert
      v-if="erroCarregarFaturas"
      type="error"
      class="mx-auto my-10 justify-center"
      outlined
      style="width: fit-content"
    >
      <div class="mb-2">Não foi possível carregar as faturas.</div>
      <v-btn color="primary" @click="carregaTodasFaturas()">
        Tentar novamente
      </v-btn>
    </v-alert>

    <v-row class="elevation-0" color="transparent" v-if="!erroCarregarFaturas">
      <v-data-table
        :headers="cabecalhoFatura"
        :items="faturas"
        :options.sync="opcoesTabela"
        class="mt-5 mx-auto mb-10 elevation-0"
        style="background-color: transparent"
        :hide-default-header="listaFaturasVazia"
        :hide-default-footer="listaFaturasVazia"
        :server-items-length="totalFaturas"
        :footer-props="{
          'items-per-page-options': [12, 24, -1],
          'items-per-page-text': 'Faturas por página',
          'items-per-page-all-text': 'Todos',
        }"
      >
        <template v-slot:top>
          <v-toolbar
            flat
            class="pt-2 mb-5"
            :class="{ 'w-982': listaFaturasVazia }"
            color="transparent"
          >
            <v-toolbar-title class="text-h4 font-weight-bold"
              >Histórico de Faturas</v-toolbar-title
            >
            <v-spacer></v-spacer>
            <v-col
              class="text-right pr-0"
              cols="5"
              v-if="empresaEmailsCobranca?.length"
            >
              <v-toolbar-title
                ><a
                  style="cursor: default"
                  class="body-1 text-decoration-underline"
                  >{{ stringEmailsCobranca }}</a
                ></v-toolbar-title
              >
              <v-toolbar-title style="font-size: 12px"
                >E-mail para Faturamento</v-toolbar-title
              >
            </v-col>
            <v-col
              class="pa-0"
              style="flex-grow: 0"
              v-if="empresaEmailsCobranca?.length"
            >
              <v-btn
                icon
                @click="abrirDialogEmailCobranca(empresaEmailsCobranca)"
              >
                <v-icon v-if="checkCredentials(['admin', 'editor_fatura'])">
                  mdi-pencil
                </v-icon>
                <v-icon v-else> mdi-email </v-icon>
              </v-btn>
            </v-col>
            <v-col
              style="flex-grow: 0"
              v-else-if="checkCredentials(['admin', 'editor_fatura'])"
            >
              <v-btn
                text
                color="primary"
                @click="abrirDialogEmailCobranca(empresaEmailsCobranca, true)"
              >
                Adicionar remetente
              </v-btn>
            </v-col>
          </v-toolbar>
          <v-col v-if="carregandoFaturas">
            <v-progress-linear
              indeterminate
              color="primary"
            ></v-progress-linear>
            <p class="fatura-sem-dados text-center mt-2 pb-2">
              Carregando faturas...
            </p>
          </v-col>
          <v-col
            v-else-if="listaFaturasVazia"
            class="d-flex justify-center mt-3"
          >
            <p class="fatura-sem-dados">Nenhuma fatura encontrada</p>
          </v-col>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.id"
              @mouseover="selecionaItem(item)"
              @mouseleave="desselecionarItem()"
            >
              <td style="font-size: 12px">#{{ item.id }}</td>
              <td class="nota-fiscal text-center">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <p class="texto" v-bind="attrs" v-on="on">
                      {{ item?.nota_fiscal }}
                    </p>
                  </template>
                  <span>{{ item?.nota_fiscal }}</span>
                </v-tooltip>
              </td>
              <td class="mes-fatura text-center">
                {{ item.periodo_inicio | moment("DD/MM/YY") }} até
                {{ item.periodo_fim | moment("DD/MM/YY") }}
              </td>
              <td class="text-right">
                <strong>
                  {{ item.total | fomatadorReal }}
                  {{
                    item.status == "PAGO_PARCIAL"
                      ? `(${formatarValor(item.diferenca)})`
                      : ""
                  }}
                </strong>
              </td>
              <td class="text-right pr-0">
                <v-chip
                  small
                  :class="item.status"
                  class="justify-center subtitle-2"
                  style="width: 105px"
                  >{{ item.status | statusFatura }}</v-chip
                >
              </td>

              <td class="menu-tabela">
                <v-menu v-if="item === itemSelecionado" v-model="menuAberto">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item-group class="opcoes-gerais">
                      <v-list-item
                        v-if="item.url_fatura"
                        v-bind:href="item.url_fatura"
                        target="_blank"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-download</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Baixar PDF</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>

                      <v-list-item
                        v-if="item.id"
                        @click="abrirModalFatura(item.id)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-chart-bar-stacked</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Visualizar fatura</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>

                    <v-divider v-if="item.url_fatura || item.id"></v-divider>

                    <v-list-item-group
                      class="opcoes-pago_parcial"
                      v-if="item.status == 'PAGO_PARCIAL'"
                    >
                      <v-list-item
                        @click="alterarFaturaParaPaga(item)"
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-swap-horizontal</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Alterar para paga</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <div
                        v-if="
                          checkCredentials(['admin', 'editor_fatura']) &&
                          empresaEmailsCobranca?.length
                        "
                      >
                        <v-divider></v-divider>
                        <v-list-item @click="abrirDialogEnviarEmail(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-send</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >Reenviar por e-mail</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                    </v-list-item-group>

                    <v-list-item-group
                      class="opcoes-aberta"
                      v-if="item.status == 'ABERTA'"
                    >
                      <v-list-item
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                        :to="{ name: 'VerFatura', params: { id: item.id } }"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-pencil</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Editar itens</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                        @click="desaprovaFatura(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Descartar fatura</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                    </v-list-item-group>

                    <v-list-item-group
                      class="opcoes-paga"
                      v-if="item.status == 'PAGA'"
                    >
                      <div
                        v-if="
                          checkCredentials(['admin', 'editor_fatura']) &&
                          empresaEmailsCobranca?.length
                        "
                      >
                        <v-divider></v-divider>
                        <v-list-item @click="abrirDialogEnviarEmail(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-send</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >Reenviar por e-mail</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="nota_fical">
                        <div v-if="!item?.nota_fiscal">
                          <v-list-item
                            @click="editarNotaFiscal(item, 'adicionar')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Adicionar nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <div v-else>
                          <v-list-item
                            v-if="item?.nota_fiscal"
                            @click="editarNotaFiscal(item, 'editar')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Editar nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            v-if="item?.nota_fiscal"
                            @click="editarNotaFiscal(item, 'remover')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice-remove</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Remover nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </div>
                    </v-list-item-group>

                    <v-list-item-group
                      class="opcoes-aprovada"
                      v-if="item.status == 'APROVADA'"
                    >
                      <v-list-item
                        @click="reabrirFatura(item)"
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-swap-horizontal</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title>Reabrir fatura</v-list-item-title>
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                        @click="desaprovaFatura(item)"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-delete-outline</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Descartar fatura</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <v-list-item
                        @click="alterarFaturaParaPaga(item)"
                        v-if="checkCredentials(['admin', 'editor_fatura'])"
                      >
                        <v-list-item-icon>
                          <v-icon>mdi-swap-horizontal</v-icon>
                        </v-list-item-icon>
                        <v-list-item-content>
                          <v-list-item-title
                            >Alterar para paga</v-list-item-title
                          >
                        </v-list-item-content>
                      </v-list-item>
                      <div
                        v-if="
                          checkCredentials(['admin', 'editor_fatura']) &&
                          empresaEmailsCobranca?.length
                        "
                      >
                        <v-divider></v-divider>
                        <v-list-item @click="abrirDialogEnviarEmail(item)">
                          <v-list-item-icon>
                            <v-icon>mdi-send</v-icon>
                          </v-list-item-icon>
                          <v-list-item-content>
                            <v-list-item-title
                              >Reenviar por e-mail</v-list-item-title
                            >
                          </v-list-item-content>
                        </v-list-item>
                      </div>
                      <div class="nota_fical">
                        <div v-if="!item?.nota_fiscal">
                          <v-list-item
                            @click="editarNotaFiscal(item, 'adicionar')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Adicionar nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                        <div v-else>
                          <v-list-item
                            v-if="item?.nota_fiscal"
                            @click="editarNotaFiscal(item, 'editar')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice-edit</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Editar nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item
                            v-if="item?.nota_fiscal"
                            @click="editarNotaFiscal(item, 'remover')"
                          >
                            <v-list-item-icon>
                              <v-icon>mdi-invoice-remove</v-icon>
                            </v-list-item-icon>
                            <v-list-item-content>
                              <v-list-item-title
                                >Remover nota fiscal</v-list-item-title
                              >
                            </v-list-item-content>
                          </v-list-item>
                        </div>
                      </div>
                    </v-list-item-group>
                  </v-list>
                </v-menu>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </v-row>
    <DialogConfirmacao ref="dialogConfirmacao" />
    <DialogEnviarEmail ref="dialogEnviarEmail" />
    <DialogEmailCobranca
      ref="dialogEmailCobranca"
      :emailsCobranca="empresaEmailsCobranca"
      @recarregarEmailsCobranca="carregaInfoEmpresa()"
    />
    <DialogPrecificacao ref="dialogPrecificacao" />
    <DialogFatura
      :itensFatura="itensFaturaParaDialog"
      :dialog="mostrarModalFatura"
      :fatura="faturaComAnalitico"
      @fechar="mostrarModalFatura = false"
    />
    <DialogNotaFiscal ref="dialogNotaFiscal" @alterarNotaFiscal="editaFatura" />
  </div>
</template>
<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import moment from "moment";
import { checkCredentials } from "@/assets/js/utils";
import Vue from "vue";
import DialogAprovarFatura from "./componentes/DialogAprovarFatura.vue";
import DialogConfirmacao from "./componentes/DialogConfirmacao.vue";
import DialogEnviarEmail from "./componentes/DialogEnviarEmail.vue";
import DialogEmailCobranca from "./componentes/DialogEmailCobranca.vue";
import GraficoServicos from "./componentes/GraficoServicos.vue";
import GraficoPorDia from "./componentes/GraficoPorDia.vue";
import DialogPrecificacao from "./componentes/DialogPrecificacao.vue";
import DialogFatura from "./componentes/DialogFatura.vue";
import DialogNotaFiscal from "./componentes/DialogNotaFiscal.vue";
export default {
  components: {
    DialogConfirmacao,
    DialogAprovarFatura,
    GraficoServicos,
    DialogEnviarEmail,
    DialogEmailCobranca,
    GraficoPorDia,
    DialogPrecificacao,
    DialogFatura,
    DialogNotaFiscal,
  },
  data() {
    return {
      opcoesTabela: {},
      ismounted: false,
      hoje: null,
      stringEmailsCobranca: null,
      cabecalhoFatura: [
        { text: "", align: "start", sortable: false, value: "id", width: 205 },
        {
          text: "Nota Fiscal",
          align: "center",
          value: "nota_fiscal",
          width: 235,
        },
        {
          text: "Período",
          align: "center",
          value: "periodo_inicio",
          width: 235,
        },
        { text: "Valor", value: "total", align: "right" },
        { text: "", align: "right", sortable: false, value: "status" },
        { text: "", align: "end", sortable: false, value: "menu" },
      ],
      franquia: null,
      itemSelecionado: false,
      menuAberto: false,
      mostrarModalFatura: false,
      itensFaturaParaDialog: null,
    };
  },
  async created() {
    await this.getPrecificacoes({
      empresa_id: this.empresaSelecionada.id,
      limite: 1000,
    });
    await this.carregaFaturaParcial();
    this.carregaInfoEmpresa();
    this.hoje = moment()
      .startOf("day")
      .subtract(1, "day")
      .format("DD [de] MMMM");
  },
  mounted() {
    this.atualizaOpcoes();
    this.ismounted = true;
  },
  methods: {
    checkCredentials,
    ...mapActions("fatura", {
      getPrecificacoes: "getPrecificacoes",
      getItensFatura: "getItensFatura",
      getFaturaParcial: "getFaturaParcial",
      getTodasFaturas: "getTodasFaturas",
      editaFatura: "editaFatura",
      limparFaturas: "limparFaturas",
      pegarFaturaComAnalitico: "pegarFaturaComAnalitico",
    }),
    ...mapActions("empresa", { getEmpresa: "getEmpresa" }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    formatarValor(valor) {
      return Vue.filter("fomatadorReal")(valor);
    },
    abrirDialogEnviarEmail(fatura) {
      this.$refs.dialogEnviarEmail.abrirDialogEnviarFatura(
        fatura,
        this.stringEmailsCobranca
      );
    },
    async carregaItensFatura() {
      if (!this.faturaParcial) return;
      let payload = {
        fatura_id: this.faturaParcial.id,
        empresa_id: this.empresaSelecionada.id,
        limite: 1000,
      };
      await this.getItensFatura(payload);
      this.calculaFranquia(this.itensFatura);
    },
    async carregaFaturaParcial() {
      let payload = {
        empresa_id: this.empresaSelecionada.id,
      };
      await this.getFaturaParcial(payload)
        .then(() => {
          this.carregaItensFatura();
        })
        .catch((error) => {
          console.error(error);
        });
    },
    carregaTodasFaturas() {
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        status: this.verificaCredencialUsuario(),
        ordem: "-id",
        pagina: this.opcoesTabela.page,
        limite:
          this.opcoesTabela.itemsPerPage == -1
            ? 1000
            : this.opcoesTabela.itemsPerPage,
      };
      this.getTodasFaturas(payload);
    },
    async alterarFaturaParaPaga(fatura) {
      let dialog = {
        titulo: "Deseja alterar o status da fatura para PAGA?",
        conteudo:
          "Lembrando que após a mudança, não será possível editar a fatura.",
      };
      if (fatura.status == "PAGO_PARCIAL") {
        this.$refs.dialogConfirmacao.abrirDialog(
          fatura,
          dialog,
          "PAGO_PARCIAL",
          false
        );
      } else {
        this.$refs.dialogConfirmacao.abrirDialog(fatura, dialog, "PAGA", false);
      }
    },
    abrirDialogEmailCobranca(emails, adicao = false) {
      this.$refs.dialogEmailCobranca.abrirDialog(emails);
      if (adicao) this.$refs.dialogEmailCobranca.adicionarEmail();
      return;
    },
    async carregaInfoEmpresa() {
      let payload = { id: this.empresaSelecionada.id };
      await this.getEmpresa(payload);
      this.juntaEmailsCobranca();
    },
    juntaEmailsCobranca() {
      if (!this.empresaEmailsCobranca) return;
      this.stringEmailsCobranca = this.empresaEmailsCobranca
        .map((u) => u.email)
        .join(", ");
    },
    reabrirFatura(fatura) {
      let dialog = {
        titulo: "Deseja alterar o status da fatura para ABERTA?",
        conteudo: "Lembrando que, após a mudança, a fatura poderá ser editada.",
      };
      this.$refs.dialogConfirmacao.abrirDialog(fatura, dialog, "ABERTA", false);
    },
    desaprovaFatura(fatura) {
      let dialog = {
        titulo: "Deseja mesmo descartar a fatura?",
        conteudo:
          "Isso pode levar alguns minutos, pois a fatura será recalculada.",
      };
      this.$refs.dialogConfirmacao.abrirDialog(
        fatura,
        dialog,
        "DESCARTADA",
        true
      );
    },
    calculaFranquia(itensFatura) {
      let franquias = itensFatura.filter(
        (item) =>
          item.referencia == "FRANQUIA" || item.referencia == "FRANQUIA_MINIMA"
      );

      if (franquias.length > 1) {
        let somaValorFranquias = 0;
        let somaQuantidadeFranquias = 0;

        franquias.forEach((franquia) => {
          somaValorFranquias += parseFloat(franquia.total);
          somaQuantidadeFranquias += franquia.quantidade;
        });

        this.franquia = {
          total: somaValorFranquias,
          quantidade: somaQuantidadeFranquias,
        };
      } else if (franquias.length == 1) {
        this.franquia = franquias[0];
      }
    },
    ordemTabela(opcoesTabela) {
      if (!opcoesTabela.sortBy) return;
      if (opcoesTabela.sortBy.length == 0 || !this.opcoesTabela.sortBy) {
        return "-id";
      } else if (
        opcoesTabela.sortBy.length > 0 &&
        opcoesTabela.sortDesc[0] == true
      ) {
        return `-${opcoesTabela.sortBy[0]}`;
      } else {
        return `${opcoesTabela.sortBy[0]}`;
      }
    },
    atualizaOpcoes() {
      Object.assign(this.opcoesTabela, {
        itemsPerPage: Number(this.$route.query.itemsPerPage) || 12,
        page: Number(this.$route.query.page) || 1,
        sortBy: [this.$route.query.sortBy || "-id"],
        sortDesc: [this.$route.query.sortDesc || ""],
      });
    },
    atualizaQuery(newValue) {
      this.$router.push({ query: newValue }).catch(() => {});
    },
    async ordenaTabela(opcoesTabela) {
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        status: this.verificaCredencialUsuario(),
        pagina: opcoesTabela.page || 1,
        ordem: this.ordemTabela(opcoesTabela),
        limite: `${
          opcoesTabela.itemsPerPage == -1 ? 1000 : opcoesTabela.itemsPerPage
        }`,
      };
      await this.getTodasFaturas(payload);
    },
    verificaCredencialUsuario() {
      let status = "APROVADA,PAGA,PAGO_PARCIAL";
      if (checkCredentials(["admin", "editor_fatura"])) status += ",ABERTA";
      return status;
    },
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    async abrirDialogPrecificacao() {
      this.$refs.dialogPrecificacao.abrirDialog();
    },
    async abrirModalFatura(id) {
      if (id) {
        await this.pegarFaturaComAnalitico(id).then(async (retorno) => {
          if (retorno) {
            const { data } = await this.getItensFatura({
              fatura_id: retorno.id,
              empresa_id: this.empresaSelecionada.id,
              limite: 1000,
              naoCommitar: true,
            });
            this.itensFaturaParaDialog = JSON.parse(JSON.stringify(data));
            this.mostrarModalFatura = true;
          } else {
            this.showSnackBar({
              text: `Erro ao buscar dados para o gráfico`,
            });
          }
        });
      }
    },
    editarNotaFiscal(fatura, operacao) {
      this.$refs.dialogNotaFiscal.abrir(
        fatura.id,
        fatura?.nota_fiscal,
        operacao
      );
      return;
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
      empresaEmailsCobranca: "empresaEmailsCobranca",
    }),
    ...mapGetters("fatura", {
      faturaParcial: "faturaParcial",
      itensFatura: "itensFatura",
      faturas: "faturas",
      mesFaturaParcial: "mesFaturaParcial",
      faturaComAnalitico: "faturaComAnalitico",
      carregandoFaturaParcial: "carregandoFaturaParcial",
      carregandoItensFatura: "carregandoItensFatura",
      carregandoFaturas: "carregandoFaturas",
      erroCarregarFaturaParcial: "erroCarregarFaturaParcial",
      faturaParcialNaoEncontrada: "faturaParcialNaoEncontrada",
      erroCarregarItensFatura: "erroCarregarItensFatura",
      erroCarregarFaturas: "erroCarregarFaturas",
      totalFaturas: "totalFaturas",
    }),
    totalEventos() {
      let eventos = 0,
        total = 0;
      this.itensFatura.forEach((itemFatura) => {
        if (itemFatura.referencia != "EVENTO") return;
        eventos += itemFatura.quantidade;
        total += parseFloat(itemFatura.total);
      });
      return {
        eventos,
        total,
      };
    },
    analiticoFiltrado() {
      let listaAnalitico = [...this.faturaParcial?.analitico];
      listaAnalitico = listaAnalitico.map((item) => {
        let lista = Object.entries(item);
        let listaFiltrada = lista.filter(
          ([chave, dado]) => chave == "dia" || dado.valor !== null
        );
        item = Object.fromEntries(listaFiltrada);
        return item;
      });
      return listaAnalitico;
    },
    itensFaturaFiltrado() {
      return (
        this.itensFatura?.filter(
          ({ referencia }) => referencia !== "DESCONTO"
        ) || []
      );
    },
    descricaoValorFranquia() {
      let valorUnitario =
        Vue.filter("fomatadorReal")(this.franquia?.valor_unitario) +
        "/contrato";
      let textoDescricao = this.franquia?.descricao.replace("Franquia ", "");
      return `${valorUnitario} ${textoDescricao}`;
    },
    listaFaturasVazia() {
      return (
        (!this.faturas?.length && !this.erroCarregarFaturas) ||
        this.carregandoFaturas
      );
    },
  },
  watch: {
    async opcoesTabela(newValue) {
      if (this.ismounted) {
        await this.ordenaTabela(newValue);
        this.atualizaQuery(newValue);
      }
    },
    empresaSelecionada: {
      async handler() {
        await this.getPrecificacoes({
          empresa_id: this.empresaSelecionada.id,
          limite: 1000,
        });
        this.atualizaOpcoes();
        this.opcoesTabela.page = 1;

        this.carregaFaturaParcial();
        this.carregaInfoEmpresa();
        this.carregaTodasFaturas();
        this.hoje = moment().startOf("day").format("DD [de] MMMM");
      },
    },
  },
  destroyed() {
    this.limparFaturas();
  },
};
</script>
<style scoped lang="scss">
.w-982 {
  width: 982px;
}

.nota-fiscal {
  .texto {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
    margin: 0px;
  }
}

.mes-fatura::first-letter {
  text-transform: capitalize;
}

.ABERTA {
  color: white !important;
  background-color: #f57c00 !important;
}

.APROVADA {
  color: white !important;
  background-color: #1e88e5 !important;
}

.PAGA {
  color: white !important;
  background-color: #7cb342 !important;
}

.PAGO_PARCIAL {
  color: white !important;
  background-color: #fbc02d !important;
}

:deep(.v-toolbar__content) {
  padding-right: 0px !important;
}

.menu-tabela {
  width: 70px !important;
}

.subtitle-ml {
  font-size: 13px !important;
}

.fatura-sem-dados {
  font-weight: 700;
  font-size: 16px;
  color: #a6a6a6 !important;
}

:deep(.v-icon.v-icon) {
  line-height: inherit;
}
.divisor_fatura {
  width: 1px;
  height: 100%;
  background: black;
}
.divisor_plus {
  line-height: 25px;
  font-size: 30px;
  font-weight: 400;
}
</style>
