<template>
  <div class="pa-8 text-center">
    <h2 class="text-h5">
      Seu acesso não foi configurado.<br>
      Contate o administrador.
    </h2>

    <div class="mt-4">
      <v-btn :href="coreApiUrl + '/oauth/login'" color="primary">
        Tentar novamente
      </v-btn>
    </div>
  </div>
</template>

<script>
import environment from '@/assets/js/enviroment';

export default {
  data: () => ({
    coreApiUrl: environment.coreApiUrl,
  }),
};
</script>

<style scoped lang="scss">
</style>
