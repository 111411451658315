<template>
  <v-card v-if="evidencia" elevation="0" class="caixa-email">
    <h2>{{ assunto }}</h2>
    <div class="my-2">
      <p class="remetente" v-if="evidencia?.remetente">de: {{ evidencia?.remetente }}</p>
    <p class="destino" v-if="evidencia?.para">para: {{ evidencia?.para }}</p>
    </div>
    <div v-if="template">
      <span v-html="template"></span>
    </div>
    <div v-else-if="!template && evidencia">
      <p class="text-center">Não foi encontrado um template de e-mail</p>
    </div>
    <ul v-if="evidencia?.anexos">
      <li class="anexo" v-for="(anexo, index) in evidencia?.anexos" :key="`${anexo.nome_arquivo}${index}`">
        <a :href="anexo?.url_arquivo" target="_blank" rel="noopener noreferrer">
          <v-icon style="font-size: 24px">mdi-file-document-multiple-outline</v-icon>
          <span>{{ anexo?.nome_arquivo }}</span>
        </a>
      </li>
    </ul>
  </v-card>
</template>

<script>
export default {
  props: ["evidencia", "template"],
  computed: {
    assunto() {
      return this.evidencia?.assunto ?? this.evidencia?.evento?.email_assunto;
    },
  },
};
</script>

<style scoped lang="scss">
.caixa-email {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
  height: 60vh;

  h1 {
    line-height: normal;
  }

  p {
    margin-bottom: 0;
    padding-bottom: 0;
  }

  .destino {
    font-size: 16px;
    color: #767676;
    line-height: normal;
  }
  .remetente {
    font-size: 16px;
    color: #767676;
    line-height: normal;
  }

  ul {
    margin: 10px 0px;
    padding: 0px;
    list-style: none;
    display: grid;
    grid-template-columns: repeat(3, auto);
    row-gap: 10px;

    .anexo {
      width: 100%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 10px;

      span {
        margin-left: 5px;
        vertical-align: middle;
      }
    }
  }
}
</style>