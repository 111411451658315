<template>
  <v-chart
    :loading="carregando"
    :loadingOptions="opcoesCarregando"
    class="chart"
    :option="grafico.servicos"
  />
</template>
<script>
import Vue from "vue";
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";
import { mapGetters } from "vuex";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  components: { VChart },
  props: { itensFatura: null, carregando: false },
  data: () => ({
    opcoesCarregando: { text: "Carregando gráfico..." },
    grafico: {
      servicos: {
        carregando: true,
        erro: false,
        tooltip: {
          trigger: "item",
          formatter: function (item) {
            return item.name + " (" + item.data.quantidade + ")";
          },
        },
        series: [{ type: "pie", radius: ["40%", "70%"], data: [] }],
      },
    },
  }),
  async created() {
    this.adicionaValorGrafico();
  },

  methods: {
    getNomePorDescricao(descricao) {
      const precificacao = this.precificacoes.find(
        (p) => p.chave === descricao
      );
      return precificacao && precificacao?.nome ? precificacao.nome : descricao;
    },
    getCorPorDescricao(descricao) {
      const precificacao = this.precificacoes.find(
        (p) => p.chave === descricao
      );
      return precificacao && precificacao?.cor
        ? precificacao.cor
        : Vue.filter("corDoGrafico")();
    },
    adicionaValorGrafico() {
      this.grafico.servicos.series[0].data = [];

      // agrega itens com a mesma descricao
      const faturasAgregadas = this.itensFatura.reduce((acc, curr) => {
        const found = acc.find((i) => i.descricao === curr.descricao);

        if (found) {
          const objectoAgregado = {
            ...found,
            valor_unitario:
              parseFloat(found.valor_unitario) +
              parseFloat(curr.valor_unitario),
            quantidade:
              parseFloat(found.quantidade) + parseFloat(curr.quantidade),
            total: parseFloat(found.total) + parseFloat(curr.total),
          };
          acc = acc.map((item) => {
            if (item.descricao === objectoAgregado.descricao) {
              item = { ...objectoAgregado };
            }
            return item;
          });
        } else {
          acc.push(curr);
        }

        return acc;
      }, []);

      faturasAgregadas.forEach((servico) => {
        if (servico.referencia != "FRANQUIA" && servico.total != "0.00") {
          this.grafico.servicos.series[0].data.push({
            value: servico.total,
            itemStyle: {
              // vem achave para com para com o array da verdade e pegar a cor
              color: this.getCorPorDescricao(servico.descricao),
            },
            name:
              this.getNomePorDescricao(servico.descricao) +
              "\n" +
              Vue.filter("fomatadorReal")(servico.total),
            quantidade: Vue.filter("numero")(servico.quantidade),
          });
        }
      });
    },
  },
  computed: {
    ...mapGetters("fatura", {
      precificacoes: "precificacoes",
    }),
  },
  watch: {
    contratos() {
      this.adicionaValorGrafico();
    },
  },
};
</script>
<style scoped lang="scss">
.chart {
  height: 270px;
}
</style>
