<template>
  <div>
    <div
      v-if="
        editandoFranquia &&
        erroEditarPrecificacaoFranquia &&
        erroEditarPrecificacaoFranquia.length
      "
    >
      <div v-for="err in erroEditarPrecificacaoFranquia" :key="err.motivo_erro">
        <v-alert v-if="err.precificacao_id" text outlined type="error" dense>
          <span>
            <b>{{ err.precificacao_id }}</b
            >: {{ err.motivo_erro }}
          </span>
        </v-alert>
      </div>
    </div>
    <v-data-table
      :headers="cabecalhoTabelaFranquia"
      :items="franquias"
      class="elevation-0"
      no-data-text="Nenhuma precificação de franquias cadastrada."
      hide-details
      hide-default-footer
      disable-pagination
      loading-text="Carregando precificação..."
    >
      <template v-slot:[`item.id`]="{ item }">
        <span class="my-2 text--disabled">#{{ item.id }}</span>
      </template>
      <template v-slot:[`item.categoria`]="{ item }">
        <span class="my-2">{{ item.categoria_apelido || "-" }}</span>
      </template>
      <template v-slot:[`item.limite_inferior`]="{ item }">
        <div v-show="item.editando">
          <v-text-field
            style="max-width: 120px"
            dense
            hide-details
            type="number"
            hide-spin-buttons
            min="0"
            v-model="item.limite_inferior"
          ></v-text-field>
        </div>
        <span v-show="!item.editando" class="my-2">{{
          item.limite_inferior.toLocaleString("pt-BR") || 0
        }}</span>
      </template>
      <template v-slot:[`item.limite_superior`]="{ item }">
        <div v-show="item.editando">
          <v-text-field
            style="max-width: 120px"
            dense
            hide-details
            type="number"
            hide-spin-buttons
            min="0"
            v-model="item.limite_superior"
          ></v-text-field>
        </div>
        <span v-show="!item.editando" class="my-2">{{
          item.limite_superior.toLocaleString("pt-BR") || 0
        }}</span>
      </template>
      <template v-slot:[`item.valor`]="{ item }">
        <div v-show="item.editando">
          <v-text-field
            style="max-width: 120px"
            dense
            hide-details
            type="number"
            hide-spin-buttons
            min="0"
            autofocus
            v-model="item.valor"
          ></v-text-field>
        </div>
        <span v-show="!item.editando" class="my-2">{{
          item.valor | formatadorRealDecimaisNCasas
        }}</span>
      </template>
      <template v-if="permissaoEditar" v-slot:[`item.acoes`]="{ item }">
        <div
          class="d-flex align-center justify-space-between"
          v-if="item.editando"
        >
          <v-icon
            style="font-size: 1rem"
            @click="cancelaEdicaoFranquia(item.id)"
            class="my-2"
            >mdi-close</v-icon
          >
        </div>
        <div v-if="!item.editando">
          <v-icon
            style="font-size: 1rem"
            @click="handleEdicaoFranquia(item.id)"
            class="edit-button"
            >mdi-pencil</v-icon
          >
        </div>
      </template>
    </v-data-table>
  </div>
</template>
      
      <script>
import precificacaoService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";

export default {
  props: {
    precificacoesFranquia: {
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      checkCredentials,
      cabecalhoTabelaFranquia: [
        {
          text: "",
          sortable: false,
          align: "start",
          value: "id",
          width: "5%",
        },
        {
          text: "Categoria",
          sortable: false,
          align: "start",
          value: "categoria",
          width: "50%",
        },
        {
          text: "Limite inferior",
          sortable: false,
          value: "limite_inferior",
          width: "10%",
        },
        {
          text: "Limite superior",
          sortable: false,
          value: "limite_superior",
          width: "10%",
        },
        {
          text: "Valor",
          sortable: false,
          value: "valor",
          width: "20%",
        },
        {
          text: "",
          sortable: false,
          value: "acoes",
          width: "10%",
          align: "end",
        },
      ],

      tab: 0,
      franquias: [],
      erroEditarPrecificacaoFranquia: [],
      mudouFranquias: false,
      mudancasFranquia: [],
    };
  },
  mounted() {
    this.setFranquiaWatcher();
    this.limpaPrecificacoes();
    this.franquias = this.precificacoesFranquia.map((obj) => ({ ...obj }));
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    setFranquiaWatcher() {
      const criaMudanca = function (item, sourceItem) {
        return {
          id: sourceItem.id,
          categoria_apelido: sourceItem.categoria_apelido,
          limite_inferior: {
            new: parseInt(item.limite_inferior),
            old: parseInt(sourceItem.limite_inferior),
          },
          limite_superior: {
            new: parseInt(item.limite_superior),
            old: parseInt(sourceItem.limite_superior),
          },
          valor: {
            new: parseFloat(item.valor),
            old: parseFloat(sourceItem.valor),
          },
        };
      };

      const verificaSeMudou = function (newValue, oldValue) {
        this.franquias.forEach((item) => {
          const sourceItem = this.precificacoesFranquia.find(
            (sourceItem) => item.id === sourceItem.id
          );
          if (sourceItem) {
            const mudou =
              parseInt(item.limite_inferior) !=
                parseInt(sourceItem.limite_inferior) ||
              parseInt(item.limite_superior) !=
                parseInt(sourceItem.limite_superior) ||
              parseFloat(item.valor) != parseFloat(sourceItem.valor);

            if (mudou) {
              const mudanca = criaMudanca(item, sourceItem);
              const indexMudanca = this.mudancasFranquia.findIndex(
                (mudancaItem) => mudancaItem.id === item.id
              );

              if (indexMudanca !== -1) {
                this.mudancasFranquia[indexMudanca] = mudanca;
              } else {
                this.mudancasFranquia.push(mudanca);
              }
            } else {
              this.mudancasFranquia = this.mudancasFranquia.filter(
                (mudancaItem) => mudancaItem.id !== sourceItem.id
              );
            }
          }
        });

        this.mudouFranquias = this.mudancasFranquia.length > 0;
        this.$emit("mudou", this.mudouFranquias);
      };

      this.$watch("franquias", verificaSeMudou, {
        deep: true,
        immediate: false,
      });
    },

    limpaAlteracoes() {
      this.franquias = this.precificacoesFranquia.map((item) => {
        return {
          ...item,
          editando: false,
        };
      });
    },

    handleEdicaoFranquia(itemId) {
      this.franquias = this.franquias.map((item) => {
        if (item.id === itemId) {
          item.editando = true;
        }
        return item;
      });
    },

    async editarFranquias() {
      this.erroEditarPrecificacaoFranquia = [];

      const franquiasFormatadas = this.franquias.map((item) => {
        return {
          ...item,
          valor: parseFloat(item.valor),
        };
      });

      try {
        const responseFranquias = await precificacaoService.editarPrecificacao(
          franquiasFormatadas,
          this.empresaSelecionada.id
        );
        if (responseFranquias.status === 200) {
          this.franquias = [...responseFranquias.data];
        }

        this.franquias = this.franquias.map((item) => {
          return {
            ...item,
            editando: false,
          };
        });
        this.erroEditarPrecificacaoFranquia = [];
      } catch (e) {
        this.erroEditarPrecificacaoFranquia = e.response?.data;
        throw new Error();
      }
    },

    cancelaEdicaoFranquia(itemId) {
      const oldPrecificacao = this.precificacoesFranquia.find(
        (item) => item.id == itemId
      );

      this.franquias = this.franquias.map((item) => {
        if (item.id === itemId && oldPrecificacao) {
          item = { ...oldPrecificacao };
          item.editando = false;
        }
        return item;
      });
    },

    async salvar() {
      try {
        await this.editarFranquias();

        this.showSnackBar({
          text: `Precificação alterada com sucesso`,
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: `Erro ao alterar precificação`,
        });
      }
    },

    limpaPrecificacoes() {
      this.franquias = [];
      this.erroEditarPrecificacaoFranquia = [];
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    permissaoEditar() {
      return checkCredentials(["admin", "editor_precificacao"]);
    },
    editandoFranquia() {
      const editando = this.franquias.some((item) => item.editando);
      if (!editando) this.erroEditarPrecificacaoFranquia = false;

      return editando;
    },
  },
};
</script>
      
      <style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
.v-data-table__wrapper tr .edit-button {
  display: none;
}
.v-data-table__wrapper tr:hover .edit-button {
  display: block;
}
</style>