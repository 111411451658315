<template>
  <v-dialog v-model="dialog" :width="850" persistent>
    <v-card style="height: 80vh" class="d-flex flex-column fill-height" flat>
      <v-card-title class="align-self-start" style="width: 100%">
        <p class="text-h5 title-card">Precificação</p>
        <v-tabs fixed-tabs v-model="tab">
          <v-tab v-for="t in tabItems" :key="t.name" :disabled="t.disabled">
            {{ t.name }}
          </v-tab>
        </v-tabs>
      </v-card-title>

      <v-card-text class="pt-3 fill-height" style="overflow-y: auto">
        <v-tabs-items style="overflow-y: auto" class="pt-3" v-model="tab">
          <v-tab-item>
            <v-data-table
              :headers="cabecalhoTabelaFranquia"
              :items="franquiaMinima"
              class="elevation-0 mb-8"
              v-if="temFranquiaMinima && !erroCarregarPrecificacao"
              no-data-text="Nenhuma franquia mínima cadastrada."
              hide-details
              hide-default-footer
              disable-pagination
              :loading="carregandoPrecificacao"
              loading-text="Carregando precificação..."
            >
              <template v-slot:[`item.faixa`]="{ item }">
                Franquia mínima para até
                {{ item.limite_superior.toLocaleString("pt-BR") || 0 }}
                contratos
              </template>
              <template v-slot:[`item.valor`]="{ item }">
                <span class="my-2">{{
                  item.valor | formatadorRealDecimaisNCasas
                }}</span>
              </template>
            </v-data-table>

            <v-data-table
              :headers="cabecalhoTabelaFranquiaUnitario"
              :items="precificacaoFranquias"
              class="elevation-0"
              v-if="!erroCarregarPrecificacao"
              no-data-text="Nenhuma precificação de franquia cadastrada."
              hide-details
              hide-default-footer
              disable-pagination
              :loading="carregandoPrecificacao"
              loading-text="Carregando precificação..."
            >
              <template v-slot:[`item.faixa`]="{ item }">
                De {{ item.limite_inferior.toLocaleString("pt-BR") || 0 }} até
                {{ item.limite_superior.toLocaleString("pt-BR") || 0 }}
                contratos
              </template>
              <template v-slot:[`item.valor`]="{ item }">
                <span class="my-2">{{
                  item.valor | formatadorRealDecimaisNCasas
                }}</span>
              </template>
            </v-data-table>
          </v-tab-item>

          <v-tab-item>
            <v-data-table
              :headers="cabecalhoTabelaEvento"
              :items="precificacaoEventos"
              class="elevation-0"
              v-if="!erroCarregarPrecificacao"
              no-data-text="Nenhuma precificação de eventos cadastrada."
              hide-details
              hide-default-footer
              disable-pagination
              :loading="carregandoPrecificacao"
              loading-text="Carregando precificação..."
            >
              <template v-slot:[`item.categoria`]="{ item }">
                <span class="my-2">{{ item.categoria_apelido || "-" }}</span>
              </template>
              <template v-slot:[`item.valor`]="{ item }">
                <span class="my-2">{{
                  item.valor | formatadorRealDecimaisNCasas
                }}</span>
              </template>
            </v-data-table>
          </v-tab-item>
        </v-tabs-items>

        <v-alert
          type="error"
          class="mx-auto my-10 justify-center"
          outlined
          style="width: fit-content"
          v-if="erroCarregarPrecificacao && !carregandoPrecificacao"
        >
          <div class="mb-2">Erro ao carregar as precificações.</div>
          <v-btn color="primary" @click="carregarPrecificacao()">
            Tentar novamente
          </v-btn>
        </v-alert>
      </v-card-text>

      <v-divider></v-divider>
      <div class="d-flex align-self-end">
        <v-spacer></v-spacer>
        <v-card-actions>
          <v-btn color="primary" text @click="fecharDialog()"> FECHAR </v-btn>
        </v-card-actions>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import precificacaoService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";

export default {
  data() {
    return {
      checkCredentials,
      dialog: false,
      cabecalhoTabelaFranquia: [
        {
          text: "",
          value: "faixa",
          sortable: false,
          width: "80%",
        },
        {
          text: "Valor fixo",
          sortable: false,
          value: "valor",
          width: "20%",
          align: "end",
        },
      ],

      cabecalhoTabelaFranquiaUnitario: [
        {
          text: "Faixa",
          value: "faixa",
          sortable: false,
          width: "80%",
        },
        {
          text: "Valor unitário",
          sortable: false,
          value: "valor",
          width: "20%",
          align: "end",
        },
      ],

      cabecalhoTabelaEvento: [
        {
          text: "Categoria",
          sortable: false,
          align: "start",
          value: "categoria",
          width: "80%",
        },
        {
          text: "Valor unitário",
          sortable: false,
          value: "valor",
          width: "20%",
          align: "end",
        },
      ],

      tab: 0,
      precificacoes: [],
      carregandoPrecificacao: false,
      erroCarregarPrecificacao: false,
      precificacaoFranquias: [],
      precificacaoEventos: [],
      sourcePrecificacaoFranquias: [],
      sourcePrecificacaoEventos: [],
    };
  },
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    abrirDialog() {
      this.dialog = true;
    },
    fecharDialog() {
      this.dialog = false;
      this.precificacoes = [];
      this.carregandoPrecificacao = false;
      this.erroCarregarPrecificacao = false;
      this.precificacaoFranquias = [];
      this.precificacaoEventos = [];
    },

    limpaPrecificacoes() {
      this.precificacoes = [];
      this.sourcePrecificacaoFranquias = [];
      this.sourcePrecificacaoEventos = [];
      this.precificacaoEventos = [];
      this.precificacaoFranquias = [];
      this.erroCarregarPrecificacao = false;
    },

    async carregarPrecificacao() {
      this.carregandoPrecificacao = true;
      this.erroCarregarPrecificacao = false;
      let params = {
        empresa_id: this.empresaSelecionada.id,
        limite: 1000,
      };

      try {
        const result = await precificacaoService.getPrecificacao(params);
        this.precificacoes = result.data.objects;

        this.precificacoes.forEach((item) => {
          if (item.categoria.includes("FRANQUIA")) {
            this.sourcePrecificacaoFranquias.push(item);
          } else {
            if (item.valor && item.valor >= 0) {
              this.sourcePrecificacaoEventos.push(item);
            }
          }
        });

        this.precificacaoEventos = JSON.parse(
          JSON.stringify(this.sourcePrecificacaoEventos)
        );

        try {
          this.precificacaoEventos.sort((a, b) => {
            const categoriaApelidoA = a.categoria_apelido
              ? a.categoria_apelido.toLowerCase()
              : "";
            const categoriaApelidoB = b.categoria_apelido
              ? b.categoria_apelido.toLowerCase()
              : "";

            if (!a.categoria_apelido && b.categoria_apelido) {
              return -1;
            }
            if (!b.categoria_apelido && a.categoria_apelido) {
              return 1;
            }

            return categoriaApelidoA.localeCompare(categoriaApelidoB);
          });
        } catch (e) {
          console.log(e);
        }

        this.precificacaoFranquias = JSON.parse(
          JSON.stringify(
            this.sourcePrecificacaoFranquias.filter(
              (item) => item.categoria !== "FRANQUIA_MINIMA"
            )
          )
        );

        this.erroCarregarPrecificacao = false;
      } catch (e) {
        this.erroCarregarPrecificacao = true;
      } finally {
        this.carregandoPrecificacao = false;
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    tabItems() {
      return [
        { id: 0, name: "Franquias", disabled: false },
        { id: 1, name: "Eventos", disabled: false },
      ];
    },
    temFranquiaMinima() {
      return this.sourcePrecificacaoFranquias.some(
        (item) => item.categoria === "FRANQUIA_MINIMA"
      );
    },
    franquiaMinima() {
      if (this.temFranquiaMinima) {
        return this.sourcePrecificacaoFranquias.filter(
          (item) => item.categoria === "FRANQUIA_MINIMA"
        );
      }
      return false;
    },
  },
  watch: {
    dialog: {
      handler() {
        if (this.dialog) {
          this.limpaPrecificacoes();
          this.carregarPrecificacao();
        }
      },
    },
  },
};
</script>

<style scoped lang="scss">
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
.v-data-table__wrapper tr .edit-button {
  display: none;
}
.v-data-table__wrapper tr:hover .edit-button {
  display: block;
}
</style>
