<template>
  <div>
    <v-app-bar app
      ><span class="text-h6 font-weight-bold ml-3"
        >{{ $route.meta.title }}
      </span>
      <div class="flex justify-end gap-4">
        <v-btn
          v-if="mudouForm"
          text
          color="primary"
          :loading="loadingSalvaConfigs"
          @click="populaConfigs"
          >Reverter alterações</v-btn
        >
        <v-btn class="primary" :disabled="!mudouForm" @click="salvar"
          >Salvar</v-btn
        >
      </div></v-app-bar
    >
    <div
      v-if="loadingEmpresa"
      class="flex flex-col items-center justify-center mt-12"
    >
      <v-progress-circular width="6" indeterminate color="primary" size="100">
      </v-progress-circular>
      <div class="mt-4">
        <span class="text-xl tex-grey weight">Carregando empresa...</span>
      </div>
    </div>
    <div class="container" v-else>
      <div class="teste"></div>
      <div
        class="bg-white elevation-1 rounded-lg border-gray-200 w-10/12 mx-auto"
      >
        <v-form v-model="form" ref="form">
          <div v-for="(config, key) in configs" :key="key">
            <div class="grid grid-cols-6 items-center p-4">
              <div class="flex flex-col col-span-3 self-start">
                <span class="text-lg text-gray-800">{{
                  config.translation
                }}</span>
                <span class="text-xs text-gray-500">{{
                  config.description
                }}</span>
              </div>
              <div class="col-span-3 ml-4">
                <component
                  v-if="config.attrs['v-mask']"
                  class="col-span-3"
                  v-model="config.value"
                  :data="config.value"
                  outlined
                  dense
                  :form="$refs.form"
                  :is="config.component"
                  v-on="config.on"
                  v-bind="config.attrs"
                  v-mlmask="{
                    mask: '##.###.###/####-##',
                  }"
                >
                </component>
                <component
                  v-else
                  class="col-span-3"
                  v-model="config.value"
                  :data="config.value"
                  outlined
                  dense
                  :form="$refs.form"
                  :is="config.component"
                  v-on="config.on"
                  v-bind="config.attrs"
                >
                </component>
              </div>
            </div>
            <v-divider></v-divider>
          </div>
        </v-form>
      </div>
      <DialogSalvaConfigs ref="DialogSalvaConfigs" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mask as mlmask } from "@titou10/v-mask";
import { VSelect, VTextField, VSwitch } from "vuetify/lib";
import JsonDashBoardUiEditor from "./components/JsonDashBoardUiEditor.vue";
import JsonPerfisReguaUiEditor from "./components/JsonPerfisReguaUiEditor.vue";
import JsonEmailUiEditor from "./components/JsonEmailUiEditor.vue";
import DialogSalvaConfigs from "./components/DialogSalvaConfigs.vue";
const rules = {
  required: (v) => !!v || "campo obrigatório",
  maiorQueZero: (v) => v === null || v > 0 || "Valor deve ser maior que 0",
  limiteSoftBounce: function () {
    return true;
  },
};
export default {
  directives: {
    mlmask,
  },
  components: {
    DialogSalvaConfigs,
    JsonPerfisReguaUiEditor,
    JsonEmailUiEditor,
  },
  async created() {
    await this.carregaEmpresa();
  },
  data: function () {
    return {
      loading: false,
      loadingEmpresa: false,
      empresa: null,
      form: false,
      mudouForm: false,
      loadingSalvaConfigs: false,
      configs: {
        nome: {
          value: null,
          translation: "Nome",
          description: "Nome da empresa que é exibido no Console.",
          component: VTextField,
          attrs: {
            rules: [],
            placeholder: "",
            maxLength: 128,
            counter: 128,
          },
        },
        chave: {
          value: null,
          translation: "Chave",
          description: "Chave da empresa conforme definido pela equipe de T.I.",
          component: VTextField,
          attrs: {
            placeholder: "chave_empresa",
            disabled: true,
          },
        },
        dashboard: {
          value: "",
          translation: "Dashboards",
          description:
            "Define os títulos e links dos dashboards que serão exibidos dentro do Console.",
          component: JsonDashBoardUiEditor,
          attrs: {
            lang: "json",
            lines: "auto",
            ref: "dashboards",
          },
        },
        perfis_regua: {
          value: "",
          translation: "Perfis das réguas",
          description:
            "Define os perfis de clientes e de contratos que são exibidos nas telas de régua.",
          component: JsonPerfisReguaUiEditor,
          attrs: {
            lang: "json",
            lines: "auto",
            ref: "perfisRegua",
          },
        },
        razao_social: {
          value: null,
          translation: "Razão Social",
          description:
            "Razão social da empresa parceira. Utilizado para emissão de faturas.",
          component: VTextField,
          attrs: {
            maxLength: 256,
            counter: 256,
          },
        },
        cnpj: {
          value: null,
          maskedValue: null,
          translation: "CNPJ",
          description:
            "CNPJ da empresa parceira. Utilizado para emissão de faturas.",
          component: VTextField,
          attrs: {
            placeholder: "",
            "v-mask": "##.###.###/####-##",
            rules: [(v) => (!!v && v.length == 18) || "CNPJ inválido"],
          },
        },
        endereco: {
          value: null,
          translation: "Endereço",
          description:
            "Endereço da empresa parceira. Utilizado para emissão de faturas.",
          component: VTextField,
          attrs: {
            placeholder: "",
            maxLength: 512,
            counter: 512,
          },
        },
        emails_cobranca: {
          value: null,
          translation: "E-mails cobrança",
          description: "E-mails para os quais as faturas serão enviadas.",
          component: JsonEmailUiEditor,
          attrs: {
            lang: "json",
            lines: "auto",
            ref: "perfisRegua",
          },
        },
        // bucket: {
        //   value: null,
        //   translation: "Bucket",
        //   description:
        //     "Nome do bucket da empresa na AWS conforme definido pela equipe de T.I.",
        //   component: VTextField,
        //   attrs: {
        //     placeholder: "",
        //     outlined: true,
        //     dense: true,
        //     disabled: true,
        //   },
        // },
        fluxo_soft_bounce: {
          value: null,
          translation: "Fluxo para soft-bounce de e-mails",
          description:
            "Ativa ou desativa o fluxo automático para tratar soft-bounces de e-mails através de ligações e SMS",
          component: VSwitch,
          attrs: {},
        },
        limite_soft_bounce: {
          value: null,
          translation: "Limite soft-bounce",
          description:
            "Limite de dias seguidos por mês em que tentaremos ligar para o cliente",
          component: VTextField,
          attrs: {
            type: "number",
            rules: [],
          },
        },
        fluxo_hard_bounce: {
          value: null,
          translation: "Fluxo para hard-bounce de e-mails",
          description:
            "Ativa ou desativa o fluxo automático para tratar hard-bounces de e-mails através de ligações e SMS",
          component: VSwitch,
          attrs: {},
        },
        limite_hard_bounce: {
          value: null,
          translation: "Limite hard-bounce",
          description:
            "Limite de dias seguidos por mês em que tentaremos ligar para o cliente",
          component: VTextField,
          attrs: {
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        fluxo_sem_email: {
          value: null,
          translation: "Fluxo para clientes sem e-mail",
          description:
            "Ativa ou desativa o fluxo automático para tentar obter e-mails de clientes através de ligações e SMS",
          component: VSwitch,
          attrs: {},
        },
        limite_sem_email: {
          value: null,
          translation: "Limite sem e-mail",
          description:
            "Limite de dias seguidos por mês em que tentaremos ligar para o cliente no fluxo de email",
          component: VTextField,
          attrs: {
            type: "number",
            rules: [],
          },
        },
        rate_limit_email: {
          value: null,
          translation: "Rate limit e-mail",
          excludeFromPatch: true,
          description:
            "Determina se os disparos de e-mail deverão ser limitados a uma certa taxa (preserva a reputação do domínio)",
          component: VTextField,
          attrs: {
            disabled: true,
            class: "rate_limit_email",
          },
        },
        fluxo_optin_wpp_sms: {
          value: false,
          translation: "Fluxo opt-in de WhatsApp via SMS",
          description:
            "Ativa ou desativa o fluxo automático para tentar obter o opt-in de WhatsApp dos clientes através de SMS",
          component: VSwitch,
          attrs: {
            class: "fluxo_optin_wpp_sms",
          },
        },
        limite_fluxo_optin_wpp_sms: {
          value: 0,
          translation: "Limite fluxo opt-in de WhatsApp via SMS",
          description:
            "Limite de dias seguidos por mês em que tentaremos obter o opt-in de WhatsApp dos clientes através de SMS",
          component: VTextField,
          attrs: {
            step: 1,
            min: 0,
            disabled: true,
            type: "number",
            class: "limite_fluxo_optin_wpp_sms",
            rules: [],
          },
        },
        limite_emails_notificaveis: {
          value: null,
          translation: "Limite de e-mails notificáveis",
          description:
            "Determina o limite de e-mails de um mesmo cliente que serão notificados por dia. Caso seja nulo, a notificação se dará em todos os e-mails disponíveis no cadastro do cliente.",
          component: VTextField,
          attrs: {
            clearable: true,
            type: "number",
            placeholder: "ilimitado",
            rules: [rules.maiorQueZero],
          },
        },
        limite_sms_notificaveis: {
          value: null,
          translation: "Limite de SMS notificáveis",
          description:
            "Determina a quantidade de telefones de um mesmo cliente que receberá SMS no mesmo dia. Caso seja nulo, notificaremos por SMS todos os telefones disponíveis no cadastro do cliente.",
          component: VTextField,
          attrs: {
            clearable: true,
            type: "number",
            placeholder: "ilimitado",
            rules: [rules.maiorQueZero],
          },
        },
        limite_ligacoes_notificaveis: {
          value: null,
          translation: "Limite de ligações notificáveis",
          description:
            "Determina a quantidade de telefones de um mesmo cliente que receberá ligação no mesmo dia. Caso seja nulo, ligaremos para todos os telefones disponíveis no cadastro do cliente.",
          component: VTextField,
          attrs: {
            clearable: true,
            type: "number",
            placeholder: "ilimitado",
            rules: [rules.maiorQueZero],
          },
        },
        limite_push_notificaveis: {
          value: null,
          translation: "Limite de web pushs notificáveis",
          description:
            "Determina quantos pushs disponíveis no cadastro do cliente vamos notificar por dia. Caso seja nulo, enviaremos notificação para todos os pushs válidos do cliente.",
          component: VTextField,
          attrs: {
            placeholder: "ilimitado",
            clearable: true,
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        whatsapp_integrado: {
          value: null,
          translation: "Habilitar WhatsApp",
          description:
            "Determina se o canal de WhatsApp está ativo para a empresa",
          component: VSwitch,
          attrs: {},
        },
        limite_whatsapps_notificaveis: {
          value: null,
          translation: "Limite de WhatsApps notificáveis",
          description:
            "Determina a quantidade de telefones de um mesmo cliente com opt-in que receberá WhatsApp no mesmo dia. Caso seja nulo, notificaremos por WhatsApp todos os telefones com opt-in disponíveis no cadastro do cliente.",
          component: VTextField,
          attrs: {
            clearable: true,
            placeholder: "ilimitado",
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        autosservico: {
          value: null,
          translation: "Autosserviço",
          description: "Ativa o Autosserviço para a empresa",
          component: VSwitch,
          attrs: {
            dense: true,
          },
        },

        dias_sem_notificar: {
          value: null,
          translation: "Dias sem notificar",
          description:
            "Determina por quantos dias devemos pausar as notificações quando o cliente alegar pagamento",
          component: VTextField,
          attrs: {
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        fake: {
          value: null,
          translation: "Contratos falsos",
          description:
            "Determina se o parceiro pode criar contratos manualmente a partir da tela de Consulta de Contratos",
          component: VSwitch,
          attrs: {},
        },
        dia_inicio_fatura: {
          value: null,
          translation: "Dia do início das faturas",
          description:
            "Dia do início das faturas. Se for nulo, será considerado o primeiro dia de cada mês.",
          component: VTextField,
          attrs: {
            placeholder: "Primeiro dia de cada mês",
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        dia_fim_fatura: {
          value: null,
          translation: "Dia do fim das faturas",
          description:
            "Dia do fim das faturas. Se for nulo, será considerado o último dia de cada mês.",
          component: VTextField,
          attrs: {
            placeholder: "Último dia de cada mês",
            type: "number",
            rules: [rules.maiorQueZero],
          },
        },
        area_logada: {
          value: null,
          translation: "Área logada",
          description:
            "Determina se vamos direcionar os clientes para a área logada do MeLembre ou para um link do parceiro",
          component: VSelect,
          attrs: {
            placeholder: "",
            items: ["MELEMBRE", "REDIRECIONAR"],
          },
        },

        fluxo_enriquecimento_externo: {
          value: null,
          translation: "Fluxo enriquecimento externo",
          description:
            "Determina se o fluxo de enriquecimento externo está ativado para a empresa.",
          component: VSwitch,
          attrs: {},
        },
        limite_dias_enriquecimento_externo: {
          value: null,
          translation: "Periodicidade do enriquecimento externo",
          description:
            "Determina o intervalo de dias entre tentativas para tentar enriquecer o cliente através do serviço externo.",
          component: VTextField,
          attrs: {
            rules: [],
            type: "number",
            placeholder: "15",
          },
        },
        limite_tentativas_enriquecimento_externo: {
          value: null,
          translation: "Limite de tentativas enriquecimento externo",
          description:
            "Determina o limite de tentativas para tentar enriquecer o cliente através do serviço externo.",
          component: VTextField,
          attrs: {
            rules: [],
            type: "number",
          },
        },
        fluxo_enriquecimento_interno: {
          value: null,
          translation: "Fluxo de enriquecimento interno",
          description:
            "Determina se o fluxo de enriquecimento interno está ativado para a empresa.",
          component: VSwitch,
          attrs: {
            dense: true,
          },
        },
        limite_dias_enriquecimento_interno: {
          value: null,
          translation: "Periodicidade do enriquecimento externo",
          description:
            "Determina o intervalo de dias entre tentativas para tentar enriquecer o cliente através do serviço interno.",
          component: VTextField,
          attrs: {
            rules: [],
            type: "number",
            placeholder: "15",
          },
        },
        limite_tentativas_enriquecimento_interno: {
          value: null,
          translation: "Limite de tentativas do enriquecimento interno",
          description:
            "Determina o limite de tentativas para tentar enriquecer o cliente através do serviço interno.",
          component: VTextField,
          attrs: {
            type: "number",
            rules: [],
          },
        },
      },
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapActions("empresa", ["getEmpresaById", "salvarEmpresa"]),
    async carregaEmpresa() {
      try {
        this.loadingEmpresa = true;

        const empresa = await this.getEmpresaById({
          empresa: this.empresaSelecionada,
          params: { incluir: "DADOS_EMPRESA" },
        });
        this.empresa = empresa;
        this.populaConfigs();
      } catch (e) {
        this.showSnackBar({ text: "Falha ao carregar empresa", type: "error" });
      } finally {
        this.loadingEmpresa = false;
        this.setConfigsWatcher();
      }
    },
    populaConfigs() {
      const configsEmpresa = Object.entries(this.empresa).map(
        ([key, value]) => {
          return { key, value };
        }
      );
      configsEmpresa.forEach((config) => {
        if (this.configs[config.key]) {
          this.$set(
            this.configs[config.key],
            "value",
            structuredClone(config.value)
          );
        }
        if (this.configs[config.key] == "cnpj") {
          this.$set(
            this.configs[config.key],
            "maskedValue",
            structuredClone(config.value)
          );
        }
      });
    },
    async salvar() {
      const form = await this.$refs.form.validate();
      if (!form) {
        const primeiroCampoComErro = this.$refs.form.inputs.find((input) => {
          return this.$refs.form.errorBag[input._uid];
        });
        console.log(primeiroCampoComErro, this.$refs.form);
        this.$vuetify.goTo(primeiroCampoComErro, this.options);
        this.showSnackBar({
          text: "Falha ao salvar, verifique os campos",
          type: "error",
        });
        return;
      }
      try {
        this.loadingSalvaConfigs = true;
        const continuar = await this.abrirDialogSalvaConfigs();
        if (!continuar) return;
        this.showSnackBar({
          text: "Configurações salvas com sucesso!",
          type: "success",
        });
        const payload = Object.entries(this.configs).reduce(
          (acc, [key, value]) => {
            acc[key] = value.value;
            return acc;
          },
          {}
        );
        payload.cnpj = payload.cnpj.replace(/[^a-zA-Z0-9 ]/g, "");

        const empresa = await this.salvarEmpresa(payload);
        this.empresa = empresa;
      } catch (e) {
        this.showSnackBar({
          text: "Falha ao salvar, erro na requisição",
          type: "error",
        });
        console.error(e);
      } finally {
        this.loadingSalvaConfigs = false;
      }
    },
    async abrirDialogSalvaConfigs() {
      return await this.$refs.DialogSalvaConfigs.openAndWaitForDecision(
        this.configs,
        this.empresa
      );
    },
    setConfigsWatcher() {
      const verificaSeMudouForm = function (newValue, oldValue) {
        const mudou = Object.entries(this.empresa).some(([key, value]) => {
          if (this.configs?.[key]) {
            if (key == "cnpj") {
              return this.unmaskedCnpj != value;
            }
            if (
              JSON.stringify(this.configs[key].value) !== JSON.stringify(value)
            ) {
              return this.configs[key].value != value;
            }
          }
        });
        if (mudou) {
          this.mudouForm = true;
        } else {
          this.mudouForm = false;
        }
      };

      this.$watch("configs", verificaSeMudouForm, {
        deep: true,
        immediate: false,
      });
      this.$watch("empresa", verificaSeMudouForm, {
        deep: true,
        immediate: false,
      });
    },
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),

    options() {
      return {
        duration: 500,
        offset: 0,
        easing: "easeInOutCubic",
        container: ".v-main__wrap",
      };
    },
    unmaskedCnpj() {
      return this.configs.cnpj.value.replace(/[^a-zA-Z0-9 ]/g, "");
    },
  },
  watch: {
    "configs.fluxo_soft_bounce.value"(newValue) {
      if (newValue) {
        this.configs.limite_soft_bounce.attrs.disabled = false;
        this.configs.limite_soft_bounce.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
      } else {
        this.configs.limite_soft_bounce.attrs.rules = [];
        this.configs.limite_soft_bounce.attrs.disabled = true;
      }
    },
    "configs.fluxo_hard_bounce.value"(newValue) {
      if (newValue) {
        this.configs.limite_hard_bounce.attrs.disabled = false;
        this.configs.limite_hard_bounce.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
      } else {
        this.configs.limite_hard_bounce.attrs.rules = [];
        this.configs.limite_hard_bounce.attrs.disabled = true;
      }
    },
    "configs.fluxo_sem_email.value"(newValue) {
      if (newValue) {
        this.configs.limite_sem_email.attrs.disabled = false;
        this.configs.limite_sem_email.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
      } else {
        this.configs.limite_sem_email.attrs.rules = [];
        this.configs.limite_sem_email.attrs.disabled = true;
      }
    },
    "configs.fluxo_enriquecimento_externo.value"(newValue) {
      if (newValue) {
        this.configs.limite_dias_enriquecimento_externo.attrs.disabled = false;
        this.configs.limite_dias_enriquecimento_externo.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
        this.configs.limite_tentativas_enriquecimento_externo.attrs.disabled = false;
        this.configs.limite_tentativas_enriquecimento_externo.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
      } else {
        this.configs.limite_dias_enriquecimento_externo.attrs.rules = [];
        this.configs.limite_dias_enriquecimento_externo.attrs.disabled = true;
        this.configs.limite_tentativas_enriquecimento_externo.attrs.rules = [];
        this.configs.limite_tentativas_enriquecimento_externo.attrs.disabled = true;
      }
    },
    "configs.fluxo_enriquecimento_interno.value"(newValue) {
      if (newValue) {
        this.configs.limite_dias_enriquecimento_interno.attrs.disabled = false;
        this.configs.limite_dias_enriquecimento_interno.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
        this.configs.limite_tentativas_enriquecimento_interno.attrs.disabled = false;
        this.configs.limite_tentativas_enriquecimento_interno.attrs.rules.push(
          (v) => (!!v && v > 0) || "Valor deve ser maior que 0"
        );
      } else {
        this.configs.limite_dias_enriquecimento_interno.attrs.rules = [];
        this.configs.limite_dias_enriquecimento_interno.attrs.disabled = true;
        this.configs.limite_tentativas_enriquecimento_interno.attrs.rules = [];
        this.configs.limite_tentativas_enriquecimento_interno.attrs.disabled = true;
      }
    },
    "configs.fluxo_optin_wpp_sms.value"(newValue) {
      if (newValue) {
        this.configs.limite_fluxo_optin_wpp_sms.attrs.disabled = false;
        this.configs.limite_fluxo_optin_wpp_sms.attrs.rules.push(
          (v) => (!!v && v >= 0) || "Valor deve ser maior ou igual a 0"
        );
      } else {
        this.configs.limite_fluxo_optin_wpp_sms.attrs.rules = [];
        this.configs.limite_fluxo_optin_wpp_sms.attrs.disabled = true;
      }
    },
  },
};
</script>

<style lang="scss">
.rate_limit_email {
  &::before {
    position: absolute;
    content: "80 e-mails/min";
    color: rgb(158, 158, 158);
    left: 1rem;
    top: 0.5rem;
  }
  input {
    color: white !important;
    display: none;
  }
}
</style>
