<template>
  <div v-click-outside="() => setSelectedDash(null)">
    <div class="gap-2 flex flex-col">
      <div
        v-for="(item, index) in value"
        :key="index"
        class="p-2 text-sm elevation-2 dashboard"
        @click="setSelectedDash(index)"
      >
        <v-slide-y-transition hide-on-leave>
          <div v-show="selectedDash === index">
            <div class="flex items-center">
              <v-text-field
                dense
                outlined
                @input="
                  value[index].nome = $event;
                  value[index].id = toSnakeCase($event);
                "
                :value="item.nome"
                :rules="[(v) => !!v || 'Campo obrigatório']"
                :label="'Nome'"
                class="mb-2 text-sm mr-2"
              ></v-text-field>
              <v-switch
                dense
                outlined
                v-model="item.interno"
                :label="'Interno'"
                class="mt-0 mb-2"
              ></v-switch>
            </div>
            <v-text-field
              dense
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório', isValidEmail]"
              @input="value[index].email = $event"
              :value="item.email"
              :label="'Email'"
              class="text-sm"
            ></v-text-field>
            <div class="actions flex justify-end">
              <v-btn
                @click.stop="setSelectedDash()"
                x-small
                v-if="item.nome && item.email"
                text
                class="mt-2"
                >incluir</v-btn
              >
              <v-btn
                @click.stop="removeDashBoard(index)"
                x-small
                text
                class="mt-2"
                >remover</v-btn
              >
            </div>
          </div>
        </v-slide-y-transition>
        <v-slide-y-reverse-transition hide-on-leave>
          <div
            v-show="selectedDash != index"
            class="flex flex-col cursor-pointer"
          >
            <span class="text-lg">{{ item.nome }}</span>
            <span
              class="text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-primary"
              >{{ item.email }}</span
            >
          </div>
        </v-slide-y-reverse-transition>
      </div>
    </div>
    <div class="flex justify-end">
      <v-btn
        :disabled="selectedDash != null"
        @click="adicionarDashBoard"
        small
        text
        class="mt-4"
        color="primary"
        ><span>Adicionar</span></v-btn
      >
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    value: {
      required: true,
    },
    attrs: {
      required: false,
    },
  },
  data() {
    return {
      selectedDash: null,
    };
  },
  methods: {
    isValidEmail(str) {
      const pattern = /^[\w\.-]+@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/;
      return pattern.test(str) || "Insira um e-mail válido";
    },
    toSnakeCase(name) {
      return name.replace(" ", "_").toLowercase();
    },
    adicionarDashBoard() {
      this.value.push({
        nome: "",
        url: "",
      });
      this.selectedDash = this.value.length - 1;
    },
    removeDashBoard(index) {
      this.value.splice(index, 1);
      this.selectedDash = null;
    },
    setSelectedDash(dashIndex = null) {
      if (this.someError) return;
      this.value.forEach((value, index) => {
        if (!value.id && !value.nome && !value.url && index != dashIndex) {
          this.value.splice(index, 1);
        }
      });
      this.selectedDash = dashIndex;
    },
  },
  computed: {
    someError() {
      const someError = this.$children.some(
        (children) => children.errorBucket?.length > 0
      );
      return someError;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  border-radius: 4px;
}
</style>
