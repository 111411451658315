var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-row',{staticClass:"d-flex align-center mb-2"},[(_vm.checkCredentials(['admin', 'editor_regua']))?_c('v-switch',{staticClass:"mr-2 ml-3",attrs:{"value":"PRODUCAO","disabled":_vm.regua.status == 'ARQUIVADA'},nativeOn:{"!click":function($event){$event.stopPropagation();_vm.getReguaById(_vm.regua.id_regua_desafiante).status === 'PRODUCAO'
          ? _vm.$emit('pararRegua', _vm.getReguaById(_vm.regua.id_regua_desafiante))
          : _vm.$emit('rodarRegua', _vm.getReguaById(_vm.regua.id_regua_desafiante))}},model:{value:(_vm.getReguaById(_vm.regua.id_regua_desafiante).status),callback:function ($$v) {_vm.$set(_vm.getReguaById(_vm.regua.id_regua_desafiante), "status", $$v)},expression:"getReguaById(regua.id_regua_desafiante).status"}}):_vm._e(),_c('router-link',{staticClass:"text-subtitle-1 regua__name text-decoration-none",class:_vm.regua.status,staticStyle:{"color":"rgba(0, 0, 0, 0.87)"},attrs:{"to":{
        name: 'eventos',
        params: {
          regua_id: _vm.getReguaById(_vm.regua.id_regua_desafiante).id,
        },
      }}},[_vm._v(_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).nome)+" "),_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-alpha-b-circle-outline")])],1),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"orange","small":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Teste A/B ")])]}}])},[_c('span',[_vm._v("Amostra início: "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).digito_verificador_inicio)+" ")]),_c('br'),_c('span',[_vm._v("Amostra fim: "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).digito_verificador_fim)+" ")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.getReguaById(_vm.regua.id_regua_desafiante).observacao),expression:"getReguaById(regua.id_regua_desafiante).observacao"}],staticClass:"ml-1",attrs:{"small":""}},on),[_vm._v("mdi-text-long")])]}}])},[_c('span',[_vm._v(_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).observacao))])]),(_vm.getReguaById(_vm.regua.id_regua_desafiante).perfis.length > 0)?_c('v-chip',{staticClass:"mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2",attrs:{"small":"","outlined":"","link":"","color":_vm.regua.status != 'PRODUCAO'
          ? 'grey'
          : 'purple'},on:{"click":function($event){$event.stopPropagation();return _vm.$emit('visualizarPerfisRegua', _vm.regua)}}},[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).perfis.length)+" "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).perfis.length > 1 ? "perfis vinculados" : "perfil vinculado")+" ")])]):_vm._e(),_c('v-spacer'),(_vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info)?_c('div',{staticClass:"shrink flex-row align-center ml-2",class:{
        'indigo--text text--accent-3':
          _vm.getReguaById(_vm.regua.id_regua_desafiante).status == 'PRODUCAO',
      }},[(
          _vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info
            .quantidade_eventos > 0
        )?_c('router-link',{staticClass:"regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none",attrs:{"to":{
          name: 'eventos',
          params: {
            regua_id: _vm.getReguaById(_vm.regua.id_regua_desafiante).id,
          },
        }}},[_c('v-chip',{staticClass:"mr-4 d-flex flex-column white--text regua__evento__text",attrs:{"outlined":"","link":"","small":"","color":_vm.getReguaById(_vm.regua.id_regua_desafiante).status != 'PRODUCAO'
              ? 'grey'
              : 'indigo accent-3'}},[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info .quantidade_eventos)+" "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info .quantidade_eventos > 1 ? "eventos" : "evento")+" "),(
                _vm.getReguaById(_vm.regua.id_regua_desafiante).tipo !=
                  'IMPLANTACAO' &&
                _vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info
                  .janela_eventos > 1
              )?_c('span',{staticClass:"text-secondary text-caption font-weight-light events__sub"},[_vm._v("em "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info .janela_eventos)+" "+_vm._s(_vm.getReguaById(_vm.regua.id_regua_desafiante).eventos_info .janela_eventos > 1 ? "dias" : "dia")+" ")]):_vm._e()])])],1):_vm._e()],1):_vm._e(),(_vm.checkCredentials(['admin', 'editor_regua']))?_c('div',{staticClass:"align-center regua__wrapper__menu mr-9"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,2097855828)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){_vm.$emit('editarRegua', _vm.getReguaById(_vm.regua.id_regua_desafiante))}}},[_c('v-list-item-title',[_vm._v("Editar Informações")])],1),_c('v-list-item',{attrs:{"to":{
              name: 'eventos',
              params: { regua_id: _vm.getReguaById(_vm.regua.id_regua_desafiante).id },
              query: {
                tipo_movimentacao_id:
                  _vm.$route.query.tipo_movimentacao_id,
              },
            }}},[_c('v-list-item-title',[_vm._v("Editar Eventos")])],1),_c('v-list-item',{on:{"click":function($event){_vm.$emit('desativarRegua', _vm.getReguaById(_vm.regua.id_regua_desafiante))}}},[_c('v-list-item-title',[_vm._v(_vm._s(_vm.regua.status != "ARQUIVADA" ? "Arquivar" : "Ativar"))])],1)],1)],1)],1):_vm._e()],1),(_vm.regua.id_regua_desafiante)?_c('v-row',{staticClass:"mt-1 mb-3 px-3",staticStyle:{"max-width":"1000px"}},_vm._l((_vm.listarEventosPorDia(
        _vm.getReguaById(_vm.regua.id_regua_desafiante).eventos
      )),function(evento){return _c('div',{key:evento.id},[_c('v-card',{staticClass:"mx-1 rounded-lg mb-2",staticStyle:{"min-width":"100px","height":"70px"}},[_c('v-card-title',{staticClass:"px-2 pt-1 pb-0 justify-center subtitle-2 white--text",style:({
            'background-color': _vm.retornaCorCard(evento.dia),
          })},[_vm._v(" "+_vm._s(_vm.retornaDiaEvento(evento))+" ")]),_c('v-card-text',{staticClass:"text-center pt-2"},[(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'EMAIL',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-email")])]}}],null,true)},[_c('span',[_vm._v("Notificação E-MAIL")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'SMS',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-message")])]}}],null,true)},[_c('span',[_vm._v("Notificação SMS")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'WHATSAPP',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-whatsapp")])]}}],null,true)},[_c('span',[_vm._v("Notificação WhatsApp")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'PUSH',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-bell")])]}}],null,true)},[_c('span',[_vm._v("Notificação PUSH")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'LIGACAO_CELULAR',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-cellphone")])]}}],null,true)},[_c('span',[_vm._v("Notificação LIGAÇÃO CELULAR")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'LIGACAO_FIXO',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-phone")])]}}],null,true)},[_c('span',[_vm._v("Notificação LIGAÇÃO FIXO")]),_c('br')]):_vm._e(),(
              _vm.contadorEventos(
                _vm.getReguaById(_vm.regua.id_regua_desafiante),
                'COMANDO',
                evento.dia
              )
            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v("mdi-xml")])]}}],null,true)},[_c('span',[_vm._v("Notificação COMANDO")]),_c('br')]):_vm._e()],1)],1)],1)}),0):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }