<template>
  <div class="ma-auto mt-16 pa-4 conteudo" v-if="checkCredentials(['token'])">
    <h1 class="mb-5 text-h5 text-center text--secondary">
      Token de autenticação
    </h1>

    <code
      class="ma-auto d-block"
      style="word-break: break-all;">
      {{ $store.state.auth.token }}
    </code>

    <h1
      id="permissoes"
      class="my-5 text-h5 text-center text--secondary">
      Permissões
    </h1>

    <div
      v-if="permissoes.length == 0"
      class="text-center text--secondary">
      Nenhuma permissão
    </div>

    <ul v-else>
      <li
        v-for="permissao in permissoes"
        v-bind:key="permissao">
        {{ permissao }}
      </li>
    </ul>

    <div
      class="ma-auto d-block">
      <v-switch
        v-model="$vuetify.theme.dark"
        @change="salvarModoEscuro()"
        inset
        label="Modo escuro"
        hide-details>
      </v-switch>
    </div>
  </div>
  <div class="pa-8 text-center mt-7" v-else>
    <h2 class="text-h5">
      Você não tem permissão para acessar essa página.<br>
      Contate o administrador.
    </h2>
    <div class="mt-4">
      <v-btn to="/" color="primary">
        Voltar ao início
      </v-btn>
    </div>
  </div>
</template>

<script>
import { checkCredentials } from "@/assets/js/utils"; 
export default {
  methods: {
    checkCredentials,
    salvarModoEscuro() {
      localStorage.setItem('melembreModoEscuro', this.$vuetify.theme.dark);
    },
  },
  computed: {
    permissoes() {
      return (this.$store.state.auth.usuario.permissoes || []).sort();
    },
  },
};
</script>

<style scoped lang="scss">
.conteudo {
  max-width: 600px;
}
</style>
