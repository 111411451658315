<template>
  <div>
    <v-app-bar app
      ><span class="text-h6 font-weight-bold ml-3"
        >{{ $route.meta.title }}
      </span>
    </v-app-bar>
    <v-container
      class="flex flex-col gap-5 w-10/12 perfisEmpresa"
      v-click-outside="() => (selectedPerfil = -1)"
    >
      <div
        v-if="loadingPerfis"
        class="flex flex-col items-center justify-center mt-12"
      >
        <v-progress-circular width="6" indeterminate color="primary" size="100">
        </v-progress-circular>
        <div class="mt-4">
          <span class="text-xl tex-grey weight">Carregando perfis...</span>
        </div>
      </div>
      <div
        v-else
        v-for="(perfil, indexPerfil) in perfis"
        :key="indexPerfil"
        class="elevation-1 rounded-md w-full bg-white perfilEmpresa"
        :class="`perfilEmpresa-${indexPerfil}`"
      >
        <div
          class="bg-gray-300 -mb-4 z-50 rounded-t-md relative text-center"
          v-if="perfil.novo"
        >
          <span class="text-sm text-gray-600">Rascunho</span>
        </div>
        <div
          class="bg-gray-300 -mb-4 z-50 rounded-t-md relative text-center"
          v-if="verificaSeAlterouPerfil(perfil) && !perfil.novo"
        >
          <span class="text-sm text-gray-600">Alterações pendentes</span>
        </div>
        <v-slide-y-transition
          hide-on-leave
          v-if="selectedPerfil == indexPerfil"
        >
          <v-form
            :ref="`form-${indexPerfil}`"
            :class="{ 'mt-6': perfil.novo || verificaSeAlterouPerfil(perfil) }"
          >
            <div v-for="(field, index) in perfil" :key="index">
              <div
                class="grid grid-cols-2 items-center py-2 px-4"
                v-if="field.component"
              >
                <div class="flex flex-col self-start">
                  <span class="text-lg text-gray-800">
                    {{ field.translation }}
                  </span>
                  <span class="text-xs text-gray-500">
                    {{ field.description }}
                  </span>
                </div>
                <component
                  class="self-center"
                  v-bind="field.attrs"
                  v-model="field.value"
                  :is="field.component"
                  dense
                ></component>
              </div>
              <v-divider v-if="field.component"></v-divider>
            </div>
            <div class="py-4 px-2 flex justify-end w-full gap-4">
              <v-btn
                color="primary"
                @click="reverterAlteracoes(perfil)"
                v-if="
                  verificaSeAlterouPerfil(perfil) &&
                  perfilJaCadastrado(perfil) &&
                  !perfil.novo
                "
                :disabled="loadingSalvar"
                text
                >Reverter alterações</v-btn
              >
              <v-btn
                color="primary"
                @click.stop="salvarPerfil(perfil, indexPerfil)"
                :disabled="!verificaSeAlterouPerfil(perfil) || loadingSalvar"
                :loading="loadingSalvar"
                >{{ perfil.novo ? "criar" : "salvar" }}</v-btn
              >
            </div>
          </v-form>
        </v-slide-y-transition>
        <v-slide-y-transition hide-on-leave v-else>
          <div
            @click.stop="selectedPerfil = indexPerfil"
            :style="{
              'border-left': '8px solid' + (perfil.tema.value.primary || 'red'),
            }"
            class="py-4 px-8 w-full flex rounded-l-lg cursor-pointer"
            :class="{ 'pt-6': perfil.novo || verificaSeAlterouPerfil(perfil) }"
          >
            <img
              class="w-24 h-20 object-contain"
              :src="perfil.url_logo.value"
              alt=""
            />

            <div class="flex flex-col ml-4">
              <span class="text-2xl">
                {{ perfil.nome_longo.value ?? "Nome longo" }}
              </span>
              <span class="text-sm text-gray-400 leading-6"
                >Nome curto: {{ perfil.nome_curto.value ?? "Nome curto" }}</span
              >
              <span class="text-sm text-gray-400"
                >Gênero: {{ perfil.genero.value ?? "Genero" }}</span
              >
            </div>
            <div>
              <h3 class="text-lg">Tema:</h3>
              <div class="flex flex-col pl-2">
                <span class="flex items-center text-xs">
                  Primária:
                  <div
                    :style="{ background: perfil.tema.value.primary }"
                    class="h-3 w-3 mx-1 rounded-lg"
                  ></div>
                  {{ perfil.tema.value.primary }}</span
                >
                <span class="flex items-center text-xs">
                  Secundária:
                  <div
                    :style="{ background: perfil.tema.value.secondary }"
                    class="h-3 w-3 mx-1 rounded-lg"
                  ></div>
                  {{ perfil.tema.value.secondary }}</span
                >
                <span class="flex items-center text-xs">
                  Destaque:
                  <div
                    :style="{ background: perfil.tema.value.accent }"
                    class="h-3 w-3 mx-1 rounded-lg"
                  ></div>
                  {{ perfil.tema.value.accent }}</span
                >
              </div>
            </div>
          </div>
        </v-slide-y-transition>
      </div>
      <FloatingBtn
        :disabled="perfis.some((perfil) => perfil.novo)"
        @click="adicionaNovoPerfil"
      />
    </v-container>
  </div>
</template>

<script>
import { VSelect, VTextField } from "vuetify/lib";
import { mapActions, mapGetters } from "vuex";
import EditorTema from "./components/EditorTema.vue";
import FileInput from "./components/FileInput.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";
import { cloneDeep } from "lodash";
export default {
  components: {
    FloatingBtn,
    FileInput,
    EditorTema,
    VSelect,
    VTextField,
  },
  data: () => ({
    loadingPerfis: false,
    perfis: [],
    selectedPerfil: null,
    loadingSalvar: false,
    fields: {
      chave: {
        value: null,
        description:
          "	Chave do perfil para ser associado aos contratos. Qualquer alteração deste campo deve ser alinhada com a equipe de T.I. para evitar divergências.",
        translation: "Chave do perfil",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      genero: {
        value: "M",
        description:
          "Indica qual gênero devemos considerar para realizar a concordância nominal nas comunicações.",
        translation: "Gênero",
        component: "v-select",
        attrs: {
          outlined: true,
          attach: true,
          items: [
            {
              text: "Masculino",
              value: "M",
            },
            {
              text: "Feminino",
              value: "F",
            },
          ],
        },
      },
      nome_longo: {
        value: null,
        translation: "Nome longo",
        description:
          "Nome longo da empresa para ser utilizado nas comunicações.",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      nome_curto: {
        value: null,
        translation: "Nome curto",
        description:
          "Nome curto da empresa para ser utilizado nas comunicações.",
        component: "v-text-field",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
          counter: 128,
          maxLength: 128,
        },
      },
      tema: {
        value: {},
        translation: "Tema",
        description:
          "Definem as cores a serem utilizadas nas telas do Autosserviço.",
        component: "editor-tema",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
        },
      },
      url_logo: {
        value: null,
        translation: "Logotipo do perfil",
        component: "file-input",
        attrs: {
          outlined: true,
          rules: [(v) => !!v || "Campo obrigatório"],
        },
      },
    },
  }),
  async created() {
    this.loadingPerfis = true;
    await this.buscaperfisEmpresa({ ordem: "-criado_em" });
    this.loadingPerfis = false;

    this.populaCampos();
  },
  mounted() {},
  methods: {
    ...mapActions("perfisEmpresa", [
      "buscaperfisEmpresa",
      "criaPerfilEmpresa",
      "alteraPerfilEmpresa",
    ]),
    ...mapActions("snackbar", ["showSnackBar"]),
    async populaCampos() {
      try {
        this.perfis = [];
        structuredClone(this.perfisEmpresa).forEach((perfil) => {
          const item = Object.entries(this.fields).map(([key, value]) => {
            if (key == "chave") {
              value.attrs.disabled = true;
            }
            return [
              key,
              {
                ...value,
                value: perfil[key],
              },
            ];
          });
          this.perfis.push(Object.fromEntries(item));
        });
        this.loadingPerfis = false;
      } catch (e) {
        console.error(e);
      }
    },
    perfilJaCadastrado(perfil) {
      const perfilJaCadastrado = this.perfisEmpresa.find(
        (item) => item.chave == perfil.chave.value
      );
      if (perfilJaCadastrado) return perfilJaCadastrado;
      return false;
    },
    verificaSeAlterouPerfil(perfil) {
      if (perfil.novo) return true;
      const perfilJaCadastrado = this.perfisEmpresa.find(
        (item) => item.chave == perfil.chave.value
      );
      if (!perfilJaCadastrado) return false;
      const valorAlterado = Object.entries(perfil).some(([key, value]) => {
        return (
          JSON.stringify(perfilJaCadastrado[key]) !==
          JSON.stringify(value.value)
        );
      });
      return valorAlterado;
    },
    reverterAlteracoes(perfil) {
      const perfilJaCadastrado = this.perfilJaCadastrado(perfil);
      try {
        Object.entries(perfilJaCadastrado).forEach(([chave, valor]) => {
          if (perfil[chave]) perfil[chave].value = valor;
        });
        this.showSnackBar({
          text: "Alterações revertidas com sucesso!",
          type: "success",
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Falha ao reverter alterações, recarregue a pagina.",
          type: "error",
        });
      }
    },
    async salvarPerfil(perfil, indexPerfil) {
      const form = this.$refs[`form-${indexPerfil}`];
      const valid = await form[0].validate();
      if (!valid) return;
      const payload = Object.entries(perfil).reduce(
        (resultObject, [key, valor]) => {
          Object.assign(resultObject, { [key]: valor.value });
          return resultObject;
        },
        {}
      );
      try {
        this.loadingSalvar = true;
        if (perfil.novo) {
          await this.criaPerfilEmpresa(payload);
          this.perfis.splice(indexPerfil, 1);
          this.populaCampos();
          this.selectedPerfil = null;
        } else {
          await this.alteraPerfilEmpresa(payload);
          this.selectedPerfil = null;
          this.$nextTick(() => {
            const perfil = document.querySelector(
              `.perfilEmpresa-${indexPerfil}`
            );
            this.$vuetify.goTo(perfil, {
              container: ".v-main__wrap",
              offset: 0,
              easing: "easeInOutCubic",
            });
          });
        }
        this.showSnackBar({
          text: "Perfil salvo com sucesso",
          type: "success",
        });
      } catch (e) {
        this.showSnackBar({ text: "Falhas ao salvar o perfil", type: "error" });
      } finally {
        this.loadingSalvar = false;
      }
    },
    async adicionaNovoPerfil(event) {
      event.stopPropagation();

      const novoPerfil = cloneDeep(this.fields);
      novoPerfil.chave.attrs.disabled = false;
      novoPerfil.chave.attrs.rules.push(this.validaChaveUnica);
      novoPerfil.novo = true;
      this.perfis.unshift(novoPerfil);
      this.selectedPerfil = 0;
      this.$nextTick(() => {
        const primeiroPerfil = document.querySelector(".perfilEmpresa");
        this.$vuetify.goTo(primeiroPerfil, {
          container: ".v-main__wrap",
          offset: 0,
          easing: "easeInOutCubic",
        });
      });
    },
    validaChaveUnica(value) {
      const chavejaExiste = this.perfisEmpresa.some(
        (perfil) => perfil.chave == value
      );
      return !chavejaExiste || "Chave em uso por outro perfil.";
    },
  },
  computed: {
    ...mapGetters("perfisEmpresa", ["perfisEmpresa"]),
  },
  watch: {
    selectedPerfil(newValue) {
      if (newValue == null || newValue < 0) return;
      this.$nextTick(() => {
        const perfilSelecionado = document.querySelector(
          `.perfilEmpresa-${newValue}`
        );
        this.$vuetify.goTo(perfilSelecionado, {
          container: ".v-main__wrap",
          offset: -40,
          easing: "easeInOutCubic",
        });
      });
    },
  },
};
</script>

<style lang="scss">
.v-input__prepend-inner {
  margin: 0px !important;
  align-items: center;
  height: 100%;
  align-self: center !important;
}
</style>
