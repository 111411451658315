<template>
  <v-app-bar app>
    <v-btn icon exact :to="telaAnterior()">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>
    <div class="text-h6 font-weight-bold ml-3">
      {{ tituloRegua }}
    </div>

    <v-chip
      v-if="$route.query.tipo"
      :color="$route.query.tipo == 'MOVIMENTACAO' ? 'green' : 'blue'"
      class="black--text font-weight-medium ml-4"
    >
      {{
        $route.query.tipo == "MOVIMENTACAO" ? "Movimentação" : "Pré-definidas"
      }}
    </v-chip>

    <v-divider style="border: none"></v-divider>
    <div v-if="this.$route.query?.tela == 'reguas'" class="my-auto">
      <v-text-field
      ref="searchInput"
      id="searchInput"
      class="white elevation-0"
      outlined
      filled
      dense
      clearable
      hide-details
      append-icon="mdi-magnify"
      label="Procurar"
      placeholder="Régua ou perfil"
      @input="$emit('search', $event)"
    ></v-text-field>
    </div>

    <v-btn
      text
      elevation="0"
      color="primary"
      class="font-weight-bold"
      @click="trocarTela()"
    >
      {{ textoBotao }}
    </v-btn>
  </v-app-bar>
</template>

<script>
export default {
  props: {
    tituloRegua: String,
    telaAtual: String,
    trocarTela: Function,
  },
  computed: {
    idRegua() {
      return this.$route.query?.id;
    },
    textoBotao() {
      if (this.idRegua && this.$route.query?.tela == "variaveis") {
        return "Régua";
      }
      let telasPossiveis = {
        reguas: "Variáveis",
        variaveis: "Réguas",
      };
      return telasPossiveis[this.telaAtual];
    },
  },
  methods: {
    telaAnterior() {
      if (this.idRegua && !this.$route.query?.tipo_movimentacao_id) {
        return `/reguas/${this.idRegua}`;
      } else if (this.idRegua && this.$route.query?.tipo_movimentacao_id) {
        let tipoMovimentacaoId = this.$route.query?.tipo_movimentacao_id;
        return `/reguas/${this.idRegua}?tipo_movimentacao_id=${tipoMovimentacaoId}`;
      } else if (this.$route.query?.tela == "variaveis") {
        return `/reguas?arquivadas=false&tipo=${this.$route.query.tipo}&tela=reguas`;
      }
      return {
        name:
          this.$route.query.tipo == "MOVIMENTACAO"
            ? "ListagemReguasMovimentacao"
            : "ListagemReguasPreDefinidas",
      };
    },
  },
};
</script>

<style lang="scss" scoped></style>