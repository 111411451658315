<template>
  <v-alert v-if="show" type="error" prominent text>
    <v-row align="center">
      <v-col class="grow">Não foi possível carregar as réguas.</v-col>
      <v-col class="shrink">
        <v-btn color="primary" @click="reload()"> Tentar novamente </v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
export default {
  name: "AlertErrorRuler",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    reload() {
      this.$emit("reload");
    },
  },
};
</script>
