<template>
  <!-- GRAFICO PIZZA - CONTRATOS POR TIPO -->
  <v-card
    class="rounded-lg mr-7 d-flex align-center tamanhoCardGrafico"
  >
    <div v-if="carregandoContrato" class="mx-auto">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-col
      v-if="
        contratos?.length > 0 && !erroCarregarContrato && !carregandoContrato
      "
    >
      <v-chart class="chart" :option="grafico.tipo_contrato" />
    </v-col>

    <v-alert
      style="width: fit-content"
      type="error"
      outlined
      class="text-center mx-10"
      v-if="erroCarregarContrato && !carregandoContrato"
    >
      Não foi possível carregar!
      <v-btn
        color="primary"
        class="ml-1 mt-2"
        @click="carregaContratosPorTipo(dataSelecionada)"
      >
        Tentar novamente
      </v-btn>
    </v-alert>

    <v-col
      v-if="
        contratos?.length == 0 && !erroCarregarContrato && !carregandoContrato
      "
      class="d-flex"
    >
      <v-alert
        type="info"
        class="mx-auto justify-center"
        outlined
        style="width: fit-content"
      >
        Nenhum dado para a data selecionada.
      </v-alert>
    </v-col>
  </v-card>
</template>

<script>
import dashboardService from "@/services/dashboards";
import { mapGetters } from "vuex";

export default {
  props: {
    dataSelecionada: null,
  },
  data: () => ({
    grafico: {
      tipo_contrato: {
        carregando: true,
        erro: false,
        title: {
          text: "Contrato por Tipo",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} - {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["58%", "58%"],
            data: [],
          },
        ],
      },
    },

    contratos: null,
    carregandoContrato: false,
    erroCarregarContrato: false,
  }),
  async created() {
    if (!this.dataSelecionada) {
      this.carregaContratosPorTipo("ultima_atualizacao");
    } else {
      this.carregaContratosPorTipo(this.dataSelecionada);
    }
  },
  methods: {
    async carregaContratosPorTipo(data) {
      this.carregandoContrato = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        data_referencia: data,
        parametro: "tipo",
      };

      try {
        const { data } = await dashboardService.getCarteiraClientes(payload);
        this.contratos = data.tipo;
        this.adicionaValorGrafico();
        this.carregandoContrato = false;
        this.erroCarregarContrato = false;
      } catch {
        this.erroCarregarContrato = true;
        this.carregandoContrato = false;
      }
    },
    adicionaValorGrafico() {
      this.grafico.tipo_contrato.series[0].data = [];
      this.contratos?.forEach((contrato) => {
        this.grafico.tipo_contrato.series[0].data.push({
          value: contrato.total,
          name: contrato.nome,
        });
      });
    },
  },
  watch: {
    dataSelecionada() {
      this.carregaContratosPorTipo(this.dataSelecionada);
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregaContratosPorTipo(this.dataSelecionada);
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style scoped lang="scss">
.tamanhoCardGrafico {
  height: 260px;
  width: 423px !important;
}

.chart {
  height: 230px;
  width: 350px;
}
</style>
