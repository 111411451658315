<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card>
        <v-toolbar dark color="primary">
          <v-toolbar-title
            >Contrato por {{ nomeGrafico }} -
            {{ formataData(dataReferencia) }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon dark @click="fecharDialog">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <div class="text-center mt-12" v-if="carregandoGrafico">
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <v-card
          class="rounded-lg d-flex mx-auto mt-2 elevation-0"
          style="width: 1000px"
          v-if="
            graficoOrigem ||
            graficoPlano ||
            (graficoDimensao && !carregandoGrafico)
          "
        >
          <v-col class="pb-0">
            <v-chart
              class="bar-chart"
              :style="{ height: `${calculaAlturaGrafico()}px` }"
              :option="todoValoresGrafico"
            />
          </v-col>
        </v-card>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import dashboardService from "@/services/dashboards";
import { mapGetters } from "vuex";

export default {
  props: {
    dataReferencia: null,
    grafico: "",
  },
  data: () => ({
    dialog: false,
    graficoOrigem: null,
    graficoPlano: null,
    graficoDimensao: null,
    nomeGrafico: "",
    carregandoGrafico: false,

    todoValoresGrafico: {
      grid: {
        left: 120,
      },
      xAxis: {
        type: "value",
      },
      yAxis: {
        type: "category",
        data: [],
        inverse: true,
        axisLabel: {
          show: true,
          fontSize: 16,
          overflow: "truncate",
          width: 100,
        },
      },
      series: [
        {
          data: [],
          type: "bar",
          label: {
            normal: {
              position: "right",
              distance: 10,
              show: true,
              formatter: {},
            },
          },
        },
      ],
    },
  }),
  created() {},
  methods: {
    abrirDialog() {
      this.carregandoGrafico = true;
      this.todoValoresGrafico.yAxis.data = [];
      this.todoValoresGrafico.series[0].data = [];
      this.dialog = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        data_referencia: this.dataReferencia,
        parametro:
          this.grafico != "contratos_por_plano" ? this.grafico : undefined,
      };
      if (this.grafico != "contratos_por_plano") {
        dashboardService
          .getCarteiraClientes(payload)
          .then((retorno) => {
            if (this.grafico == "origem") {
              this.graficoOrigem = retorno.data.origem;
              this.nomeGrafico = "origem";

              this.graficoOrigem.forEach((origem) => {
                this.todoValoresGrafico.yAxis.data.push(origem.nome);
                this.todoValoresGrafico.series[0].data.push(origem.total);
              });
            } else {
              this.graficoDimensao = retorno.data.dimensao_3;
              this.nomeGrafico = this.nomeDimensao("dimensao_3");

              this.graficoDimensao.forEach((dimensao) => {
                this.todoValoresGrafico.yAxis.data.push(dimensao.nome);
                this.todoValoresGrafico.series[0].data.push(dimensao.total);
              });
            }
            this.carregandoGrafico = false;
          })
          .catch((erro) => {
            console.error(erro);
            this.carregandoGrafico = false;
          });
      } else {
        dashboardService
          .getContratosPorPlano(payload)
          .then((retorno) => {
            this.graficoPlano = retorno.data.contratos_por_plano;
            this.nomeGrafico = "plano";

            this.graficoPlano.forEach((plano) => {
              this.todoValoresGrafico.yAxis.data.push(plano.nome);
              this.todoValoresGrafico.series[0].data.push(plano.total);
            });
            this.carregandoGrafico = false;
          })
          .catch((erro) => {
            console.error(erro);
            this.carregandoGrafico = false;
          });
      }
    },

    formataData(data) {
      if (!data) return null;
      const [ano, mes, dia] = data.split("-");
      return `${dia}/${mes}/${ano}`;
    },

    calculaAlturaGrafico() {
      if (this.grafico == "origem") {
        return this.graficoOrigem.length * 45;
      } else if (this.grafico == "contratos_por_plano") {
        return this.graficoPlano.length * 45;
      } else {
        return this.graficoDimensao.length * 45;
      }
    },
    fecharDialog() {
      this.dialog = false;
      this.nomeGrafico = "";
      this.graficoOrigem = null;
      this.graficoPlano = null;
      this.graficoDimensao = null;

      this.todoValoresGrafico.yAxis.data = [];
      this.todoValoresGrafico.series[0].data = [];
    },
    nomeDimensao(dimensao) {
      if (!dimensao) {
        return;
      }
      let dimensoes = this.empresaSelecionada.dimensoes_contrato || {};

      return dimensoes[dimensao] || dimensao.replace("dimensao_", "Dimensão ");
    },
  },
  watch: {},
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style scoped lang="scss">
.bar-chart {
  width: 980px;
}
</style>
