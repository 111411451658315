import http from '@/plugins/axios';
import store from '@/store';

export default {
  logUsuario(params) {
    let empresa = store.getters['empresa/empresaSelecionada'];

    return http.api.get(`${empresa.url_instancia}/v1/log_usuario`, {
      params: params,
    });
  },
}
