<template>
  <v-dialog
    width="1000"
    @keydown.esc="$emit('update:dialog', false)"
    @click:outside="$emit('update:dialog', false)"
    v-model="dialog"
  >
    <v-card class="card-preview">
      <v-card-title class="grey lighten-2 py-3">
        <div>
          <div class="text-h6">Assunto: {{ template.assunto }}</div>
          <div class="text-subtitle-2 text--secondary">
            <span v-html="preVisualizacaoAssunto"></span>
            <em class="font-weight-regular"> (pré-visualização)</em>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-btn icon class="ma-0" @click="$emit('update:dialog', false)">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text v-if="template.html" class="pa-0 card-text">
        <v-progress-circular
          v-if="loadingTemplate"
          indeterminate
          color="grey"
          class="d-flex mx-auto mt-8"
        ></v-progress-circular>
        <iframe
          v-show="!loadingTemplate"
          style="height: 100% !important"
          class="preview-frame"
          ref="iframePreview"
          :srcdoc="template.html"
          @load="estilosIFrame"
        ></iframe>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import variavelMensagemService from "@/services/variavel-mensagem";

export default {
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    template: {
      type: Object,
      require: true,
    },
  },
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),
    preVisualizacaoAssunto() {
      return variavelMensagemService.preVisualizarMensagem(this.template.assunto);
    },
  },
  data: () => ({
    loadingTemplate: true,
  }),
  methods: {
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    estilosIFrame() {
      const frame = this.$refs.iframePreview.contentWindow;
      const css = document.createElement("style");
      css.textContent = `
        ::-webkit-scrollbar {
          width: 4px;
          margin-left: 1px;
        }
        ::-webkit-scrollbar-track {
          background: transparent;
        }
        ::-webkit-scrollbar-thumb {
          background-color: #bdbdbd;
          border-radius: 25px;
          padding-right: 0px;
        }
        button, a {
          pointer-events: none;
        }
      `;
      frame.document.head.appendChild(css);
      this.loadingTemplate = false;
    },
  },
};
</script>

<style scoped lang="scss">
.preview-frame {
  border: none;
  width: 100%;
}
.card-preview {
  min-height: 85vh !important;
}
.card-text {
  height: calc(85vh - 85px);
}
</style>
