<template>
  <div>
    Usuarios
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  data: () => {
    return {
      loading: false
    }
  },
  async mounted(){
    await this.carregaUsuarios()
  },
  methods: {
    ...mapActions('usuarios', ['getUsuarios']),
    async carregaUsuarios() {
      try {
        await this.getUsuarios()
      } catch (e) {
        console.error(e)
      }
    }
  },
  computed: {
    ...mapGetters('usuarios', 'usuarios')
  },
}
</script>

<style lang="scss" scoped>

</style>