<template>
  <v-dialog v-model="dialog" :width="800" max-width="100%" persistent>
    <v-card>
      <v-form @submit.prevent="salvar()" ref="form" lazy-validation class="d-flex flex-column h-100">
        <v-card-title>
          <h3 class="mb-2">
            <span class="text-capitalize">{{ operacao }}</span>
            NPS
          </h3>
        </v-card-title>

        <v-card-text class="text-card">
          <div class="campos mt-1">
            <v-textarea v-model="nps.pergunta" label="Pergunta" rows="2" outlined required
              :rules="regras.exigido"></v-textarea>

            <v-textarea v-if="nps.dashboard" v-model="nps.dashboard" label="Dashboard" rows="1" outlined
              :rules="regras.url"></v-textarea>
          </div>

          <div class="opcoes">

            <div v-if="!mostrarOpcoesAdicionais" class="ativador">
              <v-btn color="primary" text @click="maisOpcoes = true">
                Adicionar Opções
              </v-btn>
            </div>

            <div v-if="mostrarOpcoesAdicionais" class="extras">
              <h3>Opções</h3>

              <div class="detratoras">
                <div class="titulo">
                  <span>Detratoras</span>
                  <v-btn icon @click="adicionarCampo('detratoras')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
              </div>
              <div class="campo" v-for="(item, index) in opcoesDetratoras" :key="`detratora-${index}`">
                <v-text-field v-model="nps.opcoes_detratoras[index]" :label="`Detratora ${index + 1}`" outlined
                  :rules="regras.exigido"></v-text-field>
                <v-btn class="mt-3" icon @click="removerCampo('detratoras', index)">
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </div>

              <div class="neutras">
                <div class="titulo">
                  <span>Neutras</span>
                  <v-btn icon @click="adicionarCampo('neutras')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div class="campo" v-for="(item, index) in opcoesNeutras" :key="`neutro-${index}`">
                  <v-text-field v-model="nps.opcoes_neutras[index]" :label="`Neutra ${index + 1}`" outlined
                    :rules="regras.exigido"></v-text-field>
                  <v-btn class="mt-3" icon @click="removerCampo('neutras', index)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>

              <div class="promotoras">
                <div class="titulo">
                  <span>Promotoras</span>
                  <v-btn icon @click="adicionarCampo('promotoras')">
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </div>
                <div class="campo" v-for="(item, index) in opcoesPromotoras" :key="`promotor-${index}`">
                  <v-text-field v-model="nps.opcoes_promotoras[index]" :label="`Promotora ${index + 1}`" outlined
                    :rules="regras.exigido"></v-text-field>
                  <v-btn class="mt-3" icon @click="removerCampo('promotoras', index)">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </div>
              </div>
            </div>
          </div>

        </v-card-text>

        <v-card-actions class="">
          <v-spacer></v-spacer>
          <v-btn color="primary" text class="text-uppercase" @click="fechar()"> Cancelar </v-btn>
          <v-btn color="primary" class="text-uppercase" type="submit">
            Salvar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import rules from "@/assets/js/rules";

export default {
  data() {
    return {
      dialog: false,
      operacao: '',
      nps: {
        pergunta: '',
        dashboard: '' || null,
        opcoes_detratoras: [],
        opcoes_neutras: [],
        opcoes_promotoras: [],
      },
      maisOpcoes: false,
      regras: rules,
    }
  },
  computed: {
    mostrarOpcoesAdicionais() {
      return this.maisOpcoes || (this.opcoesDetratoras?.length || this.opcoesNeutras?.length || this.opcoesPromotoras?.length);
    },
    opcoesDetratoras() {
      return this.nps?.opcoes_detratoras;
    },
    opcoesNeutras() {
      return this.nps?.opcoes_neutras;
    },
    opcoesPromotoras() {
      return this.nps?.opcoes_promotoras;

    },
  },
  methods: {
    async abrir({ operacao, nps = null }) {
      this.operacao = operacao;
      if (nps) this.nps = { ...nps };
      this.dialog = true;
      return;
    },
    resetarNps() {
      this.nps = {
        pergunta: '',
        dashboard: null,
        opcoes_detratoras: [],
        opcoes_neutras: [],
        opcoes_promotoras: [],
      };
      return;
    },
    fechar() {
      this.dialog = false;
      this.maisOpcoes = false;
      this.$refs.form.reset();
      this.resetarNps();
      return;
    },
    async salvar() {
      if (await this.$refs.form.validate()) {
        if (this.nps.dashboard === '') this.nps.dashboard = null;

        await this.$emit(this.operacao, { ...this.nps });
        this.fechar();
      }
      return;
    },
    adicionarCampo(campo) {
      if (!this.nps[`opcoes_${campo}`]) this.nps[`opcoes_${campo}`] = [];

      this.nps[`opcoes_${campo}`].push('');
      return;
    },
    removerCampo(campo, index) {
      this.nps[`opcoes_${campo}`].splice(index, 1);
      return;
    },
  }
}
</script>

<style lang="scss" scoped>
.h-100 {
  height: 100%;
}

.text-card {
  max-height: 70vh;
  overflow: auto;
}

.select-text {
  text-transform: lowercase !important;

  &:first-letter {
    text-transform: uppercase !important;
  }
}

.opcoes {
  display: flex;

  .ativador {
    display: flex;
    margin-left: auto;

    button {
      text-transform: uppercase;
    }
  }

  .extras {
    width: 100%;

    .titulo {
      display: flex;
      align-items: center;

      button {
        margin-left: auto;
      }
    }

    .campo {
      display: flex;
    }
  }
}
</style>