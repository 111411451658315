<template>
  <div>
    <v-dialog
      max-width="700"
      v-model="dialogDesconto"
      @click:outside="fecharDialogDesconto()"
    >
      <v-form
        ref="formDesconto"
        lazy-validation
        @submit="salvarDesconto($event)"
      >
        <v-card>
          <v-card-title
            >{{ descontoId ? "Editar" : "Criar" }} Desconto</v-card-title
          >
          <v-card-text class="pt-7 pb-0 d-flex">
            <v-text-field
              type="text"
              label="Descrição"
              @keydown.enter="adicionarDescontoFatura(faturaEmAberto)"
              outlined
              v-model="desconto.descricao"
              required
              :rules="[rules.required]"
              :hint="
                desconto.descricao && desconto.descricao.length >= 256
                  ? 'Descrição limitada à 256 caracteres'
                  : ''
              "
              maxLength="256"
            ></v-text-field>
            <v-text-field
              type="number"
              label="Valor desconto"
              class="ml-2"
              outlined
              v-model="desconto.total"
              style="width: 60px"
              required
              :rules="[rules.required]"
              prefix="R$"
            ></v-text-field
          ></v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="fecharDialogDesconto()">
              cancelar
            </v-btn>
            <v-btn color="primary" type="submit"> salvar </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
    <v-dialog v-model="dialogApagarDesconto" max-width="400">
      <v-form
        ref="formDeletarDesconto"
        lazy-validation
        @submit="apagarDesconto($event)"
      >
        <v-card>
          <v-card-title>Deseja apagar o desconto selecionado?</v-card-title>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="fecharDialogDesconto()">
              Não
            </v-btn>
            <v-btn color="primary" type="submit"> Sim </v-btn>
          </v-card-actions>
        </v-card>
      </v-form>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },

      desconto: {
        descricao: null,
        total: null,
      },
      descontoId: null,
      descontoFatura: null,
      dialogDesconto: false,
      dialogApagarDesconto: false,
    };
  },

  methods: {
    ...mapActions("fatura", {
      editaOuCriaItemFatura: "editaOuCriaItemFatura",
      deletaItemFatura: "deletaItemFatura",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapMutations("fatura", {
      setItemFaturaSelecionado: "setItemFaturaSelecionado",
    }),
    abrirDialog(desconto) {
      if (desconto) {
        this.descontoId = desconto.id;
        this.desconto.descricao = desconto.descricao;
        this.desconto.total = desconto.total;
      }
      this.dialogDesconto = true;
      this.$nextTick(() => this.$refs.formDesconto.resetValidation());
    },
    fecharDialogDesconto() {
      this.$refs.formDesconto.reset();
      this.dialogDesconto = false;
      this.dialogApagarDesconto = false;
    },
    abrirDialogApagarDesconto(desconto) {
      this.setItemFaturaSelecionado(desconto);
      this.dialogApagarDesconto = true;
    },
    async salvarDesconto(event) {
      event.preventDefault();

      const validaCampos = this.$refs.formDesconto.validate();
      if (!validaCampos) return;

      let payload = {
        id: this.descontoId || undefined,
        fatura_id: this.$route.params.id || undefined,
        referencia: "DESCONTO",
        descricao: this.desconto.descricao,
        total: this.desconto.total,
      };
      this.editaOuCriaItemFatura(payload)
        .then(() => {
          this.showSnackBar({
            text: `Desconto ${
              this.descontoId ? "editado" : "criado"
            } com sucesso!`,
          });
          this.$emit("salvar");
          this.$refs.formDesconto.reset();
          this.dialogDesconto = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao ${this.descontoId ? "editar" : "criar"} o desconto!`,
          });
        });
    },
    apagarDesconto(event) {
      event.preventDefault();
      this.deletaItemFatura(this.itemFaturaSelecionado)
        .then(() => {
          this.showSnackBar({
            text: `Desconto apagado com sucesso!`,
          });
          this.$emit("carregarFatura");
          this.dialogApagarDesconto = false;
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao apagar o desconto!`,
          });
        });
    },
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("fatura", {
      itemFaturaSelecionado: "itemFaturaSelecionado",
    }),
  },
};
</script>

<style></style>
