<template>
  <v-row justify="center" v-if="dialog">
    <v-dialog v-model="dialog" persistent max-width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5">Filtros</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12" sm="6">
                <v-menu
                  offset-y
                  transition="scale-transition"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="dateRangeTexto"
                      readonly
                      hide-details
                      v-bind="attrs"
                      label="Data da notificação"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="dataSelecionada"
                    range
                    no-title
                    locale="pt-br"
                  >
                  </v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="tiposEventoSelecionado"
                  :items="itemsTipoEvento"
                  text
                  label="Tipo Evento"
                  multiple
                  :value="tiposEventoSelecionado.valor"
                  no-data-text="Nenhum filtro cadastrado"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 1">{{ `${item.text}` }} &nbsp; </span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      +{{ tiposEventoSelecionado.length - 1 }}
                      {{
                        tiposEventoSelecionado.length == 2
                          ? "selecionado"
                          : "selecionados"
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="tiposNotificacaoSelecionado"
                  :items="itemsTipoNotificacao"
                  text
                  label="Tipo Notificação"
                  multiple
                  :value="tiposNotificacaoSelecionado?.valor"
                  no-data-text="Nenhum filtro cadastrado"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 1">{{ `${item.text}` }} &nbsp; </span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      +{{ tiposNotificacaoSelecionado.length - 1 }}
                      {{
                        tiposNotificacaoSelecionado.length == 2
                          ? "selecionado"
                          : "selecionados"
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="statusNotificacaoSelecionado"
                  :items="itemsStatusNotificacao"
                  text
                  label="Status Notificação"
                  multiple
                  :value="statusNotificacaoSelecionado?.valor"
                  no-data-text="Nenhum filtro cadastrado"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 1">{{ `${item.text}` }} &nbsp; </span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      +{{ statusNotificacaoSelecionado.length - 1 }}
                      {{
                        statusNotificacaoSelecionado.length == 2
                          ? "selecionado"
                          : "selecionados"
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="contratoSelecionado"
                  :items="itemsContrato"
                  text
                  label="Contratos"
                  no-data-text="Nenhum filtro cadastrado"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 1">{{ `${item.text}` }} &nbsp; </span>
                    <span v-if="index === 1" class="grey--text text-caption">
                      +{{ tiposNotificacaoSelecionado.length - 1 }}
                      {{
                        tiposNotificacaoSelecionado.length == 2
                          ? "selecionado"
                          : "selecionados"
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
              <v-col cols="12" sm="6">
                <v-select
                  v-model="tiposReguasSelecionado"
                  :items="tiposReguaComFiltro"
                  item-text="text"
                  item-value="tipo"
                  label="Tipos de Réguas"
                  multiple
                  no-data-text="Nenhum filtro cadastrado"
                >
                  <template v-slot:selection="{ item, index }">
                    <span v-if="index < 2">{{ `${item.text}` }} &nbsp; </span>
                    <span v-if="index === 2" class="grey--text text-caption">
                      +{{ tiposReguasSelecionado.length - 2 }}
                      {{
                        tiposReguasSelecionado.length == 3
                          ? "selecionado"
                          : "selecionados"
                      }}
                    </span>
                  </template>
                </v-select>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> Fechar </v-btn>
          <v-btn color="primary" @click="aplicarFiltros()"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import moment from "moment";
import { mapActions, mapGetters, mapMutations } from "vuex";
import Vue from "vue";

export default {
  data: () => ({
    dialog: false,
    trocandoEmpresa: false,
    itemsTipoEvento: [
      {
        text: "Comando",
        value: "COMANDO",
      },
      {
        text: "E-mail",
        value: "EMAIL",
      },
      {
        text: "Fast SMS",
        value: "FAST_SMS",
      },
      {
        text: "Ligação Celular",
        value: "LIGACAO_CELULAR",
      },
      {
        text: "Ligação Fixo",
        value: "LIGACAO_FIXO",
      },
      {
        text: "Push",
        value: "PUSH",
      },
      {
        text: "Sms",
        value: "SMS",
      },
      {
        text: "WhatsApp",
        value: "WHATSAPP",
      },
    ],
    itemsTipoNotificacao: [
      { text: "Régua de Comunicação", value: "REGUA_COMUNICACAO" },
      { text: "Fluxo Automático", value: "FLUXO_AUTOMATICO" },
      { text: "Quite Já", value: "QUITEJA" },
    ],
    itemsStatusNotificacao: [
      { text: "Enviado", value: "ENVIADO" },
      { text: "Não enviado", value: "NAO_ENVIADO" },
    ],
    itemsContrato: [],
    itemsReguas: [],
  }),
  created() {
    this.itemsContrato = []
    this.carregarReguasEmpresa();
  },
  methods: {
    ...mapActions("reguas", {
      carregarReguas: "carregarReguas",
    }),
    ...mapMutations("filtros", {
      setFiltrosTipoEvento: "setFiltrosTipoEvento",
      setFiltrosTipoNotificacao: "setFiltrosTipoNotificacao",
      setFiltrosStatusNotificacao: "setFiltrosStatusNotificacao",
      setFiltrosAplicados: "setFiltrosAplicados",
      setFiltrosData: "setFiltrosData",
      setFiltroContrato: "setFiltroContrato",
      setFiltrosTipoRegua: "setFiltrosTipoRegua",
    }),
    abrirDialog(contratos) {
      this.carregarItemsContratos(contratos.objects);
      this.dialog = true;
    },
    carregarItemsContratos(contratos) {
      this.itemsContrato = []
      contratos.forEach((contrato) => {
        this.itemsContrato.push({
          text: `${contrato.tipo} - #${contrato.id}`,
          value: contrato,
        });
      });
    },
    async carregarReguasEmpresa() {
      // const response = await this.carregarReguas({limite: 1000});
      // let tiposReguas = [];
      // this.reguas.objects.forEach((regua) => {
      //   tiposReguas.push({
      //     text: Vue.filter("tipoRegua")(regua.tipo),
      //     value: regua.tipo,
      //   })
      // })
      // console.log(tiposReguas, response)
      return
    },
    fecharDialog() {
      this.dialog = false;
    },
    limparFiltrosDialog() {
      this.tiposEventoSelecionado = null;
      this.tiposNotificacaoSelecionado = null;
      this.statusNotificacaoSelecionado = null;
      this.contratoSelecionado = null;
      this.tiposReguasSelecionado = null;
    },
    aplicarFiltros() {
      this.$emit("aplicarFiltros");
      this.setFiltrosAplicados();
      this.dialog = false;
    },
  },
  computed: {
    ...mapGetters("reguas", {
      reguas: "reguas",
      tiposRegua: "tiposRegua"
    }),
    ...mapGetters("filtros", {
      filtrosTipoEvento: "filtrosTipoEvento",
      filtrosTipoNotificacao: "filtrosTipoNotificacao",
      filtrosStatusNotificacao: "filtrosStatusNotificacao",
      filtrosData: "filtrosData",
      filtroContrato: "filtroContrato",
      filtrosTipoRegua: "filtrosTipoRegua",
    }),
    tiposReguaComFiltro(){
      return this.tiposRegua(Vue.filter("tipoRegua"))
    },
    tiposReguasSelecionado: {
      get: function () {
        return this.filtrosTipoRegua.map((filter) => filter?.valor);
      },
      set: function (value) {
        this.setFiltrosTipoRegua(value);
      },
    },
    tiposEventoSelecionado: {
      get: function () {
        return this.filtrosTipoEvento.map((filter) => filter?.valor);
      },
      set: function (value) {
        this.setFiltrosTipoEvento(value);
      },
    },
    statusNotificacaoSelecionado: {
      get: function () {
        return this.filtrosStatusNotificacao.map((filter) => filter?.valor);
      },
      set: function (value) {
        this.setFiltrosStatusNotificacao(value);
      },
    },
    tiposNotificacaoSelecionado: {
      get: function () {
        return this.filtrosTipoNotificacao.map((filter) => filter?.valor);
      },
      set: function (value) {
        this.setFiltrosTipoNotificacao(value);
      },
    },
    dataSelecionada: {
      get: function () {
        return this.filtrosData?.valor;
      },
      set: function (value) {
        this.setFiltrosData(value);
      },
    },
    contratoSelecionado: {
      get: function () {
        return this.filtroContrato.valor;
      },
      set: function (value) {
        this.setFiltroContrato(value);
      },
    },
    dateRangeTexto() {
      if (!this.dataSelecionada) return;
      let dataUmFormatada = moment(this.dataSelecionada[0]).format("L");
      let dataDoisFormatada = moment(this.dataSelecionada[1]).format("L");

      if (!this.dataSelecionada) return;
      if (this.dataSelecionada.length == 2) {
        if (this.dataSelecionada[0] > this.dataSelecionada[1]) {
          return `${dataDoisFormatada} ~ ${dataUmFormatada}`;
        } else if (this.dataSelecionada[0] == this.dataSelecionada[1]) {
          return `${dataUmFormatada}`;
        } else {
          return `${dataUmFormatada} ~ ${dataDoisFormatada}`;
        }
      } else {
        return `${dataUmFormatada}`;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
