<template>
  <v-dialog v-model="dialog" :width="450">
    <v-card>
      <v-card-title>
        <h3>
          Tem certeza que deseja salvar?
        </h3>
      </v-card-title>
      <v-card-text>
        <span class="text-subtitle-1">
          Alguns campos não podem ser editados.
        </span>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="dialog = false">
          Cancelar
        </v-btn>
        <v-btn color="primary" @click="$emit('confirmarAdicao'), dialog = false">
          Confirmar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
    }
  },
  methods: {
    async abrir() {
      this.dialog = true;
      return;
    },
  }
}
</script>

<style lang="scss" scoped>
h3 {
  word-break: initial;
}
</style>