<template>
  <v-dialog v-model="dialog" :width="600" persistent>
    <v-form
      v-model="formularioValido"
      @submit="salvarMovimentacao($event)"
      ref="form"
      lazy-validation
    >
      <v-card>
        <v-card-title class="text-h5 title-card">
          {{
            idMovimentacao
              ? "Editar Tipo Movimentação"
              : "Novo Tipo de Movimentação"
          }}
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-text-field
            label="Nome"
            outlined
            autofocus
            required
            counter
            :maxlength="30"
            :rules="[campoRules.limitado]"
            v-model="nome"
          ></v-text-field>
          <v-textarea
            label="Descrição"
            rows="3"
            outlined
            :maxlength="maxLength"
            v-model="descricao"
          ></v-textarea>
          <span
            style="margin-top: -25px"
            class="d-flex justify-end"
            :class="conteudoDescricao > maxLength ? 'counter-sms' : ''"
            >{{ conteudoDescricao || 0 }} / {{ maxLength }}</span
          >
          <v-text-field
            label="Chave"
            outlined
            required
            class="mt-4"
            v-model="chave"
            :rules="campoRules.chave"
          ></v-text-field>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> cancelar </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :loading="salvandoTipoMovimentacao"
            :disabled="!formularioValido"
          >
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      maxLength: 130,
      nome: null,
      descricao: null,
      chave: null,
      textDescricao: "",
      tamanhoDescricao: 0,
      idMovimentacao: null,
      formularioValido: false,
      salvandoTipoMovimentacao: false,
      listaDeReguas: null,
      campoRules: {
        chave: [
          (valor) => (valor || "").length > 0 || "Campo obrigatório",
          (valor) => {
            const matches = valor.match(/[^\w\s]/g);
            return !matches || "Não é permitido caractéres especiais";
          },
        ],
        limitado: (value) =>
          (value && value?.length <= 30) || "Apenas 30 caracteres permitidos",
      },
    };
  },

  methods: {
    ...mapActions("snackbar", ["showSnackBar"]),
    ...mapActions("tipoMovimentacao", {
      salvarTipoMovimentacao: "salvarTipoMovimentacao",
    }),
    async salvarMovimentacao(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      this.salvandoTipoMovimentacao = true;
      let nomeMovimentacao = this.listaTiposMovimentacao.objects.find(
        (tipo) => {
          return (
            this.chave.toUpperCase() == tipo.chave &&
            this.idMovimentacao != tipo.id
          );
        }
      );
      if (nomeMovimentacao) {
        this.showSnackBar({
          text: "A chave já existe.",
        });
        this.salvandoTipoMovimentacao = false;
        return;
      }
      event.preventDefault();
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        nome: this.nome,
        descricao: this.descricao,
        chave: this.chave.toUpperCase(),
      };
      if (this.idMovimentacao)
        payload = { ...payload, id: this.idMovimentacao };
      await this.salvarTipoMovimentacao(payload)
        .then((retorno) => {
          this.salvandoTipoMovimentacao = false;
          retorno = { ...retorno, reguas: this.listaDeReguas };

          this.$emit("atualizarListaMovimentacoes", retorno);
          this.fecharDialog();
          this.showSnackBar({
            text: "Tipo de movimentação salva com sucesso!",
          });
        })
        .catch((error) => {
          console.error(error);
          this.salvandoTipoMovimentacao = false;
          this.showSnackBar({
            text: "Erro ao salvar o tipo de movimentação!",
          });
        });
    },
    fecharDialog() {
      this.dialog = false;
      this.nome = null;
      this.descricao = null;
      this.chave = null;
      this.idMovimentacao = null;
      this.listaDeReguas = [];
      this.$refs.form.reset();
    },
    abrirDialog(movimentacao) {
      if (movimentacao) {
        this.idMovimentacao = movimentacao.id;
        this.nome = movimentacao.nome;
        this.descricao = movimentacao.descricao;
        this.chave = movimentacao.chave;
      }
      if (movimentacao?.reguas) this.listaDeReguas = movimentacao?.reguas;
      this.dialog = true;
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("tipoMovimentacao", {
      listaTiposMovimentacao: "listaTiposMovimentacao",
    }),
    conteudoDescricao() {
      this.textDescricao = this.descricao;
      if (this.textDescricao == null) return;
      return this.tamanhoDescricao + this.textDescricao.length;
    },
  },
};
</script>

<style scoped lang="scss">
.counter-sms {
  color: red;
}
</style>
