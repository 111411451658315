<template>
  <div class="fill-height">
    <div class="fill-height d-flex">
      <v-container class="grow">
        <router-view :key="empresaSelecionada.id"></router-view>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  mounted() {},
  data() {
    return {
      list: 0,

      right: null,
    };
  },
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
  },
};
</script>

<style></style>
