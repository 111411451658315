<template>
  <div v-click-outside="() => setSelectedItem()">
    <div v-if="value">
      <div
        class="gap-2 flex flex-col"
        v-for="(config, configKey) in value"
        :key="configKey"
      >
        <span class="text-xl capitalize">{{
          configKey.replace("_", " ")
        }}</span>
        <div
          v-for="(item, index) in config"
          :key="index"
          class="p-2 text-sm elevation-2 dashboard"
        >
          <div v-for="(valor, chave) in item" :key="chave">
            <v-slide-y-transition hide-on-leave>
              <div
                v-show="selectedItem && selectedItem?.[configKey] == index"
                class="flex flex-col"
              >
                <div class="flex">
                  <v-text-field
                    dense
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    outlined
                    hide-details
                    label="chave"
                    :value="chave"
                    class="mr-1 text-xs"
                    @blur="setNovaChave(configKey, index, chave, $event)"
                  ></v-text-field>
                  <v-text-field
                    dense
                    outlined
                    :rules="[(v) => !!v || 'Campo obrigatório']"
                    hide-details
                    label="Nome do perfil"
                    :value="valor"
                    class="text-xs"
                    @input="value[configKey][index][chave] = $event"
                  ></v-text-field>
                </div>
                <div class="flex justify-end">
                  <v-btn
                    @click.stop="setSelectedItem()"
                    x-small
                    v-if="value[configKey][index][chave] && chave"
                    text
                    class="mt-2"
                    >incluir</v-btn
                  >
                  <v-btn
                    @click.stop="removeItem(index, configKey)"
                    x-small
                    text
                    class="mt-2"
                    >remover</v-btn
                  >
                </div>
              </div>
            </v-slide-y-transition>
            <v-slide-y-reverse-transition hide-on-leave>
              <div
                @click="setSelectedItem(index, configKey)"
                v-show="selectedItem?.[configKey] != index"
                class="cursor-pointer pa-1"
              >
                <span class="text-sm">{{ valor }}</span>
              </div>
            </v-slide-y-reverse-transition>
          </div>
        </div>
        <div class="flex justify-end">
          <v-btn
            :disabled="selectedItem != null"
            @click="adicionaItem(configKey)"
            small
            text
            color="primary"
            class="mt-4"
            ><span>Adicionar</span></v-btn
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/edit/trailingspace.js";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint.js";
export default {
  components: {
    codemirror,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    cmOptions: {
      required: false,
      default: () => {
        return {
          mode: "application/json",
          lineNumbers: true,
          indentUnit: 2,
          tabSize: 2,
          indentWithTabs: false,
          autoCloseBrackets: true,
          matchBrackets: true,
          lint: true,
          gutters: ["CodeMirror-lint-markers"],
          styleActiveLine: true,
          extraKeys: {
            "Ctrl-Q": function (cm) {
              cm.foldCode(cm.getCursor());
            },
            "Ctrl-Space": "autocomplete",
          },
          foldGutter: true,
          gutters: [
            "CodeMirror-linenumbers",
            "CodeMirror-foldgutter",
            "CodeMirror-lint-markers",
          ],
        };
      },
    },
    value: {
      required: true,
    },
    attrs: {
      required: false,
    },
  },
  data() {
    return {
      selectedItem: null,
    };
  },
  methods: {
    adicionaItem(configKey) {
      this.value[configKey].push({
        "": null,
      });
      this.selectedItem = { [configKey]: this.value[configKey].length - 1 };
    },
    removeItem(index, configKey) {
      this.value[configKey].splice(index, 1);
      this.selectedItem = null;
    },
    async setSelectedItem(dashIndex = null, configKey = "") {
      const someError = this.$children.some(
        (children) => children.errorBucket?.length > 0
      );
      if (someError) return;
      if (dashIndex === null) {
        this.selectedItem = null;
        return;
      }
      this.value[configKey]?.forEach((value, index) => {});
      this.selectedItem = { [configKey]: dashIndex };
    },
    setNovaChave(configKey, index, chave, event) {
      const novoObjeto = {
        [event.target._value]: this.value[configKey][index][chave],
      };
      this.value[configKey].slice(index, 1);
      this.$set(this.value[configKey], index, novoObjeto);
    },
  },
  computed: {
    someError() {
      const someError = this.$children.some(
        (children) => children.errorBucket?.length > 0
      );
      return someError;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  border-radius: 4px;
}
</style>
