<template>
  <!-- eslint-disable vue/valid-v-slot -->
  <div>
    <v-dialog v-model="dialog" persistent max-width="80vw">
      <v-card>
        <v-card-title class="text-h5"> Salvar configurações? </v-card-title>
        <v-card-text>
          Verifique todas as alterações antes de salvar
          <v-data-table
            :items="alteracoes"
            :headers="headers"
            hide-default-footer
          >
            <template v-slot:item.antigo="{ item }">
              <vue-json-pretty
                :data="item.antigo"
                v-if="item.json"
                showIcon
                showLineNumber
                showLength
                :deep="3"
              />
              <span v-else-if="item.text == 'CNPJ'">
                {{ item.antigo | VMask("##.###.###/####-##") }}
              </span>
              <span v-else>{{ item.antigo }}</span>
            </template>
            <template v-slot:item.novo="{ item }">
              <vue-json-pretty
                :data="item.novo"
                v-if="item.json"
                showIcon
                showLineNumber
                showLength
                :deep="3"
              />
              <span v-else>{{ item.novo }}</span>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primay darken-1" text @click="decision = false">
            Voltar
          </v-btn>
          <v-btn color="primary" @click="decision = true"> Salvar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
export default {
  components: {
    VueJsonPretty,
  },
  data() {
    return {
      dialog: false,
      decision: null,
      configs: {},
      empresa: {},
      alteracoes: [],
      headers: [
        { text: "Campo", value: "text", width: "33%" },
        { text: "Valor antigo", value: "antigo", width: "33%" },
        { text: "Valor novo", value: "novo", width: "33%" },
      ],
    };
  },
  methods: {
    async openAndWaitForDecision(configs, empresa) {
      this.dialog = true;
      this.configs = configs;
      this.empresa = empresa;
      const alteracoes = [];

      Object.keys(configs).forEach((chaveConfig) => {
        if (configs[chaveConfig]?.attrs?.lang == "json") {
          if (chaveConfig == "dashboard") {
            if (configs[chaveConfig].value.length) {
              configs[chaveConfig].value = configs[chaveConfig].value.map(
                (item) => {
                  if (!item.id) {
                    const newId = item.nome
                      .toLowerCase()
                      .normalize("NFD")
                      .replace(/[\u0300-\u036f]/g, "")
                      .replace(/ /g, "_");

                    item.id = newId;
                  }
                  return item;
                }
              );
            }
          }

          if (
            JSON.stringify(configs[chaveConfig].value) !==
            JSON.stringify(empresa[chaveConfig])
          ) {
            alteracoes.push({
              antigo: empresa[chaveConfig],
              novo: configs[chaveConfig]?.value,
              text: configs[chaveConfig]?.translation,
              json: true,
            });
          }
          return;
        }

        if (chaveConfig == "cnpj") {
          const unmaskedCnpj = configs[chaveConfig]?.value.replace(
            /[^a-zA-Z0-9 ]/g,
            ""
          );
          if (unmaskedCnpj == empresa[chaveConfig]) return;
        }

        if (configs[chaveConfig]?.value != empresa[chaveConfig]) {
          alteracoes.unshift({
            antigo: empresa[chaveConfig],
            novo: configs[chaveConfig]?.value,
            text: configs[chaveConfig]?.translation,
          });
        }
      });
      this.alteracoes = alteracoes;

      return new Promise((resolve) => {
        const interval = setInterval(() => {
          if (this.decision !== null) {
            resolve(this.decision);
            this.dialog = false;
            this.decision = null;
            clearInterval(interval);
          }
        }, 100);
      });
    },
  },
};
</script>

<style></style>
