<template>
  <div>
    <v-dialog v-model="dialog" persistent max-width="370">
      <v-card>
        <v-card-title class="text-h5">
          Deseja Remover esta faq?
        </v-card-title>
        <v-card-text
          ></v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="green darken-1" text @click="decision = false">
            Voltar
          </v-btn>
          <v-btn color="error" text @click="decision = true">
            Remover
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
export default {
    data () {
      return {
        dialog: false,
        decision: null
      }
    },
    methods: {
      async openAndWaitForDecision(){
        this.dialog = true
        return new Promise((resolve) => {
          const interval = setInterval(() => {
            if (this.decision !== null) {
              resolve(this.decision)
              this.dialog = false
              this.decision = null
              clearInterval(interval)
            }
          }, 100)
        })
      }
    }
  }
</script>

<style>
</style>