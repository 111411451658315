<template>
  <v-dialog v-model="dialog" max-width="450" persistent>
    <v-card v-if="opcoesDialog">
      <v-card-title class="text-h5">
        {{ opcoesDialog.titulo }}
      </v-card-title>
      <v-card-text :class="{ 'pb-1': statusFatura == 'PAGA' }">{{
        opcoesDialog.conteudo
      }}</v-card-text>
      <v-card-text
        class="d-flex"
        v-if="
          this.statusFatura == 'PAGA' || this.statusFatura == 'PAGO_PARCIAL'
        "
      >
        <v-checkbox
          v-model="checkboxPagoParcial"
          :label="`Pago parcial`"
        ></v-checkbox>
        <v-text-field
          v-if="checkboxPagoParcial"
          prefix="R$"
          type="number"
          outlined
          :max="Number(fatura.total)"
          v-model="valorParcial"
          label="Valor Parcial"
          required
          class="mr-1 ml-7"
        ></v-text-field>
      </v-card-text>
      <v-divider></v-divider>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          text
          @click="fecharDialog()"
          :disabled="carregandoDescartarFatura"
        >
          cancelar
        </v-btn>
        <v-btn color="primary" @click="alterarStatus()" v-if="!faturaDescartar">
          salvar
        </v-btn>
        <v-btn
          color="primary"
          @click="descartaFatura()"
          v-else
          :loading="carregandoDescartarFatura"
        >
          salvar
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  data() {
    return {
      dialog: false,
      fatura: null,
      opcoesDialog: null,
      statusFatura: null,
      faturaDescartar: false,
      checkboxPagoParcial: false,
      valorParcial: null,
    };
  },

  methods: {
    ...mapActions("fatura", {
      editaFatura: "editaFatura",
      descartarFatura: "descartarFatura",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapMutations("fatura", {
      setFaturaSelecionada: "setFaturaSelecionada",
    }),
    abrirDialog(fatura, opcoesDialog, status, descartar) {
      if (descartar) this.faturaDescartar = true;
      this.setFaturaSelecionada(fatura);
      this.statusFatura = status;
      this.opcoesDialog = opcoesDialog;
      this.fatura = fatura;
      this.dialog = true;
    },
    fecharDialog() {
      this.idFaturaSelecionada = null;
      this.dialog = false;
    },
    async alterarStatus() {
      if (
        (this.statusFatura == "PAGA" || this.statusFatura == "PAGO_PARCIAL") &&
        this.checkboxPagoParcial
      ) {
        if (this.fatura.total < this.valorParcial) {
          this.showSnackBar({
            text: `O valor pago não pode ser maior que valor total da fatura.`,
          });
          return;
        }
        let payload = {
          id: this.fatura.id,
          status: this.statusFatura,
          valor_pago: this.valorParcial,
        };
        await this.editaFatura(payload)
          .then((retorno) => {
            this.showSnackBar({
              text: `Fatura alterada para ${
                this.statusFatura == "PAGA" ? "paga" : "aberta"
              }`,
            });
            this.fecharDialog();
          })
          .catch((error) => {
            console.error(error);
            this.showSnackBar({
              text: `Erro ao alterar para ${
                this.statusFatura == "PAGA" ? "paga" : "aberta"
              }`,
            });
          });
      } else {
        if (this.statusFatura == "PAGO_PARCIAL") this.statusFatura = "PAGA";
        let payload = {
          id: this.fatura.id,
          status: this.statusFatura,
        };
        await this.editaFatura(payload)
          .then((retorno) => {
            this.showSnackBar({
              text: `Fatura alterada para ${
                this.statusFatura == "PAGA" ? "paga" : "aberta"
              }`,
            });
            this.fecharDialog();
          })
          .catch((error) => {
            console.error(error);
            this.showSnackBar({
              text: `Erro ao alterar para ${
                this.statusFatura == "PAGA" ? "paga" : "aberta"
              }`,
            });
          });
      }

      let payload = {
        id: this.fatura.id,
        status: this.statusFatura,
      };
    },
    descartaFatura() {
      let payload = {
        id: this.fatura.id,
      };
      this.descartarFatura(payload)
        .then(() => {
          this.showSnackBar({
            text: `Fatura descartada com sucesso!`,
          });
          this.faturaDescartar = false;
          this.dialog = false;
        })
        .catch((error) => {
          this.showSnackBar({
            text: `Erro ao descartar fatura!`,
          });
          console.error(error);
        });
    },
  },

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("fatura", {
      carregandoAprovarFatura: "carregandoAprovarFatura",
      carregandoDescartarFatura: "carregandoDescartarFatura",
    }),
  },
};
</script>

<style></style>
