<template>
  <div class="d-flex flex-column">
    <div
      class="d-flex mx-auto align-center mb-2"
      style="width: 1000px"
      v-if="!carregando"
      :class="{
        'mt-4': !quantidadeReguas || !quantidadeReguasArquivadas,
        'mb-4': !quantidadeReguasArquivadas,
      }"
    >
      <div class="text-h6 font-weight-bold">
        Listagem de Réguas
        {{
          quantidadeReguas
            ? "(" +
              (routeParams.arquivadas
                ? quantidadeReguas + quantidadeReguasArquivadas
                : quantidadeReguas) +
              ")"
            : ""
        }}
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex" v-if="quantidadeReguasArquivadas">
        <v-switch
          v-model="routeParams.arquivadas"
          :label="`Mostrar arquivadas (${quantidadeReguasArquivadas})`"
        ></v-switch>
      </div>
    </div>

    <div>
      <v-card class="table-wraper mx-auto">
        <v-data-table v-if="!carregando" hide-default-footer>
          <template v-slot:body>
            <div class="regua__wrapper list-wraper">
              <v-expansion-panels
                class="expansion-panels"
                v-model="painelRegua"
                accordion
                multiple
              >
                <v-expansion-panel
                  v-for="regua in reguas"
                  :key="regua.id"
                  :id="regua.id"
                  ref="reguas"
                  class="rounded-0 pa-0 elevation-0 flex-column"
                  :readonly="
                    verificaEventoAtivo(regua.eventos) &&
                    !regua.id_regua_desafiante
                  "
                >
                  <v-expansion-panel-header
                    class="pa-1 d-flex justify-start"
                    v-if="verificaSeReguaTesteAb(regua)"
                    :style="
                      verificaEventoAtivo(regua.eventos) &&
                      !regua.id_regua_desafiante
                        ? 'cursor: unset'
                        : ''
                    "
                  >
                    <!-- caso não tenha eventos, deixa o expansion panel sem o icone -->
                    <template v-slot:actions>
                      <v-icon
                        :class="{
                          'mr-6':
                            verificaEventoAtivo(regua.eventos) &&
                            !regua.id_regua_desafiante,
                        }"
                        color="primary"
                        v-if="
                          verificaEventoAtivo(regua.eventos) &&
                          !regua.id_regua_desafiante
                        "
                      ></v-icon>
                    </template>
                    <div class="d-flex align-center">
                      <v-switch
                        v-model="regua.status"
                        value="PRODUCAO"
                        class="mr-2"
                        v-if="checkCredentials(['admin', 'editor_regua'])"
                        :disabled="regua.status == 'ARQUIVADA'"
                        @click.stop.native.capture="
                          regua.status === 'PRODUCAO'
                            ? pararRegua(regua)
                            : rodarRegua(regua)
                        "
                      ></v-switch>
                      <router-link
                        :to="{
                          name: 'eventos',
                          query: {
                            tipo_movimentacao_id:
                              $route.query.tipo_movimentacao_id,
                          },
                          params: { regua_id: regua.id },
                        }"
                        style="color: rgba(0, 0, 0, 0.87)"
                        class="text-subtitle-1 regua__name text-decoration-none"
                        :class="regua.status"
                      >
                        {{ regua.nome }}
                        <v-icon class="ml-1" v-if="regua.id_regua_desafiante">
                          mdi-alpha-a-circle-outline
                        </v-icon>
                      </router-link>
                      <v-tooltip bottom v-if="regua.id_regua_desafiante">
                        <template v-slot:activator="{ on, attrs }">
                          <v-chip
                            v-bind="attrs"
                            v-on="on"
                            color="orange"
                            small
                            class="ml-2"
                            dark
                          >
                            Teste A/B
                          </v-chip>
                        </template>

                        <span>
                          Amostra início:
                          {{ regua.digito_verificador_inicio }}
                        </span>
                        <br />
                        <span>
                          Amostra fim:
                          {{ regua.digito_verificador_fim }}
                        </span>
                      </v-tooltip>
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on }">
                          <v-icon
                            class="ml-1"
                            v-show="regua.observacao"
                            v-on="on"
                            small
                            >mdi-text-long</v-icon
                          >
                        </template>
                        <span>{{ regua.observacao }}</span>
                      </v-tooltip>

                      <v-chip
                        small
                        outlined
                        link
                        @click.stop="visualizarPerfisRegua(regua)"
                        v-if="
                          regua.perfis?.length > 0 &&
                          regua.status != 'ARQUIVADA'
                        "
                        :color="regua.status != 'PRODUCAO' ? 'grey' : 'purple'"
                        class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2"
                      >
                        <span class="text-subtitle-2 font-weight-black">
                          {{ regua.perfis?.length }}
                          {{
                            regua.perfis?.length > 1
                              ? "perfis vinculados"
                              : "perfil vinculado"
                          }}
                        </span>
                      </v-chip>
                      <v-spacer></v-spacer>
                      <div
                        v-if="regua.eventos_info"
                        class="shrink flex-row align-center ml-2"
                        :class="{
                          'indigo--text text--accent-3':
                            regua.status == 'PRODUCAO',
                        }"
                      >
                        <router-link
                          v-if="regua.eventos_info.quantidade_eventos > 0"
                          :to="{
                            name: 'eventos',
                            query: {
                              tipo_movimentacao_id:
                                $route.query.tipo_movimentacao_id,
                            },
                            params: { regua_id: regua.id },
                          }"
                          class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
                        >
                          <v-chip
                            outlined
                            link
                            small
                            :color="
                              regua.status != 'PRODUCAO'
                                ? 'grey'
                                : 'indigo accent-3'
                            "
                            class="mr-2 d-flex flex-column white--text regua__evento__text"
                          >
                            <span class="text-subtitle-2 font-weight-black">
                              {{ regua.eventos_info.quantidade_eventos }}
                              {{
                                regua.eventos_info.quantidade_eventos > 1
                                  ? "eventos"
                                  : "evento"
                              }}
                              <span
                                v-if="
                                  regua.tipo != 'IMPLANTACAO' &&
                                  regua.eventos_info.janela_eventos > 1
                                "
                                class="text-secondary text-caption font-weight-light events__sub"
                              >
                                em
                                {{ regua.eventos_info.janela_eventos }}
                                {{
                                  regua.eventos_info.janela_eventos > 1
                                    ? "dias"
                                    : "dia"
                                }}
                              </span>
                            </span>
                          </v-chip>
                        </router-link>
                      </div>
                      <div
                        class="align-center regua__wrapper__menu ml-2"
                        v-if="checkCredentials(['admin', 'editor_regua'])"
                      >
                        <v-menu>
                          <template v-slot:activator="{ on }">
                            <v-btn icon v-on="on">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <v-list>
                            <v-list-item @click="editarRegua(regua)">
                              <v-list-item-title
                                >Editar Informações</v-list-item-title
                              >
                            </v-list-item>
                            <v-list-item
                              :to="{
                                name: 'eventos',
                                params: { regua_id: regua.id },
                                query: {
                                  tipo_movimentacao_id:
                                    $route.query.tipo_movimentacao_id,
                                },
                              }"
                            >
                              <v-list-item-title>
                                Editar Eventos
                              </v-list-item-title>
                            </v-list-item>
                            <v-list-item @click="desativarRegua(regua)">
                              <v-list-item-title>
                                {{
                                  regua.status != "ARQUIVADA"
                                    ? "Arquivar"
                                    : "Ativar"
                                }}
                              </v-list-item-title>
                            </v-list-item>
                          </v-list>
                        </v-menu>
                      </div>
                    </div>
                  </v-expansion-panel-header>

                  <v-expansion-panel-content>
                    <v-row class="mt-1 mb-3 px-3" style="max-width: 1000px">
                      <div
                        v-for="evento in listarEventosPorDia(regua.eventos)"
                        :key="evento.id"
                      >
                        <v-card
                          style="min-width: 100px; height: 70px"
                          class="mx-1 rounded-lg mb-2"
                        >
                          <v-card-title
                            :style="{
                              'background-color': retornaCorCard(evento.dia),
                            }"
                            class="px-2 pt-1 pb-0 justify-center subtitle-2 white--text"
                          >
                            {{ retornaDiaEvento(evento, regua) }}
                          </v-card-title>
                          <v-card-text class="text-center pt-2">
                            <v-tooltip
                              bottom
                              v-if="contadorEventos(regua, 'EMAIL', evento.dia)"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-email
                                </v-icon>
                              </template>

                              <span>Notificação E-MAIL</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="contadorEventos(regua, 'SMS', evento.dia)"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-message
                                </v-icon>
                              </template>

                              <span>Notificação SMS</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="
                                contadorEventos(regua, 'WHATSAPP', evento.dia)
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-whatsapp
                                </v-icon>
                              </template>

                              <span>Notificação WhatsApp</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="contadorEventos(regua, 'PUSH', evento.dia)"
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-bell
                                </v-icon>
                              </template>

                              <span>Notificação PUSH</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="
                                contadorEventos(
                                  regua,
                                  'LIGACAO_CELULAR',
                                  evento.dia
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-cellphone
                                </v-icon>
                              </template>

                              <span>Notificação LIGAÇÃO CELULAR</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="
                                contadorEventos(
                                  regua,
                                  'LIGACAO_FIXO',
                                  evento.dia
                                )
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-phone
                                </v-icon>
                              </template>

                              <span>Notificação LIGAÇÃO FIXO</span><br />
                            </v-tooltip>
                            <v-tooltip
                              bottom
                              v-if="
                                contadorEventos(regua, 'COMANDO', evento.dia)
                              "
                            >
                              <template v-slot:activator="{ on, attrs }">
                                <v-icon
                                  small
                                  class="mr-1"
                                  v-bind="attrs"
                                  v-on="on"
                                >
                                  mdi-xml
                                </v-icon>
                              </template>

                              <span>Notificação COMANDO</span><br />
                            </v-tooltip>
                          </v-card-text>
                        </v-card>
                      </div>
                    </v-row>

                    <ReguaExpansionTesteAb
                      v-if="regua.id_regua_desafiante"
                      :regua="regua"
                      @pararRegua="pararRegua"
                      @rodarRegua="rodarRegua"
                      @editarRegua="editarRegua"
                      @desativarRegua="desativarRegua"
                      @visualizarPerfisRegua="visualizarPerfisRegua"
                    />
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <PromiseBasedDialog
      ref="promiseBasedDialog"
      :options="promiseBasedDialogOptions"
    />
    <DialogNovaRegua
      ref="editOuNovaReguaDialog"
      @addOrUpdateRegua="addOrUpdateRegua"
    />
    <DialogVisualizarPerfis ref="dialogVisualizarPerfis" />
  </div>
</template>

<script>
import { checkCredentials } from "@/assets/js/utils";
import { mapGetters, mapActions } from "vuex";
import PromiseBasedDialog from "../../../components/dialogs//promiseBasedDialog.vue";
import DialogNovaRegua from "../components/dialogs/DialogNovaRegua.vue";
import ReguaExpansionTesteAb from "../components/ReguaExpansionTesteAb.vue";
import DialogVisualizarPerfis from "./dialogs/DialogVisualizarPerfis.vue";

export default {
  components: {
    PromiseBasedDialog,
    DialogNovaRegua,
    ReguaExpansionTesteAb,
    DialogVisualizarPerfis,
  },
  props: {
    reguasFiltradas: null,
    carregando: false,
    routeParams: null,
    filtroRegua: null,
    reguaSelecionada: null,
    todasReguas: [],
  },
  data() {
    return {
      promiseBasedDialogOptions: {},
      painelRegua: [],
    };
  },
  mounted() {
    document.addEventListener("keydown", this.interceptSearch);
  },
  beforeDestroy() {
    document.removeEventListener("keydown", this.interceptSearch);
  },
  async created() {
    if (this.reguaSelecionada) {
      let index = this.reguasFiltradas.findIndex(
        (regua) => regua.id == this.reguaSelecionada.id
      );

      if (index != -1) {
        if (this.reguasFiltradas[index].eventos.length >= 1) {
          this.painelRegua = [index];
        }

        await this.fazerRolagem(this.reguasFiltradas[index].id);
      }
    }
  },
  methods: {
    checkCredentials,
    ...mapActions("reguas", {
      getReguas: "getReguas",
      saveRegua: "saveRegua",
      carregaReguaPerfil: "carregaReguaPerfil",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    interceptSearch: (event) => {
      if (event.ctrlKey && event.key == "f") {
        event.preventDefault();
        event.stopPropagation();
        document.getElementById("searchInput").focus();
      }
    },
    verificaEventoAtivo(eventos) {
      if (!eventos) return false;
      let eventoEmProducao = eventos.find((evento) => {
        return evento.status === "PRODUCAO";
      });
      if (eventoEmProducao) {
        return false;
      } else {
        return true;
      }
    },
    verificaSeReguaTesteAb(regua) {
      if (!regua.id_regua_desafiante) {
        return true;
      } else {
        if (regua.id < regua.id_regua_desafiante) {
          return true;
        } else {
          return false;
        }
      }
    },
    listarEventosPorDia(eventos) {
      const eventosFiltrados = eventos.reduce((unicoEvento, evento) => {
        if (evento.status == "PRODUCAO" && evento.master_id == null) {
          if (!unicoEvento.some((obj) => obj.dia === evento.dia)) {
            unicoEvento.push(evento);
          }
        }
        return unicoEvento;
      }, []);
      return eventosFiltrados.sort((a, b) => (a.dia > b.dia ? 1 : -1));
    },
    contadorEventos(regua, tipoEvento, diaEvento) {
      if (!regua.eventos) return 0;

      let eventos = regua.eventos.filter(
        (evento) => evento.status === "PRODUCAO" && evento.master_id == null
      );

      return (
        eventos.filter(
          (item) => item.tipo === tipoEvento && item.dia === diaEvento
        ).length || 0
      );
    },
    retornaCorCard(diaEvento) {
      if (diaEvento < 0) {
        return "#448AFF";
      } else if (diaEvento == 0) {
        return "#FFAB40";
      } else {
        return "#EF5350";
      }
    },
    retornaDiaEvento(evento, regua) {
      if (evento.dia == 0) {
        const tipos = [
          { tipo: "IMPLANTACAO", titulo: "Implantação" },
          { tipo: "VENCIMENTO_BOLETO", titulo: "Vencimento" },
          { tipo: "VENCIMENTO_DEBITO", titulo: "Vencimento" },
          {
            tipo: "TOLERANCIA_BOLETO",
            titulo: "Tolerância",
          },
          {
            tipo: "TOLERANCIA_DEBITO",
            titulo: "Tolerância",
          },
          {
            tipo: "ANIVERSARIO_DO_CLIENTE",
            titulo: "Aniversário",
          },
          { tipo: "BOLETO_SOLICITADO", titulo: "Solicitação" },
          { tipo: "INICIO_CONTRATO", titulo: "Início" },
          {
            tipo: "CONTRATO_FINALIZADO",
            titulo: "Finalização",
          },
          {
            tipo: "CONTRATO_CANCELADO",
            titulo: "Cancelamento",
          },
          {
            tipo: "PAGAMENTO_BOLETO_RECEBIDO",
            titulo: "Pagamento",
          },
          {
            tipo: "PAGAMENTO_DEBITO_RECEBIDO",
            titulo: "Pagamento",
          },
          {
            tipo: "PARCELA_BOLETO_CRIADA",
            titulo: "Criação da Parcela",
          },
          {
            tipo: "PARCELA_DEBITO_CRIADA",
            titulo: "Criação da Parcela",
          },
          { tipo: "TERMINO_CONTRATO", titulo: "Término" },
          { tipo: "MOVIMENTACAO", titulo: "Movimentação" },
        ];
        let tipoSelecionado = tipos.find((tipos) => tipos.tipo == regua.tipo);
        return tipoSelecionado?.titulo;
      } else if (evento.dia > 0) {
        return "+" + evento.dia;
      } else {
        return evento.dia;
      }
    },
    async pararRegua(regua) {
      if (!checkCredentials(["admin", "editor_regua"])) return;
      this.promiseBasedDialogOptions = {
        cardText: "Deseja parar esta régua? Todos os perfis serão removidos!",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;

      let reguaTesteAb = false;
      let payloadReguaTesteAb = {};

      if (regua.id_regua_desafiante) {
        reguaTesteAb = true;
      }
      const payload = {
        id: regua.id,
        status: "RASCUNHO",
      };
      if (reguaTesteAb) {
        payloadReguaTesteAb = {
          id: regua.id_regua_desafiante,
          status: "RASCUNHO",
        };
      }
      try {
        const { data } = await this.saveRegua(payload);
        regua.status = data.status;
        regua.perfis = [];
        this.$emit("atualizarRegua", data);
        if (reguaTesteAb) {
          const { data } = await this.saveRegua(payloadReguaTesteAb);
          this.getReguaById(regua.id_regua_desafiante).status = data.status;
          this.getReguaById(regua.id_regua_desafiante).perfis = [];
          this.$emit("atualizarRegua", data);
        }
        this.showSnackBar({
          text: "Régua parada",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao parar régua",
        });
        console.error(e);
      }
    },
    async rodarRegua(regua) {
      if (!checkCredentials(["admin", "editor_regua"])) return;
      this.promiseBasedDialogOptions = {
        cardText: "Deseja colocar esta régua em produção?",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;
      let reguaTesteAb = false;
      let payloadReguaTesteAb = {};

      if (regua.id_regua_desafiante) {
        reguaTesteAb = true;
      }
      let payload = {
        id: regua.id,
        status: "PRODUCAO",
      };
      if (reguaTesteAb) {
        payloadReguaTesteAb = {
          id: regua.id_regua_desafiante,
          status: "PRODUCAO",
        };
      }
      try {
        const { data } = await this.saveRegua(payload);
        regua.status = data.status;
        this.$emit("atualizarRegua", data);
        if (reguaTesteAb) {
          const { data } = await this.saveRegua(payloadReguaTesteAb);
          this.getReguaById(regua.id_regua_desafiante).status = data.status;
          this.$emit("atualizarRegua", data);
        }
        this.showSnackBar({
          text: "Régua alterada para produção",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Régua alterada para rascunho",
        });
      }
    },
    async desativarRegua(regua) {
      if (regua.id_regua_desafiante) {
        this.promiseBasedDialogOptions = {
          cardText:
            regua.status == "ARQUIVADA"
              ? "Deseja ativar esta régua?"
              : "Deseja arquivar esta régua? Lembrando que é uma régua do tipo Teste A/B, caso seja arquivada, a régua desafiante ficará como principal.",
          cardTitle: null,
          acceptBtn: regua.status != "ARQUIVADA" ? "arquivar" : "ativar",
          declineBtn: "cancelar",
          width: "300px",
        };
        const decision = await this.$refs.promiseBasedDialog.openDialog();
        if (!decision) return;
        let reguaDesafiante = this.getReguaById(regua.id_regua_desafiante);
        let payloadReguaArquivada = {
          id: regua.id,
          status: regua.status != "ARQUIVADA" ? "ARQUIVADA" : "RASCUNHO",
          id_regua_desafiante: null,
          digito_verificador_inicio: null,
          digito_verificador_fim: null,
        };
        let payloadReguaDesafiante = {
          id: reguaDesafiante.id,
          id_regua_desafiante: null,
          digito_verificador_inicio: null,
          digito_verificador_fim: null,
        };
        try {
          const dataReguaArquivada = await this.saveRegua(
            payloadReguaArquivada
          );
          const dataReguaDesafiante = await this.saveRegua(
            payloadReguaDesafiante
          );
          this.showSnackBar({
            text: `Régua ${
              regua.status != "ARQUIVADA" ? "arquivada" : "ativada"
            } com sucesso!`,
          });
          regua.status = dataReguaArquivada.data.status;
          reguaDesafiante.status = dataReguaDesafiante.data.status;
          reguaDesafiante.id_regua_desafiante =
            dataReguaDesafiante.data.id_regua_desafiante;
          reguaDesafiante.digito_verificador_inicio =
            dataReguaDesafiante.data.digito_verificador_inicio;
          reguaDesafiante.digito_verificador_fim =
            dataReguaDesafiante.data.digito_verificador_fim;
        } catch (e) {
          this.showSnackBar({
            text: `Ocorreu um erro ao ${
              regua.status != "ARQUIVADA" ? "arquivar" : "ativar"
            } a regua`,
          });
        } finally {
          this.$emit("filtraReguas");
        }
      } else {
        this.promiseBasedDialogOptions = {
          cardText:
            regua.status == "ARQUIVADA"
              ? "Deseja ativar esta régua?"
              : "Deseja arquivar esta régua?",
          cardTitle: null,
          acceptBtn: regua.status != "ARQUIVADA" ? "arquivar" : "ativar",
          declineBtn: "cancelar",
          width: "300px",
        };
        const decision = await this.$refs.promiseBasedDialog.openDialog();
        if (!decision) return;
        let payload = {
          id: regua.id,
          status: regua.status != "ARQUIVADA" ? "ARQUIVADA" : "RASCUNHO",
        };
        try {
          const { data } = await this.saveRegua(payload);
          this.showSnackBar({
            text: `Régua ${
              regua.status != "ARQUIVADA" ? "arquivada" : "ativada"
            } com sucesso!`,
          });
          regua.status = data.status;
        } catch (e) {
          this.showSnackBar({
            text: `Ocorreu um erro ao ${
              regua.status != "ARQUIVADA" ? "arquivar" : "ativar"
            } a régua`,
          });
        }
        this.$emit("filtraReguas");
      }
    },
    editarRegua(regua) {
      this.$refs.editOuNovaReguaDialog.abrirEditRegua(regua);
    },
    abrirNovaRegua(type, perfil_cliente, perfil_contrato) {
      this.$refs.editOuNovaReguaDialog.abrirNovaRegua(
        type,
        perfil_cliente,
        perfil_contrato
      );
    },
    addOrUpdateRegua(novaRegua) {
      this.$emit("getTodasReguas", this.$route.query.tipo);
    },
    visualizarPerfisRegua(regua) {
      this.$refs.dialogVisualizarPerfis.abrirDialog(regua, "EDITAR");
    },
    async fazerRolagem(id) {
      await setTimeout(() => {
        document.getElementById(id)?.scrollIntoView({
          block: "center",
          inline: "nearest",
          behavior: "smooth",
        });
      }, 250);
    },
  },
  computed: {
    ...mapGetters("reguas", {
      reguas: "reguas",
      getReguaById: "getReguaById",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    quantidadeReguas() {
      return this.reguas.length;
    },
    quantidadeReguasArquivadas() {
      try {
        return this.todasReguas
          ?.filter((regua) => {
            if (!this.filtroRegua) return true;
            return regua.nome
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
              .includes(
                this.filtroRegua
                  .toLowerCase()
                  .normalize("NFD")
                  .replace(/[\u0300-\u036f]/g, "")
              );
          })
          .filter((item) => item.status == "ARQUIVADA").length;
      } catch {
        return 0;
      }
    },
    reguas() {
      return this.reguasFiltradas.filter((regua) => {
        if (!this.filtroRegua) return true;
        return regua.nome
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.filtroRegua
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-wraper {
  max-width: 1000px;
}
a {
  color: hsl(229, 100%, 66%);
}
.ARQUIVADA {
  text-decoration: line-through !important;
  color: #bdbdbd !important;
}
.RASCUNHO {
  .v-application .indigo.accent-3 {
    background-color: #9f9f9f !important;
    border-color: #9f9f9f !important;
  }
  color: #9f9f9f !important;
}

.regua__wrapper {
  a:nth-of-type(1) {
    &:hover {
      color: #4264fe !important;
      text-decoration: underline;
    }
  }
}

.list-wraper {
  display: flex;
  flex-direction: column;
  padding: 0px 10px;
}
.events__sub {
  font-size: 10px !important;
  letter-spacing: 0.1px !important;
}
.expansion-panels {
  z-index: auto;
}
:deep(.v-expansion-panel-content__wrap) {
  padding: 0px 4px 0px !important;
}
</style>
