import http from "@/plugins/axios";
import store from "@/store";

const VERSION = "/v1";

export default {
  getInformacoesFake(tipo) {
    let empresa = store.getters["empresa/empresaSelecionada"];
    return http.api.get(empresa.url_instancia + VERSION + "/fake", {
      params: tipo,
    });
  },
};
