<template>
  <v-data-table v-if="variaveis" :headers="cabecalhos" :items="variaveis" hide-default-footer class="elevation-2"
    :loading="carregando" :sort-by="['ativa', 'atualizado_em']" :sort-desc="[true, true]" disable-pagination :items-per-page="-1">
    <template v-slot:body="{ items }">
      <tbody>
        <tr class="text-center" v-show="carregando">
          <td colspan="12">
            Carregando Variáveis
          </td>
        </tr>
        <tr class="text-center" v-show="variaveis.length === 0 && !carregando">
          <td colspan="12">
            Nenhuma variável encontrada
          </td>
        </tr>
        <tr v-for="(item, index) in items" :key="item?.id ?? index + item?.nome"
          :class="item?.ativa || item?.pre_definida ? '' : 'variavel-inativa'" 
          @mouseover="ativarMenu(item)" @mouseleave="desativarMenu()">
          <td class="text-start nome">
            <div class="d-flex flex-row mt-4">
              <p class="mr-2">
                {{ item.nome }}
                <span v-if="item.tamanho" class="text-caption"><br> Tamanho: {{ item.tamanho }}</span>
              </p>

              <v-tooltip right color="#494949">
                <template v-slot:activator="{ on, attrs }">
                  <span v-bind="attrs" v-on="on">
                    <v-icon small>
                      mdi-text-long
                    </v-icon>
                  </span>
                </template>
                <span>{{ item.descricao }}</span>
              </v-tooltip>

            </div>
          </td>
          <td class="text-start referencia" v-if="verificaExistencia('referencia')">
            <span :class="verificaExistencia('exemplo') ? '' : 'font-weight-black'">{{ item.referencia }}</span>
            <div v-if="!verificaExistencia('exemplo')">
              {{ retornaExemploVariavel(item) }}
            </div>
          </td>
          <td class="text-start exemplo" v-if="verificaExistencia('exemplo')">
            {{ retornaExemploVariavel(item) }}
          </td>
          <td class="text-start atualizado_em" v-if="verificaExistencia('atualizado_em')">
            {{ item.atualizado_em | moment("L") }}
          </td>
          <td class="text-start menuAcoes" v-if="verificaExistencia('actions')">
            <div>
              <v-menu offset-x offset-y v-if="item?.id === existeItemSelecionado" v-model="menuAberto" dense>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn class="action-button" icon v-bind="attrs" v-on="on">
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </template>

                <v-list>
                  <v-list-item>
                    <v-list-item-title>
                      <v-btn plain @click="$emit('abrirDialog', { operacao: 'editar', ...item }); fecharMenu()">
                        Editar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="item?.ativa">
                    <v-list-item-title>
                      <v-btn plain @click="$emit('alterarStatus', item); fecharMenu()">
                        Desativar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-else>
                    <v-list-item-title>
                      <v-btn plain @click="$emit('alterarStatus', item); fecharMenu()">
                        Ativar
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item v-if="!item?.ativa">
                    <v-list-item-title>
                      <v-btn plain @click="$emit('excluir', item.id); fecharMenu()">
                        Excluir
                      </v-btn>
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </td>
        </tr>
      </tbody>
    </template>
  </v-data-table>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    cabecalhos: Array,
    variaveis: Array,
    carregando: Boolean,
  },
  data() {
    return {
      itemSelecionado: false,
      menuAberto: false,
    }
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    existeItemSelecionado() {
      return this.itemSelecionado;
    }
  },
  methods: {
    ativarMenu(item) {
      if (!this.menuAberto) this.itemSelecionado = item.id;
    },
    desativarMenu() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    verificaExistencia(tipoConteudo) {
      return this.cabecalhos.some(itemArray => itemArray.value === tipoConteudo);
    },
    fecharMenu() {
      this.menuAberto = false;
    },
    retornaExemploVariavel(variavel) {
      if(variavel.nome != 'link_area_logada') {
        return variavel.exemplo
      } else {
        if(variavel.exemplo[this.empresaSelecionada.chave]) {
          return variavel.exemplo[this.empresaSelecionada.chave];
        } else {
          return variavel.exemplo["padrao"];
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.v-data-table {
  .variavel-inativa {
    color: lightgrey;
  }

  .menuAcoes {
    min-width: 70px !important;
  }
}
</style>