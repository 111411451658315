import { render, staticRenderFns } from "./TabelaVariaveis.vue?vue&type=template&id=40991b82&scoped=true"
import script from "./TabelaVariaveis.vue?vue&type=script&lang=js"
export * from "./TabelaVariaveis.vue?vue&type=script&lang=js"
import style0 from "./TabelaVariaveis.vue?vue&type=style&index=0&id=40991b82&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40991b82",
  null
  
)

export default component.exports