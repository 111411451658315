<template>
  <div>
    <v-card
      class="table-wraper mx-auto mt-10 mb-2"
      v-if="!erroCarregarResumoContratos && resumoContratos"
    >
      <v-card-title v-if="resumoContratos">
        Contratos Ativos
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              :loading="carregandoResumoContratos"
              @click="$emit('recarregarTabela', true)"
            >
              <v-icon color="black"> mdi-cached </v-icon>
            </v-btn>
          </template>
          <span>Recarregar</span>
        </v-tooltip>
      </v-card-title>
      <v-data-table
        hide-default-footer
        :headers="cabecalhoContratos"
        :items="linhasTabelaFiltrado"
        no-data-text="Nenhum conteúdo para ser mostrado!"
        class="tabela"
        :loading="carregandoResumoContratos"
        :items-per-page="-1"
      >
        <template v-slot:item="{ item }">
          <tr>
            <td
              v-for="(quantidadeContratos, chave, index) in item"
              :key="index"
              :class="{ 'text-center': chave != 'perfil_contrato' }"
              @mouseover="selecionaItem(chave, item)"
              @mouseleave="desmarcaItem()"
            >
              <strong
                v-if="chave == 'total' && item.perfil_contrato != 'total'"
              >
                {{ item.total.toLocaleString("pt-BR") || 0 }}
              </strong>
              <strong
                v-if="item.perfil_contrato == 'total'"
                :class="{ 'text-h5 font-weight-bold': chave == 'total' }"
              >
                {{ verificaCampoTotal(item, chave) }}
              </strong>
              <strong
                v-if="chave == 'perfil_contrato'"
                style="white-space: nowrap"
              >
                {{ item.perfil_contrato | perfilContrato }}
              </strong>

              <div
                v-else
                class="d-flex justify-center align-center"
                :class="{
                  'perfil-sem-regua':
                    filtrarReguasPerfil(item.perfil_contrato, chave).length ==
                      0 &&
                    chave != 'total' &&
                    item.perfil_contrato != 'total',
                }"
                style="position: relative"
              >
                <p
                  class="ma-0 pa-0 mx-auto"
                  v-if="chave != 'total' && item.perfil_contrato != 'total'"
                >
                  {{ quantidadeContratos.toLocaleString("pt-BR") || 0 }}
                </p>
                <v-tooltip
                  v-if="
                    filtrarReguasPerfil(item.perfil_contrato, chave).length >
                      0 &&
                    checkCredentials(['admin', 'editor_regua']) &&
                    chave != 'total' &&
                    item.perfil_contrato != 'total'
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="icone-quantidade-contratos"
                      @click="
                        abrirDialogLinkarRegua(
                          item.perfil_contrato,
                          chave,
                          true
                        )
                      "
                    >
                      <v-icon size="22">mdi-link-variant</v-icon>
                    </v-btn>
                  </template>

                  <span>Vincular régua</span>
                </v-tooltip>
                <v-tooltip
                  v-if="
                    filtrarReguasPerfil(item.perfil_contrato, chave).length ==
                      0 &&
                    checkCredentials(['admin', 'editor_regua']) &&
                    chave != 'total' &&
                    item.perfil_contrato != 'total' &&
                    item === itemSelecionado &&
                    chave === chaveSelecionada
                  "
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                      color="primary"
                      class="icone-quantidade-contratos"
                      @click="
                        abrirDialogLinkarRegua(item.perfil_contrato, chave)
                      "
                    >
                      <v-icon size="22">mdi-link-variant</v-icon>
                    </v-btn>
                  </template>

                  <span>Vincular régua</span>
                </v-tooltip>
              </div>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <DialogLinkarRegua
      ref="dialogLinkarRegua"
      @filtrarReguasPerfil="filtrarReguasPerfil"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import Vue from "vue";
import DialogLinkarRegua from "../components/dialogs/DialogLinkarRegua.vue";

export default {
  components: {
    DialogLinkarRegua,
  },
  props: {
    resumoContratos: null,
    reguasFiltradas: null,
    filtro: null,
  },
  data() {
    return {
      upHere: false,
      cabecalhoTabela: [],
      linhasTabela: [],
      cabecalhoContratos: [],
      itemSelecionado: false,
      chaveSelecionada: false,
    };
  },
  async mounted() {
    await this.criaTabela(this.resumoContratos);
  },
  watch: {
    resumoContratos: {
      handler() {
        this.criaTabela(this.resumoContratos);
      },
    },
  },
  methods: {
    checkCredentials,
    criaTabela(dadosTabela) {
      if (!dadosTabela?.length) return;
      let cabecalho = [];
      if (this.empresaSelecionada.perfis_regua?.perfis_cliente) {
        let perfisRegua =
          this.empresaSelecionada.perfis_regua.perfis_cliente.reduce(function (
            array,
            perfilCliente
          ) {
            if (array.indexOf(Object.keys(perfilCliente)[0]) < 0)
              array.push(Object.keys(perfilCliente)[0]);
            return array;
          },
          []);
        perfisRegua.forEach((perfil) => {
          cabecalho.push({
            text: Vue.filter("perfilCliente")(perfil),
            value: perfil,
            sortable: false,
            align: "center",
          });
        });
      }
      let criaCabecalhoTabela = (data) => {
        let indexCabecalhoExistente = cabecalho.findIndex(
          (cabecalho) =>
            cabecalho.text ==
            Vue.filter("perfilCliente")(data.perfil_cliente ?? "sem_perfil")
        );
        if (indexCabecalhoExistente == -1) {
          cabecalho.push({
            text: Vue.filter("perfilCliente")(
              data.perfil_cliente ?? "sem_perfil"
            ),
            value: data.perfil_cliente ?? "sem_perfil",
            sortable: false,
            align: "center",
          });
        }
      };
      dadosTabela.forEach((data) => {
        criaCabecalhoTabela(data);
      });
      cabecalho.unshift({
        text: "",
        value: "perfil_contrato",
        sortable: false,
      });
      cabecalho.push({
        text: "TOTAL",
        value: "total",
        sortable: false,
        align: "center",
      });

      cabecalho.forEach((cabecalhoSelecionado) => {
        cabecalhoSelecionado["width"] = 25 / cabecalho?.length + "%";
      });

      var perfisCliente = this.verificaPerfisExistentes(
        "perfil_cliente",
        dadosTabela,
        cabecalho
      );

      var perfisContrato = this.verificaPerfisExistentes(
        "perfil_contrato",
        dadosTabela
      );

      let linhasTabela = [];

      if (this.empresaSelecionada.perfis_regua?.perfis_contrato) {
        let perfisRegua =
          this.empresaSelecionada.perfis_regua.perfis_contrato.reduce(function (
            array,
            perfilContrato
          ) {
            if (array.indexOf(Object.keys(perfilContrato)[0]) < 0)
              array.push(Object.keys(perfilContrato)[0]);
            return array;
          },
          []);
        perfisRegua.forEach((perfil) => {
          const chavePerfilContrato = perfil;
          const perfilContrato = {};
          perfisCliente.forEach((perfilCliente) => {
            const chavePerfilCliente = perfilCliente;
            Object.assign(perfilContrato, {
              [chavePerfilCliente]: 0,
            });
          });
          perfilContrato["perfil_contrato"] = chavePerfilContrato;
          perfilContrato["total"] = 0;
          linhasTabela.push(perfilContrato);
        });
      }

      perfisContrato.forEach((perfil) => {
        let indexPerfilExistente = linhasTabela.findIndex((perfilContrato) => {
          return perfilContrato?.perfil_contrato == perfil;
        });
        if (indexPerfilExistente == -1) {
          const chavePerfilContrato = perfil;
          const perfilContrato = {};
          perfisCliente.forEach((perfilCliente) => {
            const chavePerfilCliente = perfilCliente;
            Object.assign(perfilContrato, {
              [chavePerfilCliente]: 0,
            });
          });
          perfilContrato["perfil_contrato"] = chavePerfilContrato;
          perfilContrato["total"] = 0;
          linhasTabela.push(perfilContrato);
        }
      });
      let criaLinhasTabela = (data) => {
        let indexPerfilContratoExistente = linhasTabela.findIndex(
          (perfilContrato) => {
            return (
              perfilContrato.perfil_contrato ==
              (data.perfil_contrato ?? "sem_perfil")
            );
          }
        );
        if (indexPerfilContratoExistente >= 0) {
          Object.assign(linhasTabela[indexPerfilContratoExistente], {
            [data.perfil_cliente ?? "sem_perfil"]: data.total,
          });
          linhasTabela[indexPerfilContratoExistente].total += data.total;
        } else {
          linhasTabela.push({
            perfil_contrato: data.perfil_contrato ?? "sem_perfil",
            [data.perfil_cliente ?? "sem_perfil"]: data.total,
            total: data.total,
          });
        }
      };
      dadosTabela.forEach((data) => {
        criaLinhasTabela(data);
      });
      let criaLinhaTotal = () => {
        linhasTabela.push({ perfil_contrato: "total" });
        linhasTabela.forEach((perfilContrato) => {
          if (perfilContrato.perfil_contrato == "total") return;
          Object.entries(perfilContrato).forEach((entries) => {
            let perfilCliente = entries[0];
            let total = entries[1];
            if (perfilCliente == "perfil_contrato") return;
            if (linhasTabela[linhasTabela.length - 1][perfilCliente]) {
              linhasTabela[linhasTabela.length - 1][perfilCliente] += total;
            } else {
              linhasTabela[linhasTabela.length - 1][perfilCliente] = 0 + total;
            }
          });
        });
      };
      criaLinhaTotal();
      this.linhasTabela = linhasTabela;
      this.cabecalhoContratos = cabecalho;
    },
    verificaPerfisExistentes(tipo, dados, cabecalho) {
      let array = [];
      if (tipo == "perfil_cliente") {
        array = cabecalho.reduce(function (array, b) {
          if (array.indexOf(b.value ?? "sem_perfil") < 0)
            array.push(b.value ?? "sem_perfil");
          return array;
        }, []);
      } else {
        array = dados.reduce(function (array, b) {
          if (array.indexOf(b.perfil_contrato ?? "sem_perfil") < 0)
            array.push(b.perfil_contrato ?? "sem_perfil");
          return array;
        }, []);
      }
      return array;
    },
    filtrarReguasPerfil(perfilContrato, perfilCliente) {
      if (!this.reguasFiltradas) {
        return [];
      }

      if (perfilCliente == "sem_perfil") {
        perfilCliente = null;
      }
      if (perfilContrato == "sem_perfil") {
        perfilContrato = null;
      }

      let reguas = this.reguasFiltradas.flat();

      reguas = reguas.filter((regua) => {
        if (regua.status != "ARQUIVADA") {
          return regua.perfis.some((perfil) => {
            return (
              perfil.perfil_contrato == perfilContrato &&
              perfil.perfil_cliente == perfilCliente
            );
          });
        }
      });
      return reguas;
    },
    abrirDialogLinkarRegua(perfilContrato, perfilCliente, edita) {
      this.$refs.dialogLinkarRegua.abrirDialog(
        this.reguasFiltradas.flat(),
        perfilCliente,
        perfilContrato,
        edita
      );
    },
    verificaCampoTotal(coluna, perfil) {
      for (const valor in coluna) {
        if (valor == perfil) {
          return Vue.filter("numero")(
            coluna[valor] != "total" ? coluna[valor] : ""
          );
        }
      }
    },
    selecionaItem(chave, item) {
      this.chaveSelecionada = chave;
      this.itemSelecionado = item;
    },
    desmarcaItem() {
      this.chaveSelecionada = false;
      this.itemSelecionado = false;
    },
  },
  computed: {
    ...mapGetters("reguas", {
      carregandoResumoContratos: "carregandoResumoContratos",
      erroCarregarResumoContratos: "erroCarregarResumoContratos",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    linhasTabelaFiltrado() {
      return this.linhasTabela.filter((linha) => {
        if (!this.filtro) return true;
        return linha.perfil_contrato
          .toLowerCase()
          .normalize("NFD")
          .replace(/[\u0300-\u036f]/g, "")
          .includes(
            this.filtro
              .toLowerCase()
              .normalize("NFD")
              .replace(/[\u0300-\u036f]/g, "")
          );
      });
    },
  },
};
</script>

<style scoped lang="scss">
.table-wraper {
  max-width: 1000px;
}
.tabela tr > td {
  border-right: 1px solid #dddddd;
}
tbody {
  tr:hover {
    background-color: transparent !important;
  }
  td:hover {
    background-color: #eeeeee !important;
  }
}
.icone-quantidade-contratos {
  position: absolute;
  right: 0;
}
.perfil-sem-regua {
  color: #bdbdbd;
}
</style>
