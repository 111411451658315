<template>
  <div>
    <v-container>
      <v-app-bar app>
        <div class="text-h6 font-weight-bold ml-3">Réguas de movimentação</div>
      </v-app-bar>
      <v-row justify="center">
        <v-col xs="12" lg="8" sm="12">
          <div
            v-if="
              !carregandoTiposMovimentacao &&
              !listaTiposMovimentacao?.objects?.length
            "
            class="text-center py-5 text--secondary"
            height="56"
            width="100%"
          >
            Nenhum tipo de movimentação cadastrada
          </div>
          <v-expansion-panels accordion v-model="reguaAberta">
            <v-expansion-panel
              class="my-2"
              v-for="(tipoRegua, index) in listaTiposMovimentacao?.objects"
              :key="tipoRegua.id"
              :value="tipoRegua.tipo"
              :disabled="tipoRegua.carregando"
              :readonly="
                !tipoRegua.carregando &&
                !filtrarProducao(tipoRegua?.reguas).length
              "
            >
              <v-hover v-slot="{ hover }">
                <v-expansion-panel-header ripple class="py-2">
                  <v-container>
                    <v-row>
                      <div class="d-flex align-center flex-grow-1">
                        <span
                          class="text-subtitle-1 font-weight-bold py-2 mr-4"
                        >
                          {{ tipoRegua.nome }}

                          <v-tooltip bottom color="#494949">
                            <template v-slot:activator="{ on, attrs }">
                              <span v-bind="attrs" v-on="on">
                                <v-icon small> mdi-text-long </v-icon>
                              </span>
                            </template>
                            <span>{{ tipoRegua.descricao }}</span>
                          </v-tooltip>
                        </span>
                        <v-skeleton-loader
                          type="chip"
                          v-if="tipoRegua.carregando"
                        ></v-skeleton-loader>

                        <v-chip
                          v-else-if="filtrarProducao(tipoRegua?.reguas).length"
                          color="#1b5e20"
                          outlined
                          class="font-weight-bold"
                        >
                          {{ escreverReguasAtivas(tipoRegua.reguas) }}
                        </v-chip>
                        <v-spacer></v-spacer>
                        <div v-show="hover || index == reguaAberta">
                          <template>
                            <v-btn
                              text
                              color="indigo accent-3"
                              @click="verificaTipoAtivo(tipoRegua.id)"
                              >Gerenciar</v-btn
                            >
                          </template>
                          <v-menu transition="scale-transition" bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-btn icon v-bind="attrs" v-on="on">
                                <v-icon> mdi-dots-vertical </v-icon>
                              </v-btn>
                            </template>
                            <v-list>
                              <v-list-item
                                @click="abrirDialog('Movimentacao', tipoRegua)"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-pencil</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Editar Movimentação</v-list-item-title
                                >
                              </v-list-item>
                              <v-divider></v-divider>
                              <v-list-item
                                @click="abrirDialog('Excluir', tipoRegua)"
                              >
                                <v-list-item-icon>
                                  <v-icon>mdi-delete</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title
                                  >Excluir Movimentação</v-list-item-title
                                >
                              </v-list-item>
                            </v-list>
                          </v-menu>
                        </div>
                      </div>
                    </v-row>
                  </v-container>
                  <template v-slot:actions>
                    <span
                      v-if="
                        !tipoRegua.carregando &&
                        !filtrarProducao(tipoRegua?.reguas).length
                      "
                    ></span>
                  </template>
                </v-expansion-panel-header>
              </v-hover>
              <v-expansion-panel-content>
                <v-data-table
                  v-if="tipoRegua?.reguas"
                  :items="filtrarProducao(tipoRegua.reguas)"
                  :items-per-page="-1"
                  :headers="cabeçalhoReguas"
                  :loading="false"
                  hide-default-footer
                  hide-default-header
                  no-data-text="Nenhuma régua em produção"
                >
                  <template v-slot:item.nome="{ item }">
                    <router-link
                      :to="{
                        name: 'Reguas',
                        query: {
                          tipo: item.tipo,
                          tipo_movimentacao_id: item.tipo_movimentacao_id,
                        },
                        params: {
                          regua: item,
                        },
                      }"
                      class="text-decoration-none"
                    >
                      <div class="d-flex">
                        <div
                          class="text-body-2 font-weight-medium secondary--text"
                        >
                          {{ item.nome }}
                          <v-icon
                            class="ml-1"
                            v-if="
                              item?.id_regua_desafiante &&
                              item.id < item?.id_regua_desafiante
                            "
                          >
                            mdi-alpha-a-circle-outline
                          </v-icon>
                          <v-icon
                            class="ml-1"
                            v-else-if="item?.id_regua_desafiante"
                          >
                            mdi-alpha-b-circle-outline
                          </v-icon>
                        </div>
                        <v-chip
                          v-if="item.id_regua_desafiante"
                          color="orange"
                          small
                          class="ml-2"
                          dark
                        >
                          Teste A/B
                        </v-chip>
                        <v-chip
                          small
                          outlined
                          v-if="
                            item.perfis?.length > 0 &&
                            item.status != 'ARQUIVADA'
                          "
                          :color="item.status != 'PRODUCAO' ? 'grey' : 'purple'"
                          class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2"
                          @click.stop.prevent="visualizarPerfisRegua(item)"
                        >
                          <span class="text-subtitle-2 font-weight-bold">
                            {{ item.perfis?.length }}
                            {{
                              item.perfis?.length > 1
                                ? "perfis vinculados"
                                : "perfil vinculado"
                            }}
                          </span>
                        </v-chip>
                        <v-spacer> </v-spacer>
                        <router-link
                          :to="{
                            name: 'eventos',
                            query: {
                              tipo_movimentacao_id: item.tipo_movimentacao_id,
                              listagem: 'movimentacao',
                            },
                            params: { regua_id: item.id },
                          }"
                          v-if="contarNumeroDeEventos(item)"
                          class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
                        >
                          <v-chip
                            outlined
                            link
                            small
                            :color="
                              item.status != 'PRODUCAO'
                                ? 'grey'
                                : 'indigo accent-3'
                            "
                            class="mr-2 d-flex flex-column white--text item__evento__text"
                          >
                            <span class="text-subtitle-2 font-weight-black">
                              {{ contarNumeroDeEventos(item) }}
                              {{
                                contarNumeroDeEventos(item) > 1
                                  ? "eventos"
                                  : "evento"
                              }}
                              <span
                                v-if="
                                  item.tipo != 'IMPLANTACAO' &&
                                  contarDiasDeEventos(item) > 1
                                "
                                class="text-secondary text-caption font-weight-medium events__sub"
                              >
                                em
                                {{ contarDiasDeEventos(item) }}
                                {{
                                  contarDiasDeEventos(item) > 1 ? "dias" : "dia"
                                }}
                              </span>
                            </span>
                          </v-chip>
                        </router-link>
                      </div>
                    </router-link>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
      </v-row>
    </v-container>

    <FloatingBtn
      :disabled="carregandoTiposMovimentacao"
      @click="abrirDialog('Movimentacao')"
    />

    <DialogVisualizarPerfis ref="dialogVisualizarPerfis" />
    <DialogTipoMovimentacao
      @atualizarListaMovimentacoes="addOuAtualizaMovimentacoes"
      ref="dialogMovimentacao"
    />
    <DialogExcluirMovimentacao
      @excluiMovimentacao="excluiMovimentacao"
      ref="dialogExcluir"
    />
  </div>
</template>

<script>
import DialogTipoMovimentacao from "../components/dialogs/dialogsMovimentacao/DialogTipoMovimentacao.vue";
import DialogExcluirMovimentacao from "../components/dialogs/dialogsMovimentacao/DialogExcluirMovimentacao.vue";
import DialogVisualizarPerfis from "../components/dialogs/DialogVisualizarPerfis.vue";
import FloatingBtn from "../../../components/comum/FloatingBtn.vue";

import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    DialogTipoMovimentacao,
    DialogExcluirMovimentacao,
    DialogVisualizarPerfis,
    FloatingBtn,
  },
  data: () => ({
    reguaAberta: null,
    cabeçalhoReguas: [{ text: "Nome", value: "nome" }],
  }),
  computed: {
    ...mapGetters("empresa", ["empresaSelecionada"]),
    ...mapGetters("tipoMovimentacao", [
      "listaTiposMovimentacao",
      "carregandoTiposMovimentacao",
    ]),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.carregarMovimentacoes();
      },
    },
  },
  async created() {
    await this.carregarMovimentacoes();
  },
  methods: {
    ...mapActions("tipoMovimentacao", ["listarTiposMovimentacao"]),
    async carregarMovimentacoes() {
      let params = {
        empresa_id: this.empresaSelecionada.id,
        limite: 1000,
      };
      await this.listarTiposMovimentacao(params);
    },
    abrirDialog(acao, movimentacao = null) {
      this.$refs["dialog" + acao].abrirDialog(movimentacao);
    },
    addOuAtualizaMovimentacoes(novaMovimentacao) {
      const index = this.listaTiposMovimentacao.objects.findIndex(
        (movimentacao) => movimentacao.id == novaMovimentacao.id
      );
      if ((index || index === 0) && index != -1) {
        this.$set(this.listaTiposMovimentacao.objects, index, novaMovimentacao);
      } else {
        this.listaTiposMovimentacao.objects.push(novaMovimentacao);
      }
    },
    excluiMovimentacao(movimentacaoId) {
      let index = this.listaTiposMovimentacao.objects.findIndex(
        (movimentacao) => movimentacao.id == movimentacaoId
      );
      this.listaTiposMovimentacao.objects.splice(index, 1);
    },
    verificaTipoAtivo(id) {
      this.$router
        .push({
          name: "Reguas",
          query: { tipo: "MOVIMENTACAO", tipo_movimentacao_id: id },
        })
        .catch(() => {});
    },
    contarNumeroDeEventos(regua) {
      let eventos = (regua.eventos || []).filter(
        (evento) => evento.status == "PRODUCAO"
      );
      return eventos.filter(
        (x) => x.master_id == null && x.status == "PRODUCAO"
      )?.length;
    },
    contarDiasDeEventos(regua) {
      let eventos = (regua.eventos || []).filter(
        (evento) => evento.status == "PRODUCAO"
      );
      let menorDia = 0;
      let maiorDia = 0;
      eventos.forEach((event) => {
        event.dia < menorDia ? (menorDia = event.dia) : null;
        event.dia > maiorDia ? (maiorDia = event.dia) : null;
      });
      let isNotifyingOnDay0 = eventos.some((event) => event.dia == 0);
      let eventDaysWindow = maiorDia + Math.abs(menorDia);
      if (isNotifyingOnDay0) {
        eventDaysWindow += 1;
      }
      return eventDaysWindow;
    },
    escreverReguasAtivas(listaDeReguas) {
      let tamanho = this.filtrarProducao(listaDeReguas).length;
      return tamanho > 1 ? `${tamanho} réguas ativas` : "1 régua ativa";
    },
    visualizarPerfisRegua(regua) {
      this.$refs.dialogVisualizarPerfis.abrirDialog(regua, "VISUALIZAR");
    },
    filtrarProducao(lista) {
      return lista?.filter((item) => item.status == "PRODUCAO") || [];
    },
  },
};
</script>

<style scoped lang="scss">

</style>
