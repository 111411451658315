<template>
  <div>
    <v-col cols="12" class="pb-0 pt-0">
      <div
        class="horarios-header d-flex justify-space-between align-center flex-grow-1"
      >
        <v-card-title
          class="text-black font-weight-bold text-subtitle-1 d-flex align-center justify-space-between pl-0"
        >
          Horários programados
        </v-card-title>

        <v-switch
          :disabled="loadingHorarios || disabled"
          v-model="horariosPadrao"
          label="Valores padrão"
        ></v-switch>
      </div>
    </v-col>

    <v-progress-linear
      v-show="loadingHorarios"
      :active="loadingHorarios"
      indeterminate
      color="deep-purple accent-4"
    ></v-progress-linear>
    <v-row v-show="!loadingHorarios" class="mt-0 pt-1">
      <v-col cols="4" class="pt-0">
        <v-card-text class="pb-0 pt-0">
          <p class="body-2">Horário</p>
          <v-select
            v-model="camposHorario.horario"
            :items="horarios"
            :disabled="horariosPadrao"
            label="Horário"
            outlined
            dense
          ></v-select>
        </v-card-text>
      </v-col>
      <v-col class="pt-0" cols="8">
        <v-card-text class="secao-dias pt-0 pb-0">
          <p class="body-2">Dias</p>
          <div class="seletor-dias">
            <div
              @click="dia.active = !dia.active"
              class="dia"
              v-for="dia in camposHorario.dias"
              :key="dia.id"
              :class="{
                ativo: dia.active,
                feriado: dia.label === 'Feriados',
                disabled: horariosPadrao,
              }"
            >
              {{ dia.label }}
            </div>
          </div>
        </v-card-text>
      </v-col>
    </v-row>
  </div>
</template>
<script>
export default {
  props: {
    event: {
      type: Object,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    loadingHorarios: false,
    horariosPadrao: true,
    horarios: [],
    camposHorario: {
      horario: "09:00",
      dias: [
        { id: "domingo", label: "D", active: false },
        { id: "segunda", label: "S", active: true },
        { id: "terca", label: "T", active: true },
        { id: "quarta", label: "Q", active: true },
        { id: "quinta", label: "Q", active: true },
        { id: "sexta", label: "S", active: true },
        { id: "sabado", label: "S", active: false },
        { id: "feriado", label: "Feriados", active: false },
      ],
    },
  }),

  watch: {
    horariosPadrao(padrao) {
      if (padrao) {
        this.loadingHorarios = true;
        this.camposHorario = {
          horario: "09:00",
          dias: [
            { id: "domingo", label: "D", active: false },
            { id: "segunda", label: "S", active: true },
            { id: "terca", label: "T", active: true },
            { id: "quarta", label: "Q", active: true },
            { id: "quinta", label: "Q", active: true },
            { id: "sexta", label: "S", active: true },
            { id: "sabado", label: "S", active: false },
            { id: "feriado", label: "Feriados", active: false },
          ],
        };
        this.loadingHorarios = false;
      } else return;
    },
  },

  methods: {
    resetaHorarios() {
      this.camposHorario = {
        horario: "09:00",
        dias: [
          { id: "domingo", label: "D", active: false },
          { id: "segunda", label: "S", active: true },
          { id: "terca", label: "T", active: true },
          { id: "quarta", label: "Q", active: true },
          { id: "quinta", label: "Q", active: true },
          { id: "sexta", label: "S", active: true },
          { id: "sabado", label: "S", active: false },
          { id: "feriado", label: "Feriados", active: false },
        ],
      };
    },

    configuraHorarios() {
      this.loadingHorarios = true;
      this.resetaHorarios();

      for (let i = 9; i <= 17; i++) {
        i <= 9 ? this.horarios.push(`0${i}:00`) : this.horarios.push(`${i}:00`);
      }

      if (this.event?.id) {
        this.camposHorario.dias.forEach((dia) => {
          dia.active = this.event[dia.id] || false;
        });
      }

      if (this.event?.horario && this.event?.id) {
        this.camposHorario.horario = this.event.horario.slice(0, -3);
      } else {
        this.camposHorario.horario = "09:00";
      }

      const diasPadrao =
        this.camposHorario.dias[0].active === false &&
        this.camposHorario.dias[1].active === true &&
        this.camposHorario.dias[2].active === true &&
        this.camposHorario.dias[3].active === true &&
        this.camposHorario.dias[4].active === true &&
        this.camposHorario.dias[5].active === true &&
        this.camposHorario.dias[6].active === false &&
        this.camposHorario.dias[7].active === false;
      const horarioPadrao =
        this.camposHorario.horario == "09:00" ||
        this.camposHorario.horario == "09:00:00";

      if (diasPadrao && horarioPadrao) {
        this.horariosPadrao = true;
      } else {
        this.horariosPadrao = false;
      }

      this.loadingHorarios = false;
    },

    configurarPayloadHorarios() {
      const payload = {
        horario: this.camposHorario.horario,
      };
      this.camposHorario.dias.forEach((dia) => {
        Object.assign(payload, { [dia.id]: dia.active });
      });

      return payload;
    },
  },
};
</script>
<style lang="scss" scoped>
.text-black {
  color: black;
}
.seletor-dias {
  display: flex;
  align-items: center;
  gap: 1rem;
  .dia {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e8eaf6;
    padding: 0.5rem;
    cursor: pointer;
    height: 1.7rem;
    width: 1.7rem;
    font-weight: 500;
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      color: unset;
      opacity: 0.7;
      pointer-events: none;
    }
    &.ativo {
      color: #fff;
      background-color: #3f51b5;
    }
    &.feriado {
      width: unset;
      height: 2rem;
      border-radius: 2rem;
    }
  }
}
</style>