var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app-bar',{attrs:{"app":""}},[_c('v-btn',{attrs:{"icon":"","to":_vm.telaAnterior(),"exact":""}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"ml-3 qj-skeleton-text",attrs:{"min-width":"200","type":"list-item"}}):_vm._e(),(_vm.loading)?_c('v-skeleton-loader',{staticClass:"ml-2",attrs:{"tile":"","type":"chip"}}):_vm._e(),_c('div',{staticClass:"header"},[_c('div',{staticClass:"informacoes"},[(!_vm.loading)?_c('v-toolbar-title',{staticClass:"mr-3"},[_vm._v(" "+_vm._s(_vm.regua.nome)+" "),(
            _vm.regua.id_regua_desafiante && _vm.regua.id < _vm.regua.id_regua_desafiante
          )?_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-alpha-a-circle-outline ")]):_vm._e(),(
            _vm.regua.id_regua_desafiante && _vm.regua.id > _vm.regua.id_regua_desafiante
          )?_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-alpha-b-circle-outline ")]):_vm._e()],1):_vm._e()],1),(!_vm.loading)?_c('v-slide-group',[_c('v-slide-item',[(_vm.regua.tipo)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"font-weight-bold darken-2 tipo-regua mx-2",class:_vm.regua.tipo},'v-chip',attrs,false),on),[_c('span',{},[_vm._v(_vm._s(_vm._f("tipoRegua")(_vm.regua.tipo)))])])]}}],null,false,2501907447)},[_c('span',[_vm._v("Tipo da régua")])]):_vm._e()],1),_c('v-slide-item',[(_vm.regua.perfis?.length > 0 && _vm.regua.status != 'ARQUIVADA')?_c('v-chip',{staticClass:"mr-2 white--text regua__evento__text regua__evento",attrs:{"outlined":"","link":"","color":"purple"},on:{"click":function($event){$event.stopPropagation();return _vm.abrirDialogVisualizarPerfis(_vm.regua)}}},[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(_vm.regua.perfis.length)+" "+_vm._s(_vm.regua.perfis.length > 1 ? "perfis vinculados" : "perfil vinculado")+" ")])]):_vm._e()],1),_c('v-slide-item',[(_vm.regua.id_regua_desafiante)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"mr-2",attrs:{"dark":"","color":"orange"}},'v-chip',attrs,false),on),[_vm._v(" Teste A/B ")])]}}],null,false,2844040545)},[_c('span',[_vm._v("Amostra início: "+_vm._s(_vm.regua.digito_verificador_inicio)+" ")]),_c('br'),_c('span',[_vm._v("Amostra fim: "+_vm._s(_vm.regua.digito_verificador_fim)+" ")])]):_vm._e()],1)],1):_vm._e(),(!_vm.loading)?_c('div',{staticClass:"acoes"},[(_vm.regua.id_regua_desafiante && _vm.$vuetify.breakpoint.width > 1392)?_c('v-btn',{staticClass:"mr-3",attrs:{"text":"","color":"primary","to":{
          name: 'eventos',
          params: { regua_id: _vm.regua.id_regua_desafiante },
        }}},[_vm._v(" régua desafiante ")]):_vm._e(),(_vm.$vuetify.breakpoint.width > 1392)?_c('v-btn',{staticClass:"font-weight mr-4",attrs:{"text":"","elevation":"0","color":"primary"},on:{"click":function($event){return _vm.irParaVariaveis()}}},[_vm._v(" Variáveis ")]):_vm._e(),(_vm.regua.status && !_vm.loading)?_c('v-chip',{staticClass:"overline",class:{
          green: _vm.regua.status == 'PRODUCAO',
          grey: _vm.regua.status == 'RASCUNHO',
        },staticStyle:{"min-width":"155px"},attrs:{"dark":"","label":""}},[(
            _vm.regua.status != 'ARQUIVADA' &&
            _vm.checkCredentials(['admin', 'editor_regua'])
          )?_c('v-switch',{staticClass:"mt-0 pt-0 mr-1",attrs:{"flat":"","hide-details":"","input-value":_vm.regua.status == 'PRODUCAO'},nativeOn:{"!change":function($event){$event.stopPropagation();},"!click:prepend":function($event){$event.stopPropagation();},"!click:append":function($event){$event.stopPropagation();},"!click":function($event){$event.stopPropagation();return _vm.changeRulerStatus(_vm.regua)}}}):_vm._e(),_vm._v(" "+_vm._s(_vm._f("eventoStatus")(_vm.regua.status))+" ")],1):_vm._e(),_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,false,3221905750)},[_c('v-list',[(
              _vm.regua.id_regua_desafiante && _vm.$vuetify.breakpoint.width <= 1392
            )?_c('v-list-item',{attrs:{"to":{
              name: 'eventos',
              params: { regua_id: _vm.regua.id_regua_desafiante },
            }}},[_c('v-list-item-title',[_vm._v("Régua desafiante")])],1):_vm._e(),(_vm.$vuetify.breakpoint.width <= 1392)?_c('v-list-item',{on:{"click":function($event){return _vm.irParaVariaveis()}}},[_c('v-list-item-title',[_vm._v("Variáveis")])],1):_vm._e(),_c('v-list-item',[_c('v-list-item-action',[_c('v-switch',{model:{value:(_vm.showDisabled),callback:function ($$v) {_vm.showDisabled=$$v},expression:"showDisabled"}})],1),_c('v-list-item-title',[_vm._v("Mostrar eventos arquivados")])],1)],1)],1)],1):_vm._e()],1),_c('PromiseBasedDialog',{ref:"promiseBasedDialog",attrs:{"options":_vm.promiseBasedDialogOptions}}),_c('DialogVisualizarPerfis',{ref:"dialogVisualizarPerfis"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }