<template>
  <div>
    <v-menu
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      attach
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :rules="[...rules]"
          dense
          v-model="value.primary"
          label="Primária"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:prepend-inner>
            <div
              :style="`background-color: ${value.primary}; width: 1rem; height: 1rem`"
              class="rounded-xl"
            ></div> </template
        ></v-text-field>
      </template>
      <v-color-picker
        dot-size="25"
        :value="value.primary"
        @update:color="(event) => (value.primary = event.hex)"
        swatches-max-height="200"
        mode="hexa"
      ></v-color-picker>
    </v-menu>
    <v-menu
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      attach
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :rules="[...rules]"
          dense
          v-model="value.secondary"
          label="Secundária"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:prepend-inner>
            <div
              :style="`background-color: ${value.secondary}; width: 1rem; height: 1rem`"
              class="rounded-xl"
            ></div> </template
        ></v-text-field>
      </template>
      <v-color-picker
        dot-size="25"
        :value="value.secondary"
        @update:color="(event) => (value.secondary = event.hex)"
        swatches-max-height="200"
        mode="hexa"
      ></v-color-picker>
    </v-menu>
    <v-menu
      :close-on-content-click="false"
      transition="scale-transition"
      offset-y
      min-width="auto"
      attach
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :rules="[...rules]"
          dense
          v-model="value.accent"
          label="Destaque"
          readonly
          v-bind="attrs"
          v-on="on"
        >
          <template v-slot:prepend-inner>
            <div
              :style="`background-color: ${value.accent}; width: 1rem; height: 1rem`"
              class="rounded-xl"
            ></div>
          </template>
        </v-text-field>
      </template>
      <v-color-picker
        dot-size="25"
        :value="value.accent"
        @update:color="(event) => (value.accent = event.hex)"
        swatches-max-height="200"
        mode="hexa"
      ></v-color-picker>
    </v-menu>
  </div>
</template>

<script>
export default {
  model: {
    event: "input",
    prop: "value",
  },
  props: {
    value: Object,
    rules: [],
  },
};
</script>

<style></style>
