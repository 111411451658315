var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-dialog',{attrs:{"width":450,"persistent":""},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-form',{ref:"form",on:{"submit":function($event){$event.preventDefault();return _vm.salvarContato.apply(null, arguments)}}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Criar "+_vm._s(_vm._f("tipoEvento")(_vm.tipoContato))+" "),_c('v-spacer')],1),_c('v-card-text',{staticClass:"pt-6"},[(
            _vm.tipoContato == 'SMS' ||
            _vm.tipoContato == 'LIGACAO_CELULAR' ||
            _vm.tipoContato == 'LIGACAO_FIXO' ||
            _vm.tipoContato == 'WHATSAPP' 
          )?_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:(_vm.tipoContato == 'LIGACAO_FIXO' ? '(##) ####-####' : '(##) #####-####'),expression:"tipoContato == 'LIGACAO_FIXO' ? '(##) ####-####' : '(##) #####-####'"}],attrs:{"label":"Número","type":"tel","autofocus":"","outlined":"","required":"","rules":[_vm.rules.required]},model:{value:(_vm.campoNumero),callback:function ($$v) {_vm.campoNumero=$$v},expression:"campoNumero"}}):_vm._e(),(
            _vm.tipoContato == 'EMAIL'
          )?_c('v-text-field',{attrs:{"label":"E-mail","type":"email","autofocus":"","outlined":"","required":"","rules":[_vm.rules.required]},model:{value:(_vm.campoEmail),callback:function ($$v) {_vm.campoEmail=$$v},expression:"campoEmail"}}):_vm._e()],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":"","disabled":_vm.salvando},on:{"click":function($event){return _vm.fecharDialog()}}},[_vm._v(" cancelar ")]),_c('v-btn',{attrs:{"color":"primary","type":"submit","loading":_vm.salvando}},[_vm._v(" salvar ")])],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }