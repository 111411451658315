<template>
  <v-dialog v-model="dialog" :width="500" persistent>
    <v-card>
      <v-card-title class="text-h5 title-card text-capitalize"> {{ operacao }} Nota Fiscal </v-card-title>
      <v-card-text class="pt-3">
        <v-form ref="form">
          <v-text-field outlined label="Nota Fiscal" autofocus v-model="notaFiscal" required
            :rules="[rules.required, rules.limitado]" @keydown.enter.prevent="salvar()"
            :disabled="operacao == 'remover'">
          </v-text-field>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="fechar()"> FECHAR </v-btn>
        <v-btn v-if="operacao !== 'remover'" color="primary" @click="salvar()" :disabled="notaFiscal === ''">
          SALVAR
        </v-btn>
        <v-btn v-else color="primary" @click="remover()">
          REMOVER
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>

export default {
  data() {
    return {
      dialog: false,
      faturaId: null,
      operacao: '',
      notaFiscal: '',
      rules: {
        required: value => !!value || "Campo obrigatório",
        limitado: value => value?.length <= 256 || 'Este campo suporta apenas 256 caracteres',
      },
    };
  },
  methods: {
    abrir(id, nota = null, operacao) {
      this.faturaId = id;
      this.notaFiscal = nota;
      this.operacao = operacao;
      this.dialog = true;
      return;
    },
    fechar() {
      this.dialog = false;
      this.$refs.form.reset();
      return;
    },
    async salvar() {
      let valid = await this.$refs.form.validate();
      if (valid) {
        this.$emit("alterarNotaFiscal", { id: this.faturaId, nota_fiscal: this.notaFiscal });
        this.fechar();
      }
      return;
    },
    async remover() {
      this.$emit("alterarNotaFiscal", { id: this.faturaId, nota_fiscal: null });
      this.fechar();
      return;
    }
  }
}
</script>

<style lang="scss" scoped></style>