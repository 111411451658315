<template>
  <div class="d-flex justify-center pt-4">
    <v-app-bar app>
      <v-btn icon :to="{ name: 'Fatura' }" exact
        ><v-icon>mdi-arrow-left</v-icon></v-btn
      >
      <v-toolbar-title>Editar Fatura</v-toolbar-title>
      <v-spacer></v-spacer>
    </v-app-bar>
    <v-alert v-if="faturaNaoEditavel" type="error" text class="text-center">
      <div class="mb-2">
        Fatura não pode ser editada, pois seu status é diferente de ABERTA
      </div>
    </v-alert>

    <v-card
      color="transparent"
      class="pl-4 pr-4 elevation-0"
      v-if="fatura && !faturaNaoEditavel"
    >
      <div v-if="itensFatura">
        <div class="fatura-header mt-4">
          <div class="d-flex justify-space-between">
            <div>
              <v-toolbar-title class="font-weight-bold text-h4"
                >Fatura Aberta</v-toolbar-title
              >
              <v-toolbar-title
                class="mes-fatura"
                style="font-size: 14px; color: #757575"
              >
                {{ retornaPeriodoFatura(fatura) }}
              </v-toolbar-title>
            </div>
            <v-btn
              depressed
              color="primary"
              class="mt-2"
              @click="aprovarFatura(fatura)"
            >
              Aprovar fatura
            </v-btn>
          </div>
        </div>

        <v-divider class="mt-5"></v-divider>

        <div
          class="servico-header d-flex align-center justify-space-between mt-10"
        >
          <v-toolbar-title
            style="font-size: 25px !important"
            class="font-weight-black text-h4"
          >
            Serviços
          </v-toolbar-title>
          <div>
            <v-btn color="primary" text @click="abrirDialogServico">
              Adicionar serviço
            </v-btn>
            <v-btn color="primary" text @click="abrirDialogDesconto">
              Adicionar desconto
            </v-btn>
          </div>
        </div>

        <v-progress-linear
          :active="carregandoItensFatura"
          :indeterminate="carregandoItensFatura"
          color="deep-purple accent-4"
        ></v-progress-linear>

        <v-data-table
          v-if="itensFranquia"
          no-data-text="Nenhuma franquia encontrada..."
          :headers="cabecalhoServicos"
          :items="itensFranquia"
          class="elevation-2 mt-4 elevation-0"
          style="width: 1100px; background-color: transparent"
          :loading="carregandoItensFatura"
          loading-text="Carregando..."
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat color="transparent">
              <div class="text-left" style="width: 100% !important">
                <v-toolbar-title
                  style="font-size: 18px !important"
                  class="font-weight-bold text-h4 pt-4"
                >
                  Franquias
                </v-toolbar-title>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.descricao`]="{ item }">
            {{ getNomePorDescricao(item.descricao) || "-" }}
          </template>
          <template v-slot:[`item.valor_unitario`]="{ item }">
            {{ item.valor_unitario | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.quantidade`]="{ item }">
            {{ item.quantidade | numero }}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="abrirDialogServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="abrirDialogDeletarServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apagar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template slot="body.append">
            <tr class="total-fatura" style="pointer-events: none !important">
              <th class="text-left subtitle-2">Subtotal</th>
              <th></th>
              <th class="text-left subtitle-2">
                {{ totalFranquia | numero }}
              </th>
              <th class="text-left subtitle-2">
                {{ totalValorFranquia | formatadorRealDecimais }}
              </th>
              <th></th>
            </tr>
          </template>
        </v-data-table>

        <v-data-table
          v-if="itensEvento"
          no-data-text="Nenhum evento encontrado..."
          :headers="cabecalhoServicos"
          :items="itensEvento"
          class="tabela-evento elevation-2 elevation-0"
          style="width: 1100px; background-color: transparent"
          :loading="carregandoItensFatura"
          loading-text="Carregando..."
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat color="transparent">
              <div class="text-left" style="width: 100% !important">
                <v-toolbar-title
                  style="font-size: 18px !important"
                  class="font-weight-bold text-h4 pt-4"
                >
                  Eventos
                </v-toolbar-title>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.descricao`]="{ item }">
            {{ getNomePorDescricao(item.descricao) || "-" }}
          </template>
          <template v-slot:[`item.valor_unitario`]="{ item }">
            {{ item.valor_unitario | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.quantidade`]="{ item }">
            {{ item.quantidade | numero }}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="abrirDialogServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="abrirDialogDeletarServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apagar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template slot="body.append">
            <tr class="total-evento" style="pointer-events: none !important">
              <th class="text-left subtitle-2">Subtotal</th>
              <th></th>
              <th class="text-left subtitle-2">
                {{ totalEvento | numero }}
              </th>
              <th class="text-left subtitle-2">
                {{ totalValorEvento | formatadorRealDecimais }}
              </th>
              <th></th>
            </tr>
          </template>
        </v-data-table>

        <v-data-table
          v-if="itensOutros && itensOutros.length > 0"
          no-data-text="Nenhum evento encontrado..."
          :headers="cabecalhoServicos"
          :items="itensOutros"
          class="tabela-evento elevation-2 elevation-0"
          style="width: 1100px; background-color: transparent"
          :loading="carregandoItensFatura"
          loading-text="Carregando..."
          hide-default-footer
          disable-pagination
        >
          <template v-slot:top>
            <v-toolbar flat color="transparent">
              <div class="text-left" style="width: 100% !important">
                <v-toolbar-title
                  style="font-size: 18px !important"
                  class="font-weight-bold text-h4 pt-4"
                >
                  Outros
                </v-toolbar-title>
              </div>
            </v-toolbar>
          </template>
          <template v-slot:[`item.descricao`]="{ item }">
            {{ getNomePorDescricao(item.descricao) || "-" }}
          </template>
          <template v-slot:[`item.valor_unitario`]="{ item }">
            {{ item.valor_unitario | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.quantidade`]="{ item }">
            {{ item.quantidade | numero }}
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total | formatadorRealDecimaisNCasas }}
          </template>
          <template v-slot:[`item.menu`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="abrirDialogServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="abrirDialogDeletarServico(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apagar serviço</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
          <template slot="body.append">
            <tr class="total-evento" style="pointer-events: none !important">
              <th class="text-left subtitle-2">Subtotal</th>
              <th></th>
              <th class="text-left subtitle-2">
                {{ totalOutros }}
              </th>
              <th class="text-left subtitle-2">
                {{ totalValorOutros | formatadorRealDecimais }}
              </th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </div>

      <section v-if="itensFatura && itensDesconto.length > 0">
        <div
          class="servico-header d-flex align-center justify-space-between mt-10"
        >
          <v-toolbar-title
            style="font-size: 25px !important"
            class="font-weight-black text-h4"
          >
            Descontos
          </v-toolbar-title>
        </div>

        <v-data-table
          :headers="cabecalhoDesconto"
          :items="itensDesconto"
          class="tabela-desconto mt-4 elevation-2 elevation-0"
          style="width: 700px; background-color: transparent"
          no-data-text="Nenhum desconto encontrado..."
          hide-default-footer
          disable-pagination
        >
          <template v-slot:[`item.descricao`]="{ item }">
            {{ getNomePorDescricao(item.descricao) || "-" }}
          </template>

          <template v-slot:[`item.total`]="{ item }">
            -{{ item.total | formatadorRealDecimaisNCasas }}
          </template>

          <template v-slot:[`item.menu`]="{ item }">
            <v-menu :close-on-content-click="false">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon v-bind="attrs" v-on="on">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="abrirDialogDesconto(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Editar desconto</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-divider></v-divider>
                <v-list-item @click="abrirDialogApagarDesconto(item)">
                  <v-list-item-icon>
                    <v-icon>mdi-delete</v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>Apagar desconto</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>

          <template slot="body.append">
            <tr class="total-desconto" style="pointer-events: none !important">
              <th class="text-left subtitle-2">Subtotal</th>
              <th class="text-right subtitle-2">
                -{{ totalValorDesconto | formatadorRealDecimaisNCasas }}
              </th>
              <th></th>
            </tr>
          </template>
        </v-data-table>
      </section>

      <v-divider class="mt-5 mb-5"></v-divider>

      <div
        v-if="!carregandoItensFatura"
        class="d-flex justify-end pr-8 pt-1 mb-2"
        style="width: 100% !important"
      >
        <p class="text-h4 font-weight-bold mr-6">TOTAL</p>
        <p class="text-h4 font-weight-medium" style="color: #757575">
          {{ fatura.total | formatadorRealDecimaisNCasas }}
        </p>
      </div>
    </v-card>

    <DialogServico
      ref="dialogServico"
      @carregarFatura="carregarFatura()"
      @salvar="handleSalvarServico"
    />
    <DialogDesconto
      ref="dialogDesconto"
      @carregarFatura="carregarFatura()"
      @salvar="handleSalvarDesconto"
    />
    <DialogAprovarFatura ref="dialogAprovarFatura" />
  </div>
</template>

<script>
import faturaService from "@/services/fatura";
import { mapGetters, mapActions } from "vuex";
import DialogServico from "../fatura/componentes/DialogServico.vue";
import DialogDesconto from "../fatura/componentes/DialogDesconto.vue";
import DialogAprovarFatura from "../fatura/componentes/DialogAprovarFatura.vue";
import moment from "moment";

export default {
  created() {},
  components: {
    DialogServico,
    DialogDesconto,
    DialogAprovarFatura,
  },
  created() {
    this.carregarFatura();
  },
  data() {
    return {
      carregandoFatura: false,
      carregandoItensFatura: false,
      erroCarregarFatura: false,
      erroCarregarItensFatura: false,
      fatura: null,
      faturaNaoEditavel: false,
      cabecalhoServicos: [
        {
          text: "Descrição",
          value: "descricao",
          align: "start",
          sortable: false,
          width: 300,
        },
        {
          text: "Valor unitário",
          value: "valor_unitario",
          align: "left",
          sortable: false,
          width: 200,
        },
        {
          text: "Quantidade",
          value: "quantidade",
          align: "left",
          sortable: false,
          width: 200,
        },
        {
          text: "Valor total",
          value: "total",
          align: "left",
          sortable: false,
          width: 200,
        },
        {
          text: "",
          value: "menu",
          align: "right",
          sortable: false,
          width: 115,
        },
      ],
      cabecalhoDesconto: [
        {
          text: "Descrição",
          value: "descricao",
          align: "start",
          sortable: false,
          width: 285,
        },
        {
          text: "Valor total",
          value: "total",
          align: "right",
          sortable: false,
        },
        {
          text: "",
          value: "menu",
          align: "right",
          sortable: false,
        },
      ],
    };
  },
  methods: {
    ...mapActions("fatura", {
      getItensFatura: "getItensFatura",
      deletaItemFatura: "deletaItemFatura",
    }),
    getNomePorDescricao(descricao) {
      const precificacao = this.precificacoes.find(
        (p) => p.chave === descricao
      );
      return precificacao ? precificacao.nome : descricao;
    },
    async handleSalvarDesconto() {
      await this.carregarFatura();
      this.$vuetify.goTo(".total-desconto");
    },
    async handleSalvarServico(referencia) {
      await this.carregarFatura();
      if (referencia === "EVENTO") this.$vuetify.goTo(".total-evento");
      else if (referencia === "FRANQUIA") this.$vuetify.goTo(".total-fatura");
      else return;
    },
    async carregarFatura() {
      console.debug("Carregando fatura");
      this.erroCarregarFatura = false;
      try {
        let resposta = await faturaService.buscar(this.$route.params.id);
        if (resposta.data.status == "ABERTA") {
          this.faturaNaoEditavel = false;
          this.fatura = resposta.data;
          this.carregaItensFatura();
          console.debug("Fatura carregada");
        } else {
          this.faturaNaoEditavel = true;
        }
      } catch (erro) {
        console.error("Não foi possível carregar a fatura", erro);
        this.erroCarregarFatura = true;
      }
    },

    async carregaItensFatura() {
      this.carregandoItensFatura = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        fatura_id: this.fatura.id,
        limite: 1000,
      };
      await this.getItensFatura(payload)
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.carregandoItensFatura = false;
        });
    },
    abrirDialogServico(servico) {
      this.$refs.dialogServico.abrirDialog(servico);
    },
    abrirDialogDeletarServico(servico) {
      this.$refs.dialogServico.abrirDialogDeletarServico(servico);
    },
    abrirDialogDesconto(desconto) {
      this.$refs.dialogDesconto.abrirDialog(desconto);
    },
    abrirDialogApagarDesconto(desconto) {
      this.$refs.dialogDesconto.abrirDialogApagarDesconto(desconto);
    },
    aprovarFatura(fatura) {
      this.$refs.dialogAprovarFatura.abrirDialog(fatura);
    },

    excluiItemFatura(item) {
      this.deletaItemFatura(item);
    },
    retornaPeriodoFatura(fatura) {
      return (
        moment(fatura.periodo_inicio).format("L") +
        " até " +
        moment(fatura.periodo_fim).format("L")
      );
    },
  },
  computed: {
    ...mapGetters("fatura", ["itensFatura", "precificacoes"]),
    ...mapGetters("empresa", ["empresaSelecionada"]),

    itensOutros() {
      return (
        this.itensFatura?.filter(({ referencia }) => referencia == "OUTROS") ||
        []
      );
    },
    totalOutros() {
      return this.itensOutros.reduce(
        (acc, curr) => (acc += curr.quantidade || 0),
        0
      );
    },
    totalValorOutros() {
      return this.itensOutros.reduce(
        (acc, curr) => (acc += parseFloat(curr.total || 0)),
        0
      );
    },

    itensEvento() {
      return (
        this.itensFatura
          ?.filter(({ referencia }) => referencia == "EVENTO")
          .sort((a, b) => {
            const DescricaoA = a.descricao ? a.descricao.toLowerCase() : "";
            const DescricaoB = b.descricao ? b.descricao.toLowerCase() : "";

            if (!a.descricao && b.descricao) {
              return -1;
            }
            if (!b.descricao && a.descricao) {
              return 1;
            }

            return DescricaoA.localeCompare(DescricaoB);
          }) || []
      );
    },
    totalEvento() {
      return this.itensEvento.reduce(
        (acc, curr) => (acc += curr.quantidade || 0),
        0
      );
    },
    totalValorEvento() {
      return this.itensEvento.reduce(
        (acc, curr) => (acc += parseFloat(curr.total || 0)),
        0
      );
    },

    itensFranquia() {
      return (
        this.itensFatura?.filter(
          ({ referencia }) => referencia == "FRANQUIA"
        ) || []
      );
    },
    totalFranquia() {
      return this.itensFranquia.reduce(
        (acc, curr) => (acc += curr.quantidade || 0),
        0
      );
    },
    totalValorFranquia() {
      return this.itensFranquia.reduce(
        (acc, curr) => (acc += parseFloat(curr.total || 0)),
        0
      );
    },

    itensDesconto() {
      return (
        this.itensFatura?.filter(
          ({ referencia, valor_unitario }) =>
            referencia == "DESCONTO" && parseFloat(valor_unitario) > 0
        ) || []
      );
    },
    totalValorDesconto() {
      return this.itensDesconto.reduce(
        (acc, curr) => (acc += parseFloat(curr.total)),
        0
      );
    },
  },

  watch: {
    empresaSelecionada: {
      handler() {
        this.$router.push({ name: "Fatura" }).catch(() => {});
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.mes-fatura::first-letter {
  text-transform: capitalize;
}
</style>
