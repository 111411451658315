<template>
  <div>
    <div class="fileInput" :class="{ 'error-input': error }">
      <div
        class="loading-file w-full h-full flex justify-center items-center mb-4"
        v-if="loadingImage"
      >
        <div class="flex flex-col justify-center items-center">
          <v-progress-circular
            color="primary"
            indeterminate
          ></v-progress-circular>
          <span class="text-xs mt-1 text-gray-500">Carregando imagem...</span>
        </div>
      </div>
      <div class="overlay-container" v-else>
        <div
          class="w-full h-full absolute flex justify-center items-center"
          v-if="value"
        >
          <img class="logo" :src="value" />
        </div>
        <div
          :class="!value ? 'visible-overlay' : ''"
          class="overlay flex items-center justify-between w-full h-full absolute"
          @click="openUploadImage"
        >
          <input
            @change="uploadImage"
            type="file"
            name=""
            id=""
            class="hidden"
            ref="inputField"
            accept=".webp, .png, .jpeg, .jpg"
          />
          <div class="grow flex items-center justify-center">
            <v-icon :color="error ? 'red' : ''">mdi-file-upload-outline</v-icon>
            <span
              :class="{ 'text-red-500': error }"
              class="text-lg text-gray-500 font-bold"
              >Carregar imagem</span
            >
          </div>
        </div>
      </div>
    </div>
    <v-input
      class="ml-2"
      v-model="value"
      lazy-validation
      ref="input"
      :rules="[required]"
    ></v-input>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import rules from "@/assets/js/rules";
export default {
  model: {
    event: "input",
    prop: "value",
  },
  data() {
    return {
      loadingImage: false,
      error: false,
    };
  },
  props: {
    value: String,
  },
  methods: {
    ...mapActions("iniciativas", { upload: "uploadArquivo" }),
    openUploadImage() {
      this.$refs.inputField.click();
    },
    required(v = true) {
      if (!!v) {
        return true;
      }
      return "Campo obrigatorio";
    },
    async uploadImage(event) {
      this.loadingImage = true;
      try {
        const file = event.target.files[0];
        const uploadedFile = await this.upload(file);
        this.$emit("input", uploadedFile);
      } catch {
        this.showSnackBar({
          text: "Falha ao carregar a imagem",
          type: "error",
        });
      } finally {
        this.loadingImage = false;
      }
    },
  },
};
</script>

<style lang="scss">
.logo {
  width: 100%;
  object-fit: contain;
  margin: 0px !important;
}
.error-input {
  .overlay,
  .overlay-container {
    outline-color: red !important;
    color: red !important;
  }
}
.overlay {
  display: flex;
  height: 10rem;
  opacity: 0;
  transition: opacity 0.2s ease, visibility 0.2s linear;
  outline: 2px dashed rgb(136, 136, 136);
  border-radius: 4px;
}
.overlay-container {
  width: 100%;
  height: 100%;
  outline: 2px dashed rgb(136, 136, 136);
  border-radius: 4px;
  position: relative;
  img {
    width: 80%;
    height: 80%;
  }
}
.fileInput {
  border-radius: 4px;
  .visible-overlay {
    opacity: 1;
    visibility: visible;
  }
  height: 10rem;
  width: 100%;
  &:hover {
    .overlay-container {
      width: 100%;
      height: 100%;
      position: relative;
    }
    .overlay {
      opacity: 1;
      visibility: visible;
      width: 100%;
      background: rgba(255, 255, 255, 0.95);
      cursor: pointer;
      // transition: opacity 0.5s ease-in-out, visibility 0s linear 0.5s !important;
    }
  }
}
</style>
