<template>
  <v-dialog width="1100" persistent scrollable v-model="dialog">
    <v-card
      class="elevation-0 rounded-0 d-flex flex-column"
      style="min-height: 80vh"
    >
      <!-- Cabeçalho -->
      <v-card-title class="text-h5">
        <span class="mr-2">{{ event.tipo | tipoEvento }} </span>
        <v-icon
          v-if="reguasComEventoMultiplo.includes(regua.tipo)"
          size="x-large"
          class="mr-2"
        >
          {{ eventoMultiplo ? "mdi-vector-union" : "mdi-vector-arrange-below" }}
        </v-icon>
      </v-card-title>
      <!-- Fomulário -->
      <v-card-text class="pt-0">
        <v-form ref="form" v-model="isFormValid" lazy-validation>
          <v-row>
            <v-col class="pt-6">
              <v-row>
                <!-- Dia do evento -->
                <v-col cols="12" sm="4" class="py-0" v-if="typeDay != 1">
                  <v-text-field
                    dense
                    outlined
                    min="1"
                    suffix="dia(s)"
                    type="number"
                    color="deep-purple accent-2"
                    v-model="days"
                    :rules="[
                      () => !!days || 'Campo obrigatório',
                      () => days > 0 || 'Mínimo 1 dia',
                    ]"
                  ></v-text-field>
                </v-col>

                <!-- Tipo do dia -->
                <v-col cols="12" :sm="typeDay == 1 ? '12' : '8'" class="py-0">
                  <v-select
                    dense
                    outlined
                    item-text="text"
                    item-value="index"
                    color="deep-purple accent-2"
                    :items="typesDays"
                    v-model="typeDay"
                  ></v-select>
                </v-col>

                <!-- Nome do evento -->
                <v-col cols="12" class="py-0">
                  <v-text-field
                    dense
                    outlined
                    label="Nome do evento"
                    color="deep-purple accent-2"
                    autofocus
                    v-model="name"
                    required
                    :rules="[() => !!name || 'Campo obrigatório']"
                  ></v-text-field>
                </v-col>

                <!-- Iniciativa -->
                <v-col cols="12" class="py-0">
                  <v-autocomplete
                    clearable
                    ref="iniciativaSelecionada"
                    class="select-iniciativa"
                    color="deep-purple accent-2"
                    dense
                    outlined
                    label="Iniciativas"
                    item-text="nome"
                    item-value="id"
                    return-object
                    no-data-text="Nenhuma iniciativa cadastrada"
                    v-model="iniciativaSelecionada"
                    :items="itemsIniciativa"
                    :search-input.sync="buscaIniciativa"
                    :rules="regras.exigidoObjId"
                    :loading="loadingIniciativa"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Observação -->
                <v-col cols="12" class="py-0">
                  <v-textarea
                    dense
                    outlined
                    hide-details
                    label="Observação"
                    color="deep-purple accent-2"
                    v-model="obs"
                    rows="2"
                  ></v-textarea>
                </v-col>

                <!-- Callback -->
                <v-card-title
                  v-if="mostrarCampoCallback"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  Chamada callback
                  <v-btn icon small @click="desativaCallback">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-col cols="12" class="mt-3 pb-0" v-if="mostrarCampoCallback">
                  <v-text-field
                    dense
                    outlined
                    persistent-hint
                    label="URL para callback"
                    hint="Será realizado um POST contendo a evidência da notificação em JSON"
                    ref="url_callback"
                    color="deep-purple accent-2"
                    v-model="url_callback"
                    rows="1"
                    style="margin-top: -25px"
                  ></v-text-field>
                </v-col>

                <!-- NPS -->
                <v-card-title
                  v-if="mostrarCampoNps && !mostrarCampoPesquisa"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  NPS
                  <v-btn icon small @click="desativaNps">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="mostrarCampoNps && !mostrarCampoPesquisa"
                >
                  <v-select
                    dense
                    outlined
                    label="Selecione uma NPS"
                    color="deep-purple accent-2"
                    :items="listaNps"
                    v-model="npsSelecionada"
                    class="select-nps"
                    no-data-text="Nenhuma NPS cadastrada"
                    hint="Atenção: Uma NPS já respondida não gera uma nova notificação"
                    persistent-hint
                  ></v-select>
                </v-col>

                <!-- Pesquisa -->
                <v-card-title
                  v-if="!mostrarCampoNps && mostrarCampoPesquisa"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  Pesquisa
                  <v-btn icon small @click="desativaPesquisa">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>
                <v-col
                  cols="12"
                  class="py-0"
                  v-if="!mostrarCampoNps && mostrarCampoPesquisa"
                >
                  <v-select
                    dense
                    outlined
                    label="Selecione uma pesquisa"
                    color="deep-purple accent-2"
                    :items="listaPesquisas"
                    item-text="titulo"
                    item-value="id"
                    v-model="pesquisaSelecionada.id"
                    class="select-pesquisa"
                    no-data-text="Nenhuma pesquisa cadastrada"
                    hint="Atenção: Uma pesquisa já respondida não gera uma nova notificação"
                    persistent-hint
                  ></v-select>
                </v-col>

                <!-- Bloco de multiplos vencimentos -->
                <v-card-title
                  v-if="eventoMultiplo"
                  class="text-black text-subtitle-1 font-weight-bold d-flex align-center justify-space-between flex-grow-1"
                >
                  {{ definirNomePorTipoRegua }}
                  <v-btn icon small @click="toggleEventoMultiplo">
                    <v-icon> mdi-close </v-icon>
                  </v-btn>
                </v-card-title>

                <v-col cols="12" class="py-0" v-if="eventoMultiplo">
                  <v-autocomplete
                    clearable
                    ref="iniciativaMultiplaSelecionada"
                    class="select-iniciativa"
                    color="deep-purple accent-2"
                    dense
                    outlined
                    label="Iniciativas"
                    item-text="nome"
                    item-value="id"
                    return-object
                    no-data-text="Nenhuma iniciativa cadastrada"
                    v-model="iniciativaMultiplaSelecionada"
                    :items="itemsIniciativaMultipla"
                    :search-input.sync="buscaIniciativaMultipla"
                    :rules="regras.exigidoObjId"
                    :loading="loadingIniciativaMultipla"
                  >
                  </v-autocomplete>
                </v-col>

                <!-- Botao de adicionar Callback -->
                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoCallback = !mostrarCampoCallback"
                  v-if="!mostrarCampoCallback"
                >
                  Adicionar callback
                </v-btn>

                <!-- Botao de adicionar NPS -->
                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoNps = !mostrarCampoNps"
                  v-if="
                    !mostrarCampoNps && !eventoMultiplo && !mostrarCampoPesquisa
                  "
                >
                  Adicionar NPS
                </v-btn>

                <!-- Botao de adicionar Pesquisa -->
                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="mostrarCampoPesquisa = !mostrarCampoPesquisa"
                  v-if="
                    !mostrarCampoPesquisa && !eventoMultiplo && !mostrarCampoNps
                  "
                >
                  Adicionar Pesquisa
                </v-btn>

                <!-- Botao de adicionar Multiplos Vencimentos -->
                <v-btn
                  color="primary"
                  text
                  class="mt-3"
                  small
                  @click="toggleEventoMultiplo"
                  v-if="
                    !mostrarCampoNps &&
                    !mostrarCampoPesquisa &&
                    !eventoMultiplo &&
                    reguasComEventoMultiplo.includes(regua.tipo)
                  "
                >
                  {{
                    regua.tipo === "IMPLANTACAO"
                      ? "Ativar Múltiplas Implantações"
                      : "Ativar Múltiplos Vencimentos"
                  }}
                </v-btn>
              </v-row>
              <!-- Horarios programados -->
              <v-row>
                <v-col cols="12">
                  <ProgramarHorario
                    :key="event.id"
                    :event="event"
                    ref="horarios"
                    class="pt-4"
                  />
                </v-col>
              </v-row>
            </v-col>

            <!-- Pré visualização da IniciativaWhatsapp -->
            <v-col cols="4">
              <v-carousel
                cycle
                height="600"
                hide-delimiter-background
                :hide-delimiters="!eventoMultiplo"
                :show-arrows="eventoMultiplo"
                show-arrows-on-hover
              >
                <v-carousel-item :key="'IniciativaNormal'">
                  <v-sheet height="100%">
                    <v-row
                      class="fill-height grey lighten-3 align-center justify-center ma-0"
                      style="width: 100%; overflow: auto"
                    >
                      <div v-if="iniciativaSelecionada.id" class="box-whatsapp">
                        <IniciativaWhatsapp
                          :iniciativa="iniciativaSelecionada.iniciativa"
                        />
                      </div>
                      <div
                        v-else
                        style="height: 600px"
                        class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary"
                      >
                        Pré-visualização Iniciativa
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
                <v-carousel-item
                  :key="'IniciativaMultiplo'"
                  v-if="eventoMultiplo"
                >
                  <v-sheet height="100%">
                    <v-row
                      class="fill-height grey lighten-3 align-center justify-center ma-0"
                      align="center"
                      justify="center"
                    >
                      <div
                        v-if="iniciativaMultiplaSelecionada.id"
                        class="fill-height"
                        style="width: 100%; overflow: auto"
                      >
                        <IniciativaWhatsapp
                          :iniciativa="iniciativaMultiplaSelecionada.iniciativa"
                        />
                      </div>
                      <div
                        v-else
                        style="height: 600px"
                        class="text-h6 d-flex justify-center align-center grey lighten-3 rounded text--secondary text-center"
                      >
                        Pré-visualização Iniciativas
                        {{ definirNomePorTipoRegua }}
                      </div>
                    </v-row>
                  </v-sheet>
                </v-carousel-item>
              </v-carousel>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <!-- Ações -->
      <v-spacer></v-spacer>
      <v-divider></v-divider>
      <v-card-actions class="mb-3 mr-3 flex-column align-end">
        <div>
          <v-btn color="primary" text @click="fecharDialog()"> Cancelar </v-btn>
          <v-btn
            color="primary"
            @click="save"
            :loading="salvando"
            :disabled="!isFormValid"
          >
            Salvar
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import { mapActions, mapGetters, mapState } from "vuex";
import npsService from "@/services/nps";
import pesquisaService from "@/services/pesquisas";
import IniciativaWhatsapp from "@/views/reguas/components/previewIniciativaWhatsapp/PreviewIniciativaWhatsapp.vue";
import iniciativaWhatsService from "@/services/iniciativa-whatsapp";
import { debounce } from "@/assets/js/utils";
import rules from "@/assets/js/rules";
import ProgramarHorario from "./ProgramarHorario.vue";

export default {
  name: "DialogEventRuler",
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    event: {
      type: Object,
      default: {},
    },
  },
  components: {
    IniciativaWhatsapp,
    ProgramarHorario,
  },
  data: () => ({
    isFormValid: false,
    regras: rules,
    url_callback: "",
    reguasComEventoMultiplo: [
      "VENCIMENTO_BOLETO",
      "VENCIMENTO_DEBITO",
      "TOLERANCIA_BOLETO",
      "TOLERANCIA_DEBITO",
      "PAGAMENTO_BOLETO_RECEBIDO",
      "IMPLANTACAO",
    ],
    salvando: false,
    obs: "",
    url: "",
    name: "",
    days: "",
    typeDay: 1,
    listaNps: [],
    listaPesquisas: [],
    npsSelecionada: {},
    pesquisaSelecionada: {},
    mostrarCampoCallback: false,
    mostrarCampoNps: false,
    mostrarCampoPesquisa: false,
    eventoMultiplo: false,

    iniciativaSelecionada: { id: null, nome: null, iniciativa: null },
    iniciativas: null,
    buscaIniciativa: null,
    buscaIniciativaMultipla: null,
    itemsIniciativa: [],
    itemsIniciativaMultipla: [],
    iniciativaMultiplaSelecionada: { id: null, nome: null, iniciativa: null },
    loadingIniciativa: false,
    loadingIniciativaMultipla: false,
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    ...mapGetters("variavelRegua", ["variaveis"]),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    title() {
      return this.event.id ? "Editar Evento " : "Novo Evento";
    },

    typesDays() {
      let dias = [];

      const tipos = [
        {
          tipo: "IMPLANTACAO",
          dias: [
            { index: 1, text: "Na implantação" },
            { index: 2, text: "Depois da implantação" },
          ],
        },
        {
          tipo: "VENCIMENTO_BOLETO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "VENCIMENTO_DEBITO",
          dias: [
            { index: 0, text: "Antes do vencimento" },
            { index: 1, text: "No vencimento" },
            { index: 2, text: "Depois do vencimento" },
          ],
        },
        {
          tipo: "TOLERANCIA_BOLETO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "TOLERANCIA_DEBITO",
          dias: [
            { index: 0, text: "Antes da tolerância" },
            { index: 1, text: "Na tolerância" },
            { index: 2, text: "Depois da tolerância" },
          ],
        },
        {
          tipo: "ANIVERSARIO_DO_CLIENTE",
          dias: [
            { index: 0, text: "Antes do aniversário" },
            { index: 1, text: "No aniversário" },
            { index: 2, text: "Depois do aniversário" },
          ],
        },
        {
          tipo: "BOLETO_SOLICITADO",
          dias: [
            { index: 1, text: "Na solicitação" },
            { index: 2, text: "Depois da solicitação" },
          ],
        },
        {
          tipo: "INICIO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do início" },
            { index: 1, text: "No início" },
            { index: 2, text: "Depois do início" },
          ],
        },
        {
          tipo: "CONTRATO_FINALIZADO",
          dias: [
            { index: 1, text: "Na finalização" },
            { index: 2, text: "Depois da finalização" },
          ],
        },
        {
          tipo: "CONTRATO_CANCELADO",
          dias: [
            { index: 1, text: "No cancelamento" },
            { index: 2, text: "Depois do cancelamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_BOLETO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PAGAMENTO_DEBITO_RECEBIDO",
          dias: [
            { index: 1, text: "No pagamento" },
            { index: 2, text: "Depois do pagamento" },
          ],
        },
        {
          tipo: "PARCELA_BOLETO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "PARCELA_DEBITO_CRIADA",
          dias: [
            { index: 1, text: "Na criação" },
            { index: 2, text: "Depois da criação" },
          ],
        },
        {
          tipo: "TERMINO_CONTRATO",
          dias: [
            { index: 0, text: "Antes do término" },
            { index: 1, text: "No término" },
            { index: 2, text: "Depois do término" },
          ],
        },
        {
          tipo: "MOVIMENTACAO",
          dias: [
            { index: 0, text: "Antes da movimentação" },
            { index: 1, text: "Na movimentação" },
            { index: 2, text: "Depois da movimentação" },
          ],
        },
      ];
      let tipoSelecionado = tipos.find(
        (tipos) => tipos.tipo == this.regua.tipo
      );
      return tipoSelecionado?.dias;
    },
    definirNomePorTipoRegua() {
      if (this.regua.tipo === "IMPLANTACAO") {
        return "Múltiplas Implantações";
      }
      return "Múltiplos Vencimentos";
    },
  },
  watch: {
    "iniciativaSelecionada.id": async function (newVal) {
      if (newVal) {
        await this.carregarIniciativas(newVal);
      } else {
        this.iniciativaSelecionada = { id: null, nome: null, iniciativa: null };
      }
    },
    "iniciativaMultiplaSelecionada.id": async function (newVal) {
      if (newVal) {
        await this.carregarIniciativasMultipla(newVal);
      } else {
        this.iniciativaMultiplaSelecionada = {
          id: null,
          nome: null,
          iniciativa: null,
        };
      }
    },

    buscaIniciativa: debounce(function (newVal) {
      if (newVal) {
        this.carregarIniciativas(newVal);
      } else {
        this.carregarIniciativas();
      }
    }, 300),
    buscaIniciativaMultipla: debounce(function (newVal) {
      if (newVal) {
        this.carregarIniciativasMultipla(newVal);
      } else {
        this.carregarIniciativasMultipla();
      }
    }, 300),

    async dialog() {
      if (this.dialog) {
        document.documentElement.style.overflow = "hidden";
        this.carregaNps();
        this.carregaPesquisa();
        this.npsSelecionada = this.event.nps || "";
        this.pesquisaSelecionada = this.event.pesquisa || {};
        this.url_callback = this.event.url_callback || "";
        this.mostrarCampoCallback = this.url_callback != "";
        this.mostrarCampoNps = this.event.nps
          ? Object.keys(this.event.nps).length > 0
          : false;
        this.mostrarCampoPesquisa = this.event?.pesquisa
          ? Object.keys(this.event.pesquisa).length > 0
          : false;

        // tratativa para caso a iniciativa encontrada nao esteja na pagina atual
        await this.carregarIniciativas();
        await this.carregarIniciativasMultipla();
        if (this.event.whatsapp_iniciativa?.id) {
          const listaIniciativas = await this.carregarIniciativas(
            this.event.whatsapp_iniciativa.nome
          );
          const iniciativaEncontrada = listaIniciativas.find(
            (item) => item.id === this.event.whatsapp_iniciativa?.id
          );
          if (iniciativaEncontrada) {
            this.iniciativaSelecionada = {
              nome: iniciativaEncontrada.nome || null,
              id: iniciativaEncontrada.id || null,
              iniciativa: iniciativaEncontrada || null,
            };
          }
        }

        this.obs = this.event.observacao || "";
        this.name = this.event.nome || "";
        if (this.event.dia != 0) {
          if (this.event.dia > 0) {
            this.typeDay = 2;
            this.days = this.event.dia;
          } else if (this.event.dia < 0) {
            this.typeDay = 0;
            this.days = this.event.dia * -1;
          }
        }

        this.eventoMultiplo = this.event.multiplo || false;

        if (this.eventoMultiplo) {
          // tratativa para caso a iniciativa encontrada nao esteja na pagina atual
          if (this.event.evento_multiplo.whatsapp_iniciativa?.id) {
            const listaIniciativas = await this.carregarIniciativasMultipla(
              this.event.evento_multiplo.whatsapp_iniciativa?.nome
            );
            const iniciativaEncontrada = listaIniciativas.find(
              (item) =>
                item.id === this.event.evento_multiplo.whatsapp_iniciativa?.id
            );
            if (iniciativaEncontrada) {
              this.iniciativaMultiplaSelecionada = {
                nome: iniciativaEncontrada.nome || null,
                id: iniciativaEncontrada.id || null,
                iniciativa: iniciativaEncontrada || null,
              };
            }
          }
        }

        this.$nextTick(() => {
          this.$refs.horarios.configuraHorarios();
        });
      } else {
        this.url = "";
        this.obs = "";
        this.name = "";
        this.days = "";
        this.typeDay = 1;
        this.$refs.form.resetValidation();
      }
    },
  },
  methods: {
    ...mapActions("evento", {
      salvarEvento: "salvarEvento",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    toggleEventoMultiplo() {
      this.eventoMultiplo = !this.eventoMultiplo;

      if (!this.eventoMultiplo) {
        this.iniciativaMultiplaSelecionada = {
          id: null,
          nome: null,
          iniciativa: null,
        };
      } else {
        this.isFormValid = false;
      }
    },

    async carregarIniciativas(busca = "") {
      this.loadingIniciativa = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        status: "APROVADA",
        ativo: true,
        busca: busca,
      };

      try {
        const { data } = await iniciativaWhatsService.listar(payload);
        this.itemsIniciativa = [];
        data.objects.forEach((iniciativa) => {
          this.itemsIniciativa.push({
            iniciativa: iniciativa,
            nome: iniciativa.nome,
            id: iniciativa.id,
          });
        });
        return data.objects;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingIniciativa = false;
      }
    },

    async carregarIniciativasMultipla(busca = "") {
      this.loadingIniciativaMultipla = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        status: "APROVADA",
        ativo: true,
        busca: busca,
      };

      try {
        const { data } = await iniciativaWhatsService.listar(payload);
        this.itemsIniciativaMultipla = [];
        data.objects.forEach((iniciativa) => {
          this.itemsIniciativaMultipla.push({
            iniciativa: iniciativa,
            nome: iniciativa.nome,
            id: iniciativa.id,
          });
        });
        return data.objects;
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingIniciativaMultipla = false;
      }
    },

    fecharDialog() {
      this.$emit("update:dialog", false);
      this.mostrarCampoCallback = false;
      this.mostrarCampoNps = false;
      this.mostrarCampoPesquisa = false;
      this.eventoMultiplo = false;
      this.npsSelecionada = {};
      this.pesquisaSelecionada = {};
      this.url_callback = "";
      this.buscaIniciativa = null;
      this.buscaIniciativaMultipla = null;
      this.iniciativaSelecionada = { id: null, nome: null, iniciativa: null };
      this.iniciativaMultiplaSelecionada = {
        id: null,
        nome: null,
        iniciativa: null,
      };

      document.documentElement.style.overflow = "";
    },

    save(evento) {
      console.debug("Salvando o evento");
      evento.preventDefault();

      if (!this.$refs.form.validate()) {
        return;
      }

      if (this.salvando) {
        return;
      }

      this.salvando = true;

      let payload = {};
      payload = {
        dia: this.dayByPayload(),
        nome: this.name || this.prepareData(this.event.nome),
        observacao: this.obs || this.prepareData(this.event.observacao),
        tipo: this.prepareData(this.event.tipo),
        regua_id: this.regua.id,
        status: this.prepareData(this.event.status) || "RASCUNHO",
        url_callback:
          this.url_callback || this.prepareData(this.event.url_callback),
        nps_id:
          this.npsSelecionada.id || this.prepareData(this.npsSelecionada.id),
        pesquisa_id:
          this.pesquisaSelecionada.id ||
          this.prepareData(this.pesquisaSelecionada.id),
        multiplo: this.eventoMultiplo,
        whatsapp_iniciativa_id: this.iniciativaSelecionada?.id || undefined,
        ...this.$refs.horarios.configurarPayloadHorarios(),
      };

      if (this.event.id) payload.id = this.event.id;

      if (this.eventoMultiplo) {
        payload.evento_multiplo = {
          whatsapp_iniciativa_id: this.iniciativaMultiplaSelecionada.id || null,
        };
      } else {
        payload.evento_multiplo = null;
      }

      this.salvarEvento(payload)
        .then((resp) => {
          console.debug("Evento salvo:", resp.id);
          this.$emit("edit", resp);
          this.fecharDialog();
          this.showSnackBar({
            text:
              "Evento " +
              (this.event.id ? "editado" : "criado") +
              " com sucesso!",
          });
          this.mostrarCampoCallback = false;
          this.mostrarCampoNps = false;
          this.mostrarCampoPesquisa = false;
        })
        .catch((e) => {
          console.error("Não foi possível salvar o evento", e);
          this.showSnackBar({
            text:
              "Erro ao " + (this.event.id ? "editar" : "criar") + " o evento!",
          });
        })
        .finally(() => {
          this.salvando = false;
          this.campanhaSelecionada = null;
        });
    },
    prepareData(item) {
      return typeof item === "undefined" ? null : item;
    },
    dayByPayload() {
      switch (this.typeDay) {
        case 0:
          return this.days ? this.days * -1 : null;
        case 1:
          return 0;
        case 2:
          return this.days || null;
        default:
          return null;
      }
    },

    async carregaNps() {
      let params = {
        empresa_id: this.empresaSelecionada.id,
        status: "PRODUCAO",
      };
      this.listaNps = [];

      try {
        const { data } = await npsService.getNps(params);
        data.forEach((nps) => {
          this.listaNps.push({
            text: nps.pergunta,
            value: nps,
          });
        });
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Erro ao carregar NPS",
        });
      }
    },
    async carregaPesquisa() {
      let params = {
        empresa_id: this.empresaSelecionada.id,
        status: "PRODUCAO",
      };
      this.listaPesquisas = [];

      try {
        const { data } = await pesquisaService.get(params);
        this.listaPesquisas = data;
      } catch (e) {
        console.error(e);
        this.showSnackBar({
          text: "Erro ao carregar pesquisas",
        });
      }
    },

    desativaCallback() {
      this.mostrarCampoCallback = false;
      this.url_callback = null;
      this.event.url_callback = null;
    },

    desativaNps() {
      this.mostrarCampoNps = false;
      this.npsSelecionada = {};
      this.event.nps_id = null;
    },
    desativaPesquisa() {
      this.mostrarCampoPesquisa = false;
      this.pesquisaSelecionada = {};
      this.event.pesquisa_id = null;
    },
  },
};
</script>

<style scoped lang="scss">
.ml-pre-visualizacao-multiplo {
  word-break: break-word;
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 8px 4px;
  position: relative;
}

.ml-pre-visualizacao-multiplo::before {
  background-color: #e3f2fd;
  border-radius: 4px;
  content: "Pré-visualização para múltiplos";
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  position: absolute;
  left: 0px;
  top: -10px;
}

.ml-pre-visualizacao-iniciativa {
  background-color: #e3f2fd;
  border-radius: 4px;
  font-size: 13px;
  padding: 8px 8px 4px;
  position: relative;
}
.ml-pre-visualizacao-iniciativa::before {
  background-color: #e3f2fd;
  border-radius: 4px;
  content: "Pré-visualização da iniciativa";
  font-size: 10px;
  font-weight: 500;
  padding: 0 8px;
  position: absolute;
  left: 0px;
  top: -10px;
}
::-webkit-scrollbar {
  width: 4px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}

.text-black {
  color: black;
}

.counter-sms {
  color: red;
}

.select-nps,
.select-pesquisa {
  max-width: 693px;
}
.select-iniciativa {
  max-width: 693px;
}

.box-whatsapp {
  height: 100%;
  width: 100%;
  overflow-y: auto;
  background-image: url("../../../../../assets/img/whatsappBackGround.png");
  background-size: contain;
  background-repeat: repeat;
}

.seletor-dias {
  display: flex;
  align-items: center;
  gap: 1rem;
  .dia {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background-color: #e8eaf6;
    padding: 0.5rem;
    cursor: pointer;
    height: 1.7rem;
    width: 1.7rem;
    font-weight: 500;
    &:hover {
      opacity: 0.7;
    }
    &.disabled {
      color: unset;
      opacity: 0.7;
      pointer-events: none;
    }
    &.ativo {
      color: #fff;
      background-color: #3f51b5;
    }
    &.feriado {
      width: unset;
      height: 2rem;
      border-radius: 2rem;
    }
  }
}
</style>
