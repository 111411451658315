var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"d-flex flex-column"},[(!_vm.carregando)?_c('div',{staticClass:"d-flex mx-auto align-center mb-2",class:{
      'mt-4': !_vm.quantidadeReguas || !_vm.quantidadeReguasArquivadas,
      'mb-4': !_vm.quantidadeReguasArquivadas,
    },staticStyle:{"width":"1000px"}},[_c('div',{staticClass:"text-h6 font-weight-bold"},[_vm._v(" Listagem de Réguas "+_vm._s(_vm.quantidadeReguas ? "(" + (_vm.routeParams.arquivadas ? _vm.quantidadeReguas + _vm.quantidadeReguasArquivadas : _vm.quantidadeReguas) + ")" : "")+" ")]),_c('v-spacer'),(_vm.quantidadeReguasArquivadas)?_c('div',{staticClass:"d-flex"},[_c('v-switch',{attrs:{"label":`Mostrar arquivadas (${_vm.quantidadeReguasArquivadas})`},model:{value:(_vm.routeParams.arquivadas),callback:function ($$v) {_vm.$set(_vm.routeParams, "arquivadas", $$v)},expression:"routeParams.arquivadas"}})],1):_vm._e()],1):_vm._e(),_c('div',[_c('v-card',{staticClass:"table-wraper mx-auto"},[(!_vm.carregando)?_c('v-data-table',{attrs:{"hide-default-footer":""},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"regua__wrapper list-wraper"},[_c('v-expansion-panels',{staticClass:"expansion-panels",attrs:{"accordion":"","multiple":""},model:{value:(_vm.painelRegua),callback:function ($$v) {_vm.painelRegua=$$v},expression:"painelRegua"}},_vm._l((_vm.reguas),function(regua){return _c('v-expansion-panel',{key:regua.id,ref:"reguas",refInFor:true,staticClass:"rounded-0 pa-0 elevation-0 flex-column",attrs:{"id":regua.id,"readonly":_vm.verificaEventoAtivo(regua.eventos) &&
                  !regua.id_regua_desafiante}},[(_vm.verificaSeReguaTesteAb(regua))?_c('v-expansion-panel-header',{staticClass:"pa-1 d-flex justify-start",style:(_vm.verificaEventoAtivo(regua.eventos) &&
                    !regua.id_regua_desafiante
                      ? 'cursor: unset'
                      : ''),scopedSlots:_vm._u([{key:"actions",fn:function(){return [(
                        _vm.verificaEventoAtivo(regua.eventos) &&
                        !regua.id_regua_desafiante
                      )?_c('v-icon',{class:{
                        'mr-6':
                          _vm.verificaEventoAtivo(regua.eventos) &&
                          !regua.id_regua_desafiante,
                      },attrs:{"color":"primary"}}):_vm._e()]},proxy:true}],null,true)},[_c('div',{staticClass:"d-flex align-center"},[(_vm.checkCredentials(['admin', 'editor_regua']))?_c('v-switch',{staticClass:"mr-2",attrs:{"value":"PRODUCAO","disabled":regua.status == 'ARQUIVADA'},nativeOn:{"!click":function($event){$event.stopPropagation();regua.status === 'PRODUCAO'
                          ? _vm.pararRegua(regua)
                          : _vm.rodarRegua(regua)}},model:{value:(regua.status),callback:function ($$v) {_vm.$set(regua, "status", $$v)},expression:"regua.status"}}):_vm._e(),_c('router-link',{staticClass:"text-subtitle-1 regua__name text-decoration-none",class:regua.status,staticStyle:{"color":"rgba(0, 0, 0, 0.87)"},attrs:{"to":{
                        name: 'eventos',
                        query: {
                          tipo_movimentacao_id:
                            _vm.$route.query.tipo_movimentacao_id,
                        },
                        params: { regua_id: regua.id },
                      }}},[_vm._v(" "+_vm._s(regua.nome)+" "),(regua.id_regua_desafiante)?_c('v-icon',{staticClass:"ml-1"},[_vm._v(" mdi-alpha-a-circle-outline ")]):_vm._e()],1),(regua.id_regua_desafiante)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-chip',_vm._g(_vm._b({staticClass:"ml-2",attrs:{"color":"orange","small":"","dark":""}},'v-chip',attrs,false),on),[_vm._v(" Teste A/B ")])]}}],null,true)},[_c('span',[_vm._v(" Amostra início: "+_vm._s(regua.digito_verificador_inicio)+" ")]),_c('br'),_c('span',[_vm._v(" Amostra fim: "+_vm._s(regua.digito_verificador_fim)+" ")])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-icon',_vm._g({directives:[{name:"show",rawName:"v-show",value:(regua.observacao),expression:"regua.observacao"}],staticClass:"ml-1",attrs:{"small":""}},on),[_vm._v("mdi-text-long")])]}}],null,true)},[_c('span',[_vm._v(_vm._s(regua.observacao))])]),(
                        regua.perfis?.length > 0 &&
                        regua.status != 'ARQUIVADA'
                      )?_c('v-chip',{staticClass:"mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2",attrs:{"small":"","outlined":"","link":"","color":regua.status != 'PRODUCAO' ? 'grey' : 'purple'},on:{"click":function($event){$event.stopPropagation();return _vm.visualizarPerfisRegua(regua)}}},[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(regua.perfis?.length)+" "+_vm._s(regua.perfis?.length > 1 ? "perfis vinculados" : "perfil vinculado")+" ")])]):_vm._e(),_c('v-spacer'),(regua.eventos_info)?_c('div',{staticClass:"shrink flex-row align-center ml-2",class:{
                        'indigo--text text--accent-3':
                          regua.status == 'PRODUCAO',
                      }},[(regua.eventos_info.quantidade_eventos > 0)?_c('router-link',{staticClass:"regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none",attrs:{"to":{
                          name: 'eventos',
                          query: {
                            tipo_movimentacao_id:
                              _vm.$route.query.tipo_movimentacao_id,
                          },
                          params: { regua_id: regua.id },
                        }}},[_c('v-chip',{staticClass:"mr-2 d-flex flex-column white--text regua__evento__text",attrs:{"outlined":"","link":"","small":"","color":regua.status != 'PRODUCAO'
                              ? 'grey'
                              : 'indigo accent-3'}},[_c('span',{staticClass:"text-subtitle-2 font-weight-black"},[_vm._v(" "+_vm._s(regua.eventos_info.quantidade_eventos)+" "+_vm._s(regua.eventos_info.quantidade_eventos > 1 ? "eventos" : "evento")+" "),(
                                regua.tipo != 'IMPLANTACAO' &&
                                regua.eventos_info.janela_eventos > 1
                              )?_c('span',{staticClass:"text-secondary text-caption font-weight-light events__sub"},[_vm._v(" em "+_vm._s(regua.eventos_info.janela_eventos)+" "+_vm._s(regua.eventos_info.janela_eventos > 1 ? "dias" : "dia")+" ")]):_vm._e()])])],1):_vm._e()],1):_vm._e(),(_vm.checkCredentials(['admin', 'editor_regua']))?_c('div',{staticClass:"align-center regua__wrapper__menu ml-2"},[_c('v-menu',{scopedSlots:_vm._u([{key:"activator",fn:function({ on }){return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',[_vm._v("mdi-dots-vertical")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{on:{"click":function($event){return _vm.editarRegua(regua)}}},[_c('v-list-item-title',[_vm._v("Editar Informações")])],1),_c('v-list-item',{attrs:{"to":{
                              name: 'eventos',
                              params: { regua_id: regua.id },
                              query: {
                                tipo_movimentacao_id:
                                  _vm.$route.query.tipo_movimentacao_id,
                              },
                            }}},[_c('v-list-item-title',[_vm._v(" Editar Eventos ")])],1),_c('v-list-item',{on:{"click":function($event){return _vm.desativarRegua(regua)}}},[_c('v-list-item-title',[_vm._v(" "+_vm._s(regua.status != "ARQUIVADA" ? "Arquivar" : "Ativar")+" ")])],1)],1)],1)],1):_vm._e()],1)]):_vm._e(),_c('v-expansion-panel-content',[_c('v-row',{staticClass:"mt-1 mb-3 px-3",staticStyle:{"max-width":"1000px"}},_vm._l((_vm.listarEventosPorDia(regua.eventos)),function(evento){return _c('div',{key:evento.id},[_c('v-card',{staticClass:"mx-1 rounded-lg mb-2",staticStyle:{"min-width":"100px","height":"70px"}},[_c('v-card-title',{staticClass:"px-2 pt-1 pb-0 justify-center subtitle-2 white--text",style:({
                            'background-color': _vm.retornaCorCard(evento.dia),
                          })},[_vm._v(" "+_vm._s(_vm.retornaDiaEvento(evento, regua))+" ")]),_c('v-card-text',{staticClass:"text-center pt-2"},[(_vm.contadorEventos(regua, 'EMAIL', evento.dia))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-email ")])]}}],null,true)},[_c('span',[_vm._v("Notificação E-MAIL")]),_c('br')]):_vm._e(),(_vm.contadorEventos(regua, 'SMS', evento.dia))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-message ")])]}}],null,true)},[_c('span',[_vm._v("Notificação SMS")]),_c('br')]):_vm._e(),(
                              _vm.contadorEventos(regua, 'WHATSAPP', evento.dia)
                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-whatsapp ")])]}}],null,true)},[_c('span',[_vm._v("Notificação WhatsApp")]),_c('br')]):_vm._e(),(_vm.contadorEventos(regua, 'PUSH', evento.dia))?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-bell ")])]}}],null,true)},[_c('span',[_vm._v("Notificação PUSH")]),_c('br')]):_vm._e(),(
                              _vm.contadorEventos(
                                regua,
                                'LIGACAO_CELULAR',
                                evento.dia
                              )
                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-cellphone ")])]}}],null,true)},[_c('span',[_vm._v("Notificação LIGAÇÃO CELULAR")]),_c('br')]):_vm._e(),(
                              _vm.contadorEventos(
                                regua,
                                'LIGACAO_FIXO',
                                evento.dia
                              )
                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-phone ")])]}}],null,true)},[_c('span',[_vm._v("Notificação LIGAÇÃO FIXO")]),_c('br')]):_vm._e(),(
                              _vm.contadorEventos(regua, 'COMANDO', evento.dia)
                            )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-icon',_vm._g(_vm._b({staticClass:"mr-1",attrs:{"small":""}},'v-icon',attrs,false),on),[_vm._v(" mdi-xml ")])]}}],null,true)},[_c('span',[_vm._v("Notificação COMANDO")]),_c('br')]):_vm._e()],1)],1)],1)}),0),(regua.id_regua_desafiante)?_c('ReguaExpansionTesteAb',{attrs:{"regua":regua},on:{"pararRegua":_vm.pararRegua,"rodarRegua":_vm.rodarRegua,"editarRegua":_vm.editarRegua,"desativarRegua":_vm.desativarRegua,"visualizarPerfisRegua":_vm.visualizarPerfisRegua}}):_vm._e()],1)],1)}),1)],1)]},proxy:true}],null,false,1749317339)}):_vm._e()],1)],1),_c('PromiseBasedDialog',{ref:"promiseBasedDialog",attrs:{"options":_vm.promiseBasedDialogOptions}}),_c('DialogNovaRegua',{ref:"editOuNovaReguaDialog",on:{"addOrUpdateRegua":_vm.addOrUpdateRegua}}),_c('DialogVisualizarPerfis',{ref:"dialogVisualizarPerfis"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }