var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text--primary"},[_c('span',{staticClass:"footer-text",domProps:{"innerHTML":_vm._s(
      _vm.getFormatedHtml(
        _vm.iniciativa?.mensagem_renderizada ? 
        _vm.iniciativa?.mensagem_renderizada : 
        _vm.iniciativa?.mensagem
      )
    )}})])
}
var staticRenderFns = []

export { render, staticRenderFns }