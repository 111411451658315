<template>
  <div>
    <v-row class="d-flex align-center mb-2">
      <v-switch
        v-model="getReguaById(regua.id_regua_desafiante).status"
        value="PRODUCAO"
        class="mr-2 ml-3"
        v-if="checkCredentials(['admin', 'editor_regua'])"
        :disabled="regua.status == 'ARQUIVADA'"
        @click.stop.native.capture="
          getReguaById(regua.id_regua_desafiante).status === 'PRODUCAO'
            ? $emit('pararRegua', getReguaById(regua.id_regua_desafiante))
            : $emit('rodarRegua', getReguaById(regua.id_regua_desafiante))
        "
      ></v-switch>
      <router-link
        :to="{
          name: 'eventos',
          params: {
            regua_id: getReguaById(regua.id_regua_desafiante).id,
          },
        }"
        style="color: rgba(0, 0, 0, 0.87)"
        class="text-subtitle-1 regua__name text-decoration-none"
        :class="regua.status"
        >{{ getReguaById(regua.id_regua_desafiante).nome }}
        <v-icon class="ml-1">mdi-alpha-b-circle-outline</v-icon>
      </router-link>
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <v-chip
            v-bind="attrs"
            v-on="on"
            color="orange"
            small
            class="ml-2"
            dark
          >
            Teste A/B
          </v-chip>
        </template>

        <span
          >Amostra início:
          {{
            getReguaById(regua.id_regua_desafiante).digito_verificador_inicio
          }} </span
        ><br />
        <span
          >Amostra fim:
          {{ getReguaById(regua.id_regua_desafiante).digito_verificador_fim }}
        </span>
      </v-tooltip>
      <v-tooltip bottom>
        <template v-slot:activator="{ on }">
          <v-icon
            class="ml-1"
            v-show="getReguaById(regua.id_regua_desafiante).observacao"
            v-on="on"
            small
            >mdi-text-long</v-icon
          >
        </template>
        <span>{{ getReguaById(regua.id_regua_desafiante).observacao }}</span>
      </v-tooltip>
      <v-chip
        small
        outlined
        link
        @click.stop="$emit('visualizarPerfisRegua', regua)"
        v-if="getReguaById(regua.id_regua_desafiante).perfis.length > 0"
        :color="
          regua.status != 'PRODUCAO'
            ? 'grey'
            : 'purple'
        "
        class="mr-2 d-flex flex-column white--text regua__evento__text regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none ml-2"
      >
        <span class="text-subtitle-2 font-weight-black">
          {{ getReguaById(regua.id_regua_desafiante).perfis.length }}
          {{
            getReguaById(regua.id_regua_desafiante).perfis.length > 1
              ? "perfis vinculados"
              : "perfil vinculado"
          }}
        </span>
      </v-chip>
      <v-spacer></v-spacer>
      <div
        v-if="getReguaById(regua.id_regua_desafiante).eventos_info"
        class="shrink flex-row align-center ml-2"
        :class="{
          'indigo--text text--accent-3':
            getReguaById(regua.id_regua_desafiante).status == 'PRODUCAO',
        }"
      >
        <router-link
          v-if="
            getReguaById(regua.id_regua_desafiante).eventos_info
              .quantidade_eventos > 0
          "
          :to="{
            name: 'eventos',
            params: {
              regua_id: getReguaById(regua.id_regua_desafiante).id,
            },
          }"
          class="regua__evento d-flex flex-row justify-start text-decoration-none text-decoration-none"
        >
          <v-chip
            outlined
            link
            small
            :color="
              getReguaById(regua.id_regua_desafiante).status != 'PRODUCAO'
                ? 'grey'
                : 'indigo accent-3'
            "
            class="mr-4 d-flex flex-column white--text regua__evento__text"
          >
            <span class="text-subtitle-2 font-weight-black">
              {{
                getReguaById(regua.id_regua_desafiante).eventos_info
                  .quantidade_eventos
              }}
              {{
                getReguaById(regua.id_regua_desafiante).eventos_info
                  .quantidade_eventos > 1
                  ? "eventos"
                  : "evento"
              }}
              <span
                v-if="
                  getReguaById(regua.id_regua_desafiante).tipo !=
                    'IMPLANTACAO' &&
                  getReguaById(regua.id_regua_desafiante).eventos_info
                    .janela_eventos > 1
                "
                class="text-secondary text-caption font-weight-light events__sub"
                >em
                {{
                  getReguaById(regua.id_regua_desafiante).eventos_info
                    .janela_eventos
                }}
                {{
                  getReguaById(regua.id_regua_desafiante).eventos_info
                    .janela_eventos > 1
                    ? "dias"
                    : "dia"
                }}
              </span>
            </span>
          </v-chip>
        </router-link>
      </div>
      <div
        class="align-center regua__wrapper__menu mr-9"
        v-if="checkCredentials(['admin', 'editor_regua'])"
      >
        <v-menu>
          <template v-slot:activator="{ on }">
            <v-btn icon v-on="on">
              <v-icon>mdi-dots-vertical</v-icon>
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                $emit('editarRegua', getReguaById(regua.id_regua_desafiante))
              "
            >
              <v-list-item-title>Editar Informações</v-list-item-title>
            </v-list-item>
            <v-list-item
              :to="{
                name: 'eventos',
                params: { regua_id: getReguaById(regua.id_regua_desafiante).id },
                query: {
                  tipo_movimentacao_id:
                    $route.query.tipo_movimentacao_id,
                },
              }"
            >
              <v-list-item-title>Editar Eventos</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="
                $emit('desativarRegua', getReguaById(regua.id_regua_desafiante))
              "
            >
              <v-list-item-title>{{
                regua.status != "ARQUIVADA" ? "Arquivar" : "Ativar"
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </div>
    </v-row>
    <v-row
      class="mt-1 mb-3 px-3"
      style="max-width: 1000px"
      v-if="regua.id_regua_desafiante"
    >
      <div
        v-for="evento in listarEventosPorDia(
          getReguaById(regua.id_regua_desafiante).eventos
        )"
        :key="evento.id"
      >
        <v-card
          style="min-width: 100px; height: 70px"
          class="mx-1 rounded-lg mb-2"
        >
          <v-card-title
            :style="{
              'background-color': retornaCorCard(evento.dia),
            }"
            class="px-2 pt-1 pb-0 justify-center subtitle-2 white--text"
          >
            {{ retornaDiaEvento(evento) }}
          </v-card-title>
          <v-card-text class="text-center pt-2">
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'EMAIL',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-email</v-icon
                >
              </template>

              <span>Notificação E-MAIL</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'SMS',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-message</v-icon
                >
              </template>

              <span>Notificação SMS</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'WHATSAPP',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-whatsapp</v-icon
                >
              </template>

              <span>Notificação WhatsApp</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'PUSH',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-bell</v-icon
                >
              </template>

              <span>Notificação PUSH</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'LIGACAO_CELULAR',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-cellphone</v-icon
                >
              </template>

              <span>Notificação LIGAÇÃO CELULAR</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'LIGACAO_FIXO',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-phone</v-icon
                >
              </template>

              <span>Notificação LIGAÇÃO FIXO</span><br />
            </v-tooltip>
            <v-tooltip
              bottom
              v-if="
                contadorEventos(
                  getReguaById(regua.id_regua_desafiante),
                  'COMANDO',
                  evento.dia
                )
              "
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon small class="mr-1" v-bind="attrs" v-on="on"
                  >mdi-xml</v-icon
                >
              </template>

              <span>Notificação COMANDO</span><br />
            </v-tooltip>
          </v-card-text>
        </v-card>
      </div>
    </v-row>
  </div>
</template>

<script>
import { checkCredentials } from "@/assets/js/utils";
import { mapGetters } from "vuex";
export default {
  props: {
    regua: null,
  },
  methods: {
    checkCredentials,
    contadorEventos(regua, tipoEvento, diaEvento) {
      let eventos = (regua.eventos || []).filter(
        (evento) => evento.status == "PRODUCAO"
      );
      return (
        eventos.filter(
          (item) => item.tipo == tipoEvento && item.dia == diaEvento
        )?.length || 0
      );
    },
    listarEventosPorDia(eventos) {
      var eventosFiltrados = eventos.reduce((unicoEvento, evento) => {
        if (evento.status == "PRODUCAO") {
          if (!unicoEvento.some((obj) => obj.dia === evento.dia)) {
            unicoEvento.push(evento);
          }
        }
        return unicoEvento;
      }, []);
      eventosFiltrados.sort((a, b) => (a.dia > b.dia ? 1 : -1));
      return eventosFiltrados;
    },
    retornaCorCard(diaEvento) {
      if (diaEvento < 0) {
        return "#448AFF";
      } else if (diaEvento == 0) {
        return "#FFAB40";
      } else {
        return "#EF5350";
      }
    },
    retornaDiaEvento(evento) {
      if (evento.dia == 0) {
        if (this.$route.query.tab == "VENCIMENTO_BOLETO") {
          return "Vencimento";
        } else if (this.$route.query.tab == "IMPLANTACAO") {
          return "Implantação";
        } else if (this.$route.query.tab == "TOLERANCIA") {
          return "Tolerância";
        } else {
          return "Comunicação";
        }
      } else if (evento.dia > 0) {
        return "+" + evento.dia;
      } else {
        return evento.dia;
      }
    },
  },
  computed: {
    ...mapGetters("reguas", {
      getReguaById: "getReguaById",
    }),
  },
  watch: {
    "regua.perfis"() {
      this.getReguaById(this.regua);
    },
  },
};
</script>

<style scoped lang="scss">
.events__sub {
  font-size: 10px !important;
  letter-spacing: 0.1px !important;
}
.RASCUNHO {
  .v-application .indigo.accent-3 {
    background-color: #9f9f9f !important;
    border-color: #9f9f9f !important;
  }
  color: #9f9f9f !important;
}
.regua__wrapper {
  a:nth-of-type(1) {
    &:hover {
      color: #4264fe !important;
      text-decoration: underline;
    }
  }
}
</style>
