<template>
  <div
    class="pa-4 mx-auto"
    style="max-width: 400px;"
  >
    <v-list two-line>
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Versão</v-list-item-title>
          <v-list-item-subtitle>{{ versao }}</v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>

      <v-list-item>
        <v-list-item-content>
          <v-list-item-title>Usuário autenticado</v-list-item-title>

          <v-list-item-subtitle v-if="usuario">
            <div><strong>{{ usuario.nome }}</strong></div>
            <div>{{ usuario.email }}</div>
          </v-list-item-subtitle>

          <v-list-item-subtitle v-else>{{ "-" }}</v-list-item-subtitle>
        </v-list-item-content>

        <v-tooltip v-if="usuario" bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-list-item-action v-bind="attrs" v-on="on">
              <v-btn icon @click="logout">
                <v-icon color="grey lighten-1">mdi-exit-to-app</v-icon>
              </v-btn>
            </v-list-item-action>
          </template>
          <span>Deslogar</span>
        </v-tooltip>
      </v-list-item>
    </v-list>

    <div v-if="deferredPrompt" class="text-center">
      <v-btn @click="instalar()">
        Instalar
      </v-btn>
    </div>

    <div class="text-center mt-4">
      <v-btn @click="limparCache()">
        Limpar cache
      </v-btn>
    </div>

    <div class="text-center mt-4">
      <v-btn class="error" @click="gerarErro()">
        Gerar erro
      </v-btn>
    </div>
  </div>
</template>

<script>
const versao = require('@/../package.json').version
import { mapGetters } from 'vuex'

export default {
  data: () => ({
    cliente: null,
    deferredPrompt: null,
    tokenPush: null,
    versao: versao,
  }),

  computed: {
    ...mapGetters('auth', {
      usuario: 'getUsuario',
    }),
  },

  created() {
    // Captura o evento de instalação do PWA
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault()
      this.deferredPrompt = e
    });
  },

  methods: {
    gerarErro() {
      throw new Error('Isso é um erro')
    },

    logout() {
      // 
    },

    /**
     * Instala o PWA.
     */
    async instalar() {
      this.deferredPrompt.prompt()

      const { outcome } = await this.deferredPrompt.userChoice
      console.log(`Resposta da instalação do PWA: ${outcome}`)

      this.deferredPrompt = null
    },

    /**
     * Limpa todos os cache storages.
     */
    limparCache() {
      console.debug('Limpando caches.')

      caches.keys().then(function (names) {
        for (let name of names) {
          console.debug(`Limpando cache storage: ${name}`)
          caches.delete(name)
        }
      });
    },
  },
};
</script>
