<template>
  <v-row>
    <v-col sm="12" class="pb-0">
      <v-timeline class="qj-timeline pl-2">
        <v-timeline-item
          right
          :key="event.dia"
          :large="event.dia == 0"
          :ref="'event-' + event.dia"
          :small="!isImportant(event)"
          :color="event.dia == 0 ? 'red' : isImportant(event) ? 'blue' : 'grey'"
          v-for="event in getEvents"
          :class="getClassTimeline(event)"
        >
          <!-- Dia -->
          <day slot="opposite" :day="event.dia" />
          <!-- Eventos -->
          <card
            :event="eventByOrder"
            :key="hash(eventByOrder)"
            @stop="$emit('stop', $event)"
            @open="$emit('open', $event)"
            @start="$emit('start', $event)"
            @disabled="$emit('disabled', $event)"
            v-for="eventByOrder in setOrderId(event)"
          />
          <!-- Botão add evento fim -->
          <end-button
            :day="event.dia"
            :isImportant="isImportant(event)"
            @open="$emit('open', $event)"
            v-if="checkCredentials(['admin', 'editor_regua'])"
          />
        </v-timeline-item>
      </v-timeline>
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import Day from "./TimelineDay";
import Card from "./TimelineCard";
import EndButton from "./TimelineEndButton";
import { checkCredentials } from "@/assets/js/utils";
import hash from 'object-hash';

export default {
  name: "TimelineRuler",
  props: {
    events: {
      type: Array,
      required: true,
    },
    showDisabled: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    Day,
    Card,
    EndButton,
  },
  data: () => ({
    setCenter: true,
    hash
  }),
  computed: {
    ...mapState("regua", ["regua"]),
    getEvents() {
      let processedEvents = [
        {
          dia: 0,
          sms: [],
          email: [],
        },
      ];

      this.events.forEach((e) => {
        if (!this.showDisabled && e.status == "ARQUIVADA") return;
        
        let index = processedEvents.map((e) => e.dia).indexOf(e.dia);
        if (index !== -1) {
          if (e.tipo == "SMS") processedEvents[index].sms.push(e);
          else processedEvents[index].email.push(e);
        } else {
          let newDay = {
            dia: e.dia,
            sms: [],
            email: [],
          };
          if (e.tipo == "SMS") newDay.sms.push(e);
          else newDay.email.push(e);

          if (e.dia < 0) {
            if (processedEvents[0].dia != newDay.dia + 1) {
              for (let i = processedEvents[0].dia - 1; i > newDay.dia; i--) {
                processedEvents.unshift({
                  dia: i,
                  sms: [],
                  email: [],
                });
              }
            }
            processedEvents.unshift(newDay);
          } else {
            if (
              processedEvents[processedEvents.length - 1].dia !=
              newDay.dia - 1
            ) {
              for (
                let i = processedEvents[processedEvents.length - 1].dia + 1;
                i < newDay.dia;
                i++
              ) {
                processedEvents.push({
                  dia: i,
                  sms: [],
                  email: [],
                });
              }
            }
            processedEvents.push(newDay);
          }
        }
      });
      let reguasSemEventoAntecipado = [
        "IMPLANTACAO",
        "PAGAMENTO_BOLETO_RECEBIDO",
        "PAGAMENTO_DEBITO_RECEBIDO",
        "BOLETO_SOLICITADO",
        "CONTRATO_CANCELADO",
        "CONTRATO_FINALIZADO",
        "PARCELA_BOLETO_CRIADA",
        "PARCELA_DEBITO_CRIADA",
      ];
      if (!reguasSemEventoAntecipado.includes(this.regua.tipo)) {
        processedEvents.unshift({
          dia: processedEvents[0].dia - 1,
          sms: [],
          email: [],
        });
      }

      processedEvents.push({
        dia: processedEvents[processedEvents.length - 1].dia + 1,
        sms: [],
        email: [],
      });

      if (this.setCenter) {
        this.setCenter = false;
        setTimeout(() => {
          this.$vuetify.goTo(this.$refs["event-0"][0], {
            duration: 0,
            offset: 250,
          });
        });
      }
      return processedEvents;
    },
  },
  mounted() {
    let intervaloDeScroll;
    let container = document.querySelector(".qj-timeline");

    container.addEventListener("mousemove", function (event) {
      let larguraContainer = this.clientWidth;
      let mouseX = event.clientX - this.getBoundingClientRect().left;
      let sensibilidade = 40;

      if (mouseX < sensibilidade) {
        clearInterval(intervaloDeScroll);
        intervaloDeScroll = setInterval(
          function () {
            this.scrollLeft -= 5;
          }.bind(this),
          10
        );
      } else if (mouseX > larguraContainer - sensibilidade) {
        clearInterval(intervaloDeScroll);
        intervaloDeScroll = setInterval(
          function () {
            this.scrollLeft += 5;
          }.bind(this),
          10
        );
      } else {
        clearInterval(intervaloDeScroll);
      }
    });

    container.addEventListener("mouseleave", function () {
      clearInterval(intervaloDeScroll);
    });
  },
  methods: {
    checkCredentials,
    isImportant(event) {
      if (this.isExtremity(event.dia)) return true;
      if (event.sms.length || event.email.length) return true;
      return false;
    },
    isExtremity(day) {
      if (
        this.getEvents[0].dia == day ||
        this.getEvents[this.getEvents.length - 1].dia == day
      ) {
        return true;
      }
      return false;
    },
    setOrderId(event) {
      let ordem = {
        SMS: 1,
        EMAIL: 2,
        PUSH: 3,
        LIGACAO_CELULAR: 4,
        LIGACAO_FIXO: 5,
        WHATSAPP: 6,
        RCS: 7,
        COMANDO: 8,
        PRODUCAO: 1,
        RASCUNHO: 2,
        ARQUIVADA: 3,
      };

      return event.sms
        .concat(event.email)
        .sort((a, b) => ordem[a?.tipo] - ordem[b?.tipo])
        .sort((a, b) => ordem[a?.status] - ordem[b?.status]);
    },
    getClassTimeline(event) {
      let events = this.setOrderId(event);
      let editavel = checkCredentials(["admin", "editor_regua"]);

      let classes = {
        editavel: editavel || events.length,
      };

      classes["qj-timeline-item" + (event.dia == 0 ? "-0" : "")] = true;

      return classes;
    },
  },
};
</script>

<style>
.v-application--is-ltr
  .qj-timeline.v-timeline:not(.v-timeline--dense):not(
    .v-timeline--reverse
  ):before {
  left: 165px;
}
.qj-timeline.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
  .v-timeline-item:nth-child(odd):not(.v-timeline-item--before)
  .v-timeline-item__body,
.qj-timeline.v-timeline:not(.v-timeline--dense):not(.v-timeline--reverse)
  .v-timeline-item--after
  .v-timeline-item__body {
  max-width: calc(100% - 133px);
  width: calc(100% - 178px);
}
.qj-timeline .v-timeline-item__opposite {
  max-width: 110px;
  width: 110px;
  min-width: 110px;
}
.qj-timeline .v-timeline-item__body > .v-card:not(.v-card--flat):before,
.qj-timeline .v-timeline-item__body > .v-card:not(.v-card--flat):after {
  border: hidden !important;
}
.qj-timeline .v-timeline-item__body {
  display: flex;
}
.qj-timeline .v-timeline-item__dot {
  z-index: 0;
}
.qj-timeline .editavel.qj-timeline-item-0.v-timeline-item::before,
.qj-timeline .editavel.qj-timeline-item.v-timeline-item::before {
  bottom: 0;
  content: "";
  position: absolute;
  width: calc(100% - 165px);
  left: 155px;
}
.qj-timeline .qj-timeline-item.v-timeline-item::before {
  background: rgba(0, 0, 0, 0.12);
  top: calc(50% - 12px);
  height: 1px;
}
.qj-timeline .qj-timeline-item-0.v-timeline-item::before {
  background: rgba(0, 0, 0, 0.24);
  top: calc(50% - 14px);
  height: 4px;
}

.qj-timeline-item-0,
.qj-timeline-item {
  position: relative;
  width: max-content;
}

.repeating-gradient {
  background-image: repeating-linear-gradient(
    -45deg,
    #e0e0e040,
    #e0e0e040 5px,
    #75757540 5px,
    #75757540 10px
  );
}
</style>
