<template>
  <v-dialog v-model="dialog" class="pa-5" :width="500" persistent>
    <v-form @submit="salvar($event)" lazy-validation>
      <v-card>
        <v-card-title class="text-h5 title-card">
          {{ this.editaRegua ? "Editar" : "Vincular" }} Perfil
        </v-card-title>
        <v-card-text>
          Selecione a régua que deseja vincular os perfis
          <strong>{{ perfilCliente | perfilCliente }}</strong> e
          <strong>{{ perfilContrato | perfilContrato }}</strong>
        </v-card-text>
        <v-card-text class="pt-0">
          <div class="d-flex">
            <v-select
              hide-details
              outlined
              label="Régua"
              no-data-text="Nenhuma régua ativa"
              :items="reguasFiltradas"
              v-model="reguaSelecionada"
            ></v-select>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-btn
            color="primary"
            @click="desvincularPerfilRegua(perfilContrato, perfilCliente)"
            v-if="editaRegua"
            :loading="desvinculandoPerfil"
            text
          >
            Desvincular
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn @click="fecharDialog()" text :disabled="desvinculandoPerfil">
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            type="submit"
            :disabled="
              reguas?.length == 0 ||
              reguaSelecionada == null ||
              desvinculandoPerfil
            "
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {
      dialog: false,
      reguas: null,
      reguasFiltradas: [],
      perfilCliente: null,
      perfilContrato: null,
      reguaSelecionada: null,
      editaRegua: false,
      reguaAtual: null,
    };
  },
  methods: {
    ...mapActions("reguas", {
      vincularReguaPerfil: "vincularReguaPerfil",
      editarReguaPerfil: "editarReguaPerfil",
      desvincularPerfil: "desvincularPerfil",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    editaPerfilRegua(reguas, perfilCliente, perfilContrato) {
      if (perfilCliente == "sem_perfil") {
        perfilCliente = null;
      }
      if (perfilContrato == "sem_perfil") {
        perfilContrato = null;
      }
      reguas.filter((regua) => {
        regua.perfis.filter((perfil) => {
          if (
            regua.id_regua_desafiante > regua.id &&
            perfil.perfil_cliente == perfilCliente &&
            perfil.perfil_contrato == perfilContrato
          ) {
            this.reguaSelecionada = regua;
          } else if (
            perfil.perfil_cliente == perfilCliente &&
            perfil.perfil_contrato == perfilContrato &&
            !regua.id_regua_desafiante
          ) {
            this.reguaSelecionada = regua;
          }
        });
      });
    },
    abrirDialog(reguas, perfilCliente, perfilContrato, edita) {
      this.editaRegua = edita;
      if (this.editaRegua) {
        this.editaPerfilRegua(reguas, perfilCliente, perfilContrato);
        this.reguaAtual = this.reguaSelecionada;
      }
      this.reguas = reguas;
      this.reguasFiltradas = [];
      this.reguas.forEach((regua) => {
        if (
          (regua.id_regua_desafiante && regua.id_regua_desafiante < regua.id) ||
          regua.status != "PRODUCAO"
        ) {
          return;
        }
        this.reguasFiltradas.push({
          text: regua.nome,
          value: regua,
        });
      });
      this.perfilCliente = perfilCliente;
      this.perfilContrato = perfilContrato;
      this.dialog = true;
    },
    fecharDialog() {
      this.reguas = null;
      this.reguaSelecionada = null;
      this.perfilCliente = null;
      this.perfilContrato = null;
      this.editaRegua = false;
      this.dialog = false;
      this.reguaAtual = null;
    },
    async salvar(event) {
      event.preventDefault();
      if (!this.editaRegua) {
        let payload = {
          regua_id: this.reguaSelecionada.id,
          perfil_cliente:
            this.perfilCliente == "sem_perfil" ? null : this.perfilCliente,
          perfil_contrato:
            this.perfilContrato == "sem_perfil" ? null : this.perfilContrato,
        };
        await this.vincularReguaPerfil(payload).then((retorno) => {
          this.showSnackBar({
            text: `Régua vinculada com sucesso!`,
          });
          this.dialog = false;
          this.reguaSelecionada.perfis.push(retorno.data);
          if (this.reguaSelecionada.id_regua_desafiante) {
            let reguaB = this.getReguaById(
              this.reguaSelecionada.id_regua_desafiante
            );
            reguaB.perfis.push(retorno.data);
          }
          this.reguaSelecionada = null;
        });
      } else {
        let payload = {
          regua_atual: this.reguaAtual.id,
          regua_id: this.reguaSelecionada.id,
          perfil_cliente:
            this.perfilCliente == "sem_perfil" ? null : this.perfilCliente,
          perfil_contrato:
            this.perfilContrato == "sem_perfil" ? null : this.perfilContrato,
        };
        await this.editarReguaPerfil(payload)
          .then((retorno) => {
            this.dialog = false;
            this.showSnackBar({
              text: `Régua alterada para o perfil!`,
            });

            if (this.reguaAtual.id_regua_desafiante) {
              let reguaB = this.getReguaById(
                this.reguaAtual.id_regua_desafiante
              );
              let index = reguaB.perfis.findIndex(
                (perfil) => perfil.id == retorno.data.id
              );
              reguaB.perfis.splice(index, 1);
            }
            //exclui o perfil da régua atual
            let index = this.reguaAtual.perfis.findIndex(
              (perfil) => perfil.id == retorno.data.perfil_antigo.id
            );
            this.reguaAtual.perfis.splice(index, 1);

            if (this.reguaSelecionada.id_regua_desafiante) {
              let reguaB = this.getReguaById(
                this.reguaSelecionada.id_regua_desafiante
              );
              reguaB.perfis.push(retorno.data);
            }
            //adiciona o perfil na nova régua
            this.reguaSelecionada.perfis.push(retorno.data.perfil_novo);

            this.reguaAtual = null;
            this.reguaSelecionada = null;
          })
          .catch((error) => {
            console.error(error);
            this.showSnackBar({
              text: `Erro ao alterar a régua!`,
            });
          });
      }
    },

    async desvincularPerfilRegua(perfilContrato, perfilCliente) {
      if (perfilCliente == "sem_perfil") {
        perfilCliente = null;
      }
      if (perfilContrato == "sem_perfil") {
        perfilContrato = null;
      }

      let reguaSelecionada = this.reguaSelecionada.perfis.filter(
        (perfil) =>
          perfil.perfil_cliente === perfilCliente &&
          perfil.perfil_contrato === perfilContrato
      );

      let payload = {
        regua: this.reguaSelecionada,
        perfis: reguaSelecionada,
        empresa_id: this.empresaSelecionada.id,
      };
      try {
        let retorno = await this.desvincularPerfil(payload);
        if (this.reguaSelecionada.id_regua_desafiante) {
          let reguaB = this.getReguaById(
            this.reguaSelecionada.id_regua_desafiante
          );
          let index = reguaB.perfis.findIndex(
            (perfil) => perfil.id == retorno[0]
          );
          reguaB.perfis.splice(index, 1);
        }
        let index = this.reguaSelecionada.perfis.findIndex(
          (perfil) => perfil.id == retorno[0]
        );
        this.reguaSelecionada.perfis.splice(index, 1);
        this.dialog = false;
        this.showSnackBar({
          text: `Perfil desvinculado com sucesso!`,
        });
        this.reguaSelecionada = null;
      } catch (e) {
        console.error(e);
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("reguas", {
      getReguaById: "getReguaById",
      desvinculandoPerfil: "desvinculandoPerfil",
      erroDesvincularPerfil: "erroDesvincularPerfil",
    }),
  },
};
</script>

<style scoped lang="scss"></style>
