<template>
  <div>
    <v-dialog
      v-model="dialogEmail"
      width="700"
      @click:outside="fecharDialog()"
      scrollable
    >
      <v-card>
        <div class="d-flex">
          <v-card-title>Emails para Faturamento</v-card-title>
          <v-spacer></v-spacer>
          <v-btn
            text
            color="primary"
            class="mt-4 mr-2"
            @click="adicionarEmail()"
            v-if="checkCredentials(['admin', 'editor_fatura'])"
            >Adicionar</v-btn
          >
        </div>
        <v-card-text class="card-text" id="form">
          <v-list
            v-for="(email, index) in emailsCobranca"
            :key="index"
            class="pa-0"
          >
            <v-list-item
              @mouseover="selecionaItem(email)"
              @mouseleave="desselecionarItem()"
              class="pa-0"
            >
              <v-list-item-content class="d-flex">
                <v-list-item-title>
                  <v-row>
                    <v-col cols="4">
                      <p class="nome mb-0">
                        {{ email.nome }}
                      </p>
                    </v-col>
                    <v-col>{{ email.email }}</v-col>
                  </v-row>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action class="ma-0 mr-2 menu">
                <v-menu
                  v-if="
                    email === itemSelecionado &&
                    checkCredentials(['admin', 'editor_fatura'])
                  "
                  v-model="menuAberto"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn icon v-on="on" class="ma-0">
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list>
                    <v-list-item @click="removerEmailCobranca(index)">
                      <v-list-item-title> Remover E-mail </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
            <v-divider v-if="index < emailsCobranca?.length - 1"></v-divider>
          </v-list>
          <v-form ref="form">
            <v-row
              class="mt-1"
              v-for="(novoEmail, index) in arrayEmails"
              :key="'A' + index"
            >
              <v-col cols="4">
                <v-text-field
                  outlined
                  label="Nome*"
                  autofocus
                  v-model="novoEmail.nome"
                  required
                  :rules="[rules.required]"
                >
                </v-text-field>
              </v-col>
              <v-col cols="5">
                <v-text-field
                  outlined
                  label="E-mail*"
                  v-model="novoEmail.email"
                  required
                  :rules="[rules.required, rules.email]"
                ></v-text-field>
              </v-col>
              <v-col>
                <v-checkbox
                  label="Interno"
                  v-model="novoEmail.interno"
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-btn
                  icon
                  class="mt-4"
                  small
                  @click="removeFormNovoEmail(index)"
                >
                  <v-icon dense>mdi-close</v-icon>
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>

        <v-card-actions v-if="checkCredentials(['admin', 'editor_fatura'])">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> cancelar </v-btn>
          <v-btn
            depressed
            color="primary"
            @click="criaOuEditaEmailCobranca($event)"
            :disabled="!checkCredentials(['admin', 'editor_fatura'])"
          >
            salvar
          </v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> Fechar </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";

export default {
  data() {
    return {
      rules: {
        required: (value) => !!value || "Campo obrigatório",
        email: (value) => /.+@.+\..+/.test(value) || "O e-mail deve ser válido",
      },
      dialogEmail: false,
      emailsCobranca: null,
      menuAberto: false,
      itemSelecionado: false,
      arrayEmails: [],
    };
  },

  methods: {
    checkCredentials,
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    ...mapActions("empresa", {
      editaEmailsCobranca: "editaEmailsCobranca",
    }),
    scrollDown() {
      setTimeout(() => {
        let objDiv = document.getElementById("form");
        if (objDiv) objDiv.scrollTop = objDiv.scrollHeight;
      });
    },
    selecionaItem(item) {
      if (!this.menuAberto) this.itemSelecionado = item;
    },
    desselecionarItem() {
      if (!this.menuAberto) this.itemSelecionado = false;
    },
    abrirDialog(emails) {
      this.emailsCobranca = emails || [];
      this.dialogEmail = true;
    },
    fecharDialog() {
      this.dialogEmail = false;
      this.arrayEmails = [];
      this.$refs.form.reset();
    },
    async criaOuEditaEmailCobranca(event) {
      event.preventDefault();
      if (!this.$refs.form.validate()) return;
      if (this.arrayEmails?.length > 0) {
        this.arrayEmails.forEach((email) => {
          this.emailsCobranca.push(email);
        });
      }
      await this.editaEmailsCobranca(this.emailsCobranca)
        .then(() => {
          this.$emit("recarregarEmailsCobranca");
          this.dialogEmail = false;
          this.showSnackBar({
            text: `Salvo com sucesso!`,
          });
          this.arrayEmails = [];
        })
        .catch((error) => {
          console.error(error);
          this.showSnackBar({
            text: `Erro ao salvar!`,
          });
        });
    },
    adicionarEmail() {
      this.arrayEmails.push({
        nome: null,
        email: null,
        interno: false,
      });
      this.scrollDown();
    },
    removeFormNovoEmail(index) {
      this.arrayEmails.splice(index, 1);
    },
    removerEmailCobranca(index) {
      this.emailsCobranca.splice(index, 1);
      this.menuAberto = false;
      this.itemSelecionado = false;
    },
  },
};
</script>

<style scoped lang="scss">
.nome {
  white-space: nowrap !important;
  width: 160px !important;
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
.menu {
  width: 20px;
}
.card-text {
  height: 60vh;
}
::-webkit-scrollbar {
  width: 4px;
  margin-left: 1px;
}
::-webkit-scrollbar-track {
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
</style>
