<template>
  <v-card v-if="evidencia" elevation="3" class="push-card">
    <a
      :href="evidencia?.link ?? evento?.push_link"
      target="_blank"
      rel="noopener noreferrer"
      class="push-link"
    >
      <div class="imagem">
        <img width="44" :src="evidencia?.icone ?? evento?.push_imagem" alt="Ícone" />
      </div>
      <div class="texto">
        <p>{{ evidencia?.titulo ?? evento?.push_titulo }}</p>
        <p>{{ evidencia?.corpo ?? evento?.push_corpo }}</p>
      </div>
    </a>
  </v-card>
</template>

<script>
export default {
  props: ["evidencia"],
  computed: {
    evento() {
      return this.evidencia?.evento;
    },
    imagem() {
      return this.evidencia?.push_imagem ?? this.evidencia?.evento?.push_imagem;
    },
    titulo() {
      return this.evidencia?.push_titulo ?? this.evidencia?.evento?.push_titulo;
    },
    corpo() {
      return this.evidencia?.push_corpo ?? this.evidencia?.evento?.push_corpo;
    },
  },
};
</script>

<style scoped lang="scss">
.push-card {
  width: 100%;
  border-radius: 10px;
  padding: 20px;
  margin: auto;
}
.push-link {
  display: flex;
  flex-direction: row;
  align-items: center;
  text-decoration: none;
  color: #282828;
  cursor: unset;
  .imagem img {
    margin-right: 20px;
    vertical-align: middle;
  }
  .texto p {
    &:first-child {
      margin-bottom: 5px;
    }
    &:last-child {
      margin-bottom: 0px;
    }
  }
}
</style>