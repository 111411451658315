<template>
  <v-dialog v-model="dialog" :width="650" persistent>
    <v-form @submit="salvarRegua" ref="form" lazy-validation>
      <v-card>
        <v-card-title class="text-h5 title-card">
          {{ retornaCardTitle(regua.id, regua.tipo) }}
          <v-spacer></v-spacer>
          <v-chip
            v-if="regua.tipo == 'MOVIMENTACAO'"
            color="green lighten-2"
            class="black--text font-weight-medium ml-4"
          >
            Movimentação
          </v-chip>
          <v-chip
            v-else
            color="blue lighten-2"
            class="black--text font-weight-medium ml-4"
          >
            Pré-definidas
          </v-chip>
        </v-card-title>
        <v-card-text class="pt-6">
          <v-text-field
            v-model="regua.nome"
            label="Nome"
            outlined
            autofocus
            required
            :rules="[rules.required]"
          ></v-text-field>
          <v-textarea
            label="Observação"
            v-model="regua.observacao"
            rows="2"
            outlined
            hide-details
          ></v-textarea>
          <div>
            <v-switch
              hide-details
              label="Clonar Eventos"
              v-model="clonarEventos"
              v-if="!edit"
              class="font-weight-bold"
              style="width: fit-content !important"
            ></v-switch>
            <v-select
              hide-details
              outlined
              label="Régua"
              v-if="clonarEventos"
              no-data-text="Nenhuma régua ativa"
              class="mt-4"
              :items="reguasFiltradasTipo"
              v-model="selectReguasTipo"
            ></v-select>
          </div>
          <div class="d-flex" v-if="regua.id_regua_desafiante || !edit">
            <v-switch
              hide-details
              label="Teste A/B"
              v-model="testeAB"
              :disabled="edit ? true : false"
              class="font-weight-bold"
            ></v-switch>
            <v-spacer></v-spacer>

            <div class="text-center" v-if="testeAB">
              <p class="mt-5 mr-6 font-weight-bold" style="font-size: 20px">
                Amostra
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon v-bind="attrs" v-on="on">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  <span>6º e 7º dígitos do CPF</span>
                </v-tooltip>
              </p>
              <div class="d-flex">
                <v-text-field
                  type="number"
                  min="0"
                  dense
                  outlined
                  label="Min"
                  v-model="testeA.digito_verificador_inicio"
                  :rules="[rules.required]"
                  color="deep-purple accent-2"
                  class="mr-2"
                  style="max-width: 110px"
                >
                </v-text-field>
                <v-text-field
                  type="number"
                  min="0"
                  dense
                  outlined
                  label="Máx"
                  v-model="testeA.digito_verificador_fim"
                  :rules="[rules.required]"
                  color="deep-purple accent-2"
                  class="ml-2"
                  style="max-width: 110px"
                >
                </v-text-field>
              </div>
              <div class="d-flex" v-if="!regua.id_regua_desafiante">
                <v-text-field
                  type="number"
                  min="0"
                  dense
                  outlined
                  label="Min"
                  color="deep-purple accent-2"
                  v-model="testeB.digito_verificador_inicio"
                  :rules="[rules.required]"
                  class="mr-2"
                  style="max-width: 110px"
                >
                </v-text-field>
                <v-text-field
                  type="number"
                  min="0"
                  max="99"
                  dense
                  outlined
                  v-model="testeB.digito_verificador_fim"
                  :rules="[rules.required]"
                  label="Máx"
                  color="deep-purple accent-2"
                  class="ml-2"
                  style="max-width: 110px"
                >
                </v-text-field>
              </div>
            </div>
          </div>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="fecharDialog()"> cancelar </v-btn>
          <v-btn color="primary" type="submit" :loading="salvando">
            salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import reguaService from "@/services/regua";
import Vue from "vue";

export default {
  props: {
    tituloMovimentacao: "",
  },
  data() {
    return {
      selectReguasTipo: undefined,
      clonarEventos: false,
      reguasFiltradasTipo: [],
      editarTipoRegua: false,
      salvando: false,
      edit: false,
      dialog: false,
      rules: {
        required: (value) => !!value || "Campo obrigatório.",
      },
      regua: {
        type: null,
        nome: null,
        observacao: null,
        tipo: null,
      },
      testeAB: false,
      testeB: {
        digito_verificador_inicio: null,
        digito_verificador_fim: null,
        tipo_teste_ab: 'B'

      },
      testeA: {
        digito_verificador_inicio: null,
        digito_verificador_fim: null,
        tipo_teste_ab: 'A'

      },
      perfilContrato: [
        {
          text: "Nenhum",
          value: null,
        },
      ],
    };
  },

  methods: {
    ...mapActions("reguas", {
      createOrEditRegua: "saveRegua",
    }),
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),
    abrirNovaRegua(type, perfil_cliente, perfil_contrato) {
      this.filtraReguaPorTipo(type);
      if (perfil_cliente == "sem_perfil") {
        perfil_cliente = null;
      }

      if (perfil_contrato == "sem_perfil") {
        perfil_contrato = null;
      }

      if (type == null) this.editarTipoRegua = true;
      this.dialog = true;
      this.edit = false;
      this.regua = {
        tipo: type || null,
        regua_id: null,
        nome: null,
        observacao: null,
        status: "RASCUNHO",
        empresa_id: this.empresaSelecionada.id,
        perfil_cliente: perfil_cliente,
        perfil_contrato: perfil_contrato,
      };
    },
    abrirEditRegua(regua) {
      this.editarTipoRegua = true;
      this.edit = true;
      this.regua = JSON.parse(JSON.stringify(regua));
      this.dialog = true;
      this.testeA.digito_verificador_inicio = regua.digito_verificador_inicio;
      this.testeA.digito_verificador_fim = regua.digito_verificador_fim;
      if (regua.id_regua_desafiante) {
        this.testeAB = true;
      }
    },

    filtraReguaPorTipo(tipo){
      this.reguasFiltradasTipo = [];
      this.listaReguas.objects.forEach((regua) => {
        if(regua.tipo == tipo && regua.status != "ARQUIVADA" && !regua.id_regua_desafiante) {
          this.reguasFiltradasTipo.push({
            text: regua.nome,
            value: regua,
          });
        }
      });
    },
    async salvarRegua(evento) {
      evento.preventDefault();
      const isValid = this.$refs.form.validate();
      if (!isValid) return;
      if (this.salvando) {
        return;
      }

      this.salvando = true;
      let reguaDesafiante = this.getReguaById(this.regua.id_regua_desafiante);

      if (this.regua.id && reguaDesafiante) {
        if (
          this.regua.id < reguaDesafiante.id &&
          this.testeA.digito_verificador_fim >=
            reguaDesafiante.digito_verificador_inicio
        ) {
          this.showSnackBar({
            text: "Digito verificador fim da régua A não pode ser maior ou igual ao da régua desafiante.",
          });
          this.salvando = false;
          return;
        } else if (
          this.regua.id > reguaDesafiante.id &&
          this.testeA.digito_verificador_inicio <=
            reguaDesafiante.digito_verificador_fim
        ) {
          this.showSnackBar({
            text: "Digito verificador início da régua B não pode ser menor ou igual ao da régua desafiante.",
          });
          this.salvando = false;
          return;
        }
      }
      let payload = {
        empresa_id: this.regua.empresa_id,
        id: this.regua.id,
        nome: this.regua.nome,
        observacao: this.regua.observacao,
        ordem_exibicao: this.regua.ordem_exibicao,
        ordem: this.regua.ordem,
        status: this.regua.status,
        tipo: this.regua.tipo,
        digito_verificador_inicio: this.testeA.digito_verificador_inicio,
        digito_verificador_fim: this.testeA.digito_verificador_fim,
        clonar_regua: this.selectReguasTipo?.id,
        tipo_movimentacao_id: this.$route.query.tipo_movimentacao_id
      };

      if (this.testeAB && payload.id == null) {
        let testeA = { ...payload, ...this.testeA };
        let testeB = { ...payload, ...this.testeB };
        let testes = [testeA, testeB];
        if (testeB.digito_verificador_inicio < testeA.digito_verificador_fim) {
          this.showSnackBar({
            text: "O máximo do primeiro digito verificador não pode ser maior que o minímo do segundo!",
          });
        }
        try {
          const resp = await reguaService.criaTesteAB(testes);
          this.$emit("addOrUpdateRegua", resp.data);
          this.fecharDialog();
        } catch (e) {
          console.error(e);
          this.showSnackBar({
            text: "Erro ao salvar a régua",
          });
        } finally {
          this.salvando = false;
        }
        return;
      }
      try {
        this.chamadaSalvarRegua(payload);
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao salvar a régua",
        });
      }
    },
    fecharDialog() {
      this.dialog = false;
      this.testeAB = false;
      this.regua.nome = null;
      this.regua.observacao = null;
      this.editarTipoRegua = false;
      this.testeA = {};
      this.testeB = {};
      this.edit = false;
      this.selectReguasTipo = null;
      this.clonarEventos = false;
    },

    async chamadaSalvarRegua(payload) {
      try {
        const { data } = await this.createOrEditRegua(payload);
        this.$emit("addOrUpdateRegua", data);
        this.showSnackBar({
          text: `Régua ${this.edit ? "editada" : "criada"} com sucesso`,
        });

        this.fecharDialog();
      } catch (e) {
        this.showSnackBar({
          text: `Ocorreu um erro ao ${this.edit ? "editar" : "criar"} a regua`,
        });
        console.error(e.message);
      } finally {
        this.salvando = false;
      }
    },

    verificaPerfilCliente(perfil_cliente) {
      if (perfil_cliente == "Novo cliente") {
        return "novo_cliente";
      } else if (perfil_cliente == "Paga adiantado") {
        return "paga_adiantado";
      } else if (perfil_cliente == "Paga em dia") {
        return "paga_em_dia";
      } else if (perfil_cliente == "Paga atrasado") {
        return "paga_atrasado";
      } else {
        return "sem_perfil";
      }
    },
    retornaCardTitle(reguaId, tipoRegua) {
      if (tipoRegua == "MOVIMENTACAO") {
        if (reguaId) {
          return `Editar Régua de ${this.tituloMovimentacao}`;
        } else {
          return `Nova Régua de ${this.tituloMovimentacao}`;
        }
      } else {
        if (reguaId) {
          return `Editar Régua de ${Vue.filter("tipoRegua")(tipoRegua)}`;
        } else {
          return `Nova Régua de ${Vue.filter("tipoRegua")(tipoRegua)}`;
        }
      }
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
    ...mapGetters("reguas", {
      listaReguas: "reguas",
      getReguaById: "getReguaById",
    }),

    perfisCliente() {
      let perfis = [
        {
          text: "Nenhum",
          value: null,
        },
      ];

      let perfisRegua = this.empresaSelecionada.perfis_regua || {};
      let perfisCliente = perfisRegua.perfis_cliente || [];

      perfisCliente.forEach((perfilCliente) => {
        let nome = Object.keys(perfilCliente)[0];

        perfis.push({
          text: perfilCliente[nome],
          value: nome,
        });
      });

      return perfis;
    },

    perfisContrato() {
      let perfis = [
        {
          text: "Nenhum",
          value: null,
        },
      ];

      let perfisRegua = this.empresaSelecionada.perfis_regua || {};
      let perfisContrato = perfisRegua.perfis_contrato || [];

      perfisContrato.forEach((perfilContrato) => {
        let nome = Object.keys(perfilContrato)[0];

        perfis.push({
          text: perfilContrato[nome],
          value: nome,
        });
      });

      return perfis;
    },
  },
};
</script>

<style scoped lang="scss">
</style>
