<template>
  <div>
    <!-- Barra superior -->
    <app-bar @showDisabled="showDisabled = $event" />
    <!-- Erro ao carregar -->
    <alert-error :show="erroCarregarEventos" @reload="carregarEventosRegua()" />
    <!-- Loading -->
    <div v-if="loading" class="d-flex justify-center mt-16 pt-16">
      <v-progress-circular :size="70" indeterminate color="primary" />
    </div>
    <!-- Timeline -->
    <v-fade-transition>
      <TimelineEstrutura
        :events="eventos"
        :showDisabled="showDisabled"
        @stop="pararEvento($event)"
        @start="rodarEvento($event)"
        @open="openEventDialog($event)"
        v-if="!erroCarregarEventos && !loading"
        @disabled="disabledEvent($event)"
      />
    </v-fade-transition>

    <FloatingMultipleBtn v-if="checkCredentials(['admin', 'editor_regua'])">
      <template v-slot:content>
        <v-chip
          v-for="evento in [...TIPO_EVENTO].reverse()"
          :key="evento"
          class="mx-1 text-subtitle-2 tipo-evento"
          :class="evento"
          @click="openEventDialog({ tipo: evento, dia: 0 })"
        >
          {{ evento | tipoEvento }}
        </v-chip>
      </template>
    </FloatingMultipleBtn>

    <PromiseBasedDialog
      ref="promiseBasedDialog"
      :options="promiseBasedDialogOptions"
    />
    <!-- Dialog Event -->
    <DialogEmail :dialog.sync="dialogEmail" :event="event" @edit="editEvent" />
    <DialogSms :dialog.sync="dialogSms" :event="event" @edit="editEvent" />
    <DialogPush :dialog.sync="dialogPush" :event="event" @edit="editEvent" />
    <DialogLigacao
      :dialog.sync="dialogLigacao"
      :event="event"
      @edit="editEvent"
    />
    <DialogComando
      :dialog.sync="dialogComando"
      :event="event"
      @edit="editEvent"
    />
    <DialogWhatsapp
      :dialog.sync="dialogWhatsapp"
      :event="event"
      @edit="editEvent"
    />
    <DialogRcs :dialog.sync="dialogRcs" :event="event" @edit="editEvent" />
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { checkCredentials } from "@/assets/js/utils";
import { TIPO_EVENTO } from "@/store/enum";

// Serviços
import serviceEventos from "@/services/eventos";

// Componentes
import AppBar from "../components/AppBar";
import AlertError from "../components/AlertError";
import DialogWhatsapp from "../components/dialogs/dialogsEvento/DialogWhatsapp.vue";
import TimelineEstrutura from "../components/timeline/TimelineEstrutura";
import PromiseBasedDialog from "@/components/dialogs/promiseBasedDialog";
import { mapGetters } from "vuex";

import DialogEmail from "../components/dialogs/dialogsEvento/DialogEmail.vue";
import DialogSms from "../components/dialogs/dialogsEvento/DialogSms.vue";
import DialogPush from "../components/dialogs/dialogsEvento/DialogPush.vue";
import DialogComando from "../components/dialogs/dialogsEvento/DialogComando.vue";
import DialogLigacao from "../components/dialogs/dialogsEvento/DialogLigacao.vue";
import DialogRcs from "../components/dialogs/dialogsEvento/DialogRcs.vue";
import FloatingMultipleBtn from "../../../components/comum/FloatingMultipleBtn.vue";

export default {
  name: "Ruler",
  components: {
    AppBar,
    AlertError,
    TimelineEstrutura,
    PromiseBasedDialog,
    DialogWhatsapp,
    DialogEmail,
    DialogSms,
    DialogPush,
    DialogComando,
    DialogLigacao,
    DialogRcs,
    FloatingMultipleBtn,
  },
  data: () => ({
    TIPO_EVENTO,
    loading: false,
    showDisabled: false,
    erroCarregarEventos: false,
    eventos: [],
    event: {},
    promiseBasedDialogOptions: {},
    itemsCampaha: [],
    dialogWhatsapp: false,
    dialogEmail: false,
    dialogSms: false,
    dialogPush: false,
    dialogComando: false,
    dialogLigacao: false,
    dialogRcs: false,
  }),

  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  watch: {
    "$route.params.regua_id": {
      handler: function (oldValue, newValue) {
        if (oldValue !== newValue) {
          this.carregarEventosRegua();
        }
      },
      deep: true,
      immediate: true,
    },
    empresaSelecionada: {
      handler() {
        this.$router.push({ name: "Reguas" });
      },
    },
    dialogEmail(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogWhatsapp(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogSms(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogPush(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogComando(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogLigacao(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
    dialogRcs(newValue) {
      if (!newValue && this.$route.query.evento_id) {
        this.$router.replace({ query: {} });
      }
    },
  },

  async created() {
    await this.carregarEventosRegua();
    if (this.$route.query.evento_id) {
      const eventoDaUrl = this.eventos.find(
        (evento) => evento.id == this.$route.query.evento_id
      );
      this.openEventDialog(eventoDaUrl);
    }
  },

  methods: {
    checkCredentials,
    ...mapActions("snackbar", {
      showSnackBar: "showSnackBar",
    }),

    ...mapActions("evento", {
      salvarEvento: "salvarEvento",
    }),

    async pararEvento(evento) {
      this.promiseBasedDialogOptions = {
        cardText:
          "Deseja " +
          (evento.status == "ARQUIVADA" ? "ativar" : "parar") +
          " este evento?",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const decision = await this.$refs.promiseBasedDialog.openDialog();
      if (!decision) return;
      const payload = {
        id: evento.id,
        status: "RASCUNHO",
      };
      try {
        const response = await this.salvarEvento(payload);
        this.showSnackBar({
          text:
            "Evento " +
            (evento.status == "ARQUIVADA" ? "ativado" : "parado") +
            " com sucesso!",
        });
        evento.status = response.status;
      } catch (e) {
        this.showSnackBar({
          text:
            "Erro ao " +
            (evento.status == "ARQUIVADA" ? "ativar" : "parar") +
            " o evento!",
        });
        console.error(e);
      }
    },

    async rodarEvento(evento) {
      this.promiseBasedDialogOptions = {
        cardText: "Deseja colocar este evento em produção?",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const data = await this.$refs.promiseBasedDialog.openDialog();
      if (!data) return;
      let payload = {
        id: evento.id,
        status: "PRODUCAO",
      };
      try {
        const response = await this.salvarEvento(payload);
        evento.status = response.status;
        this.showSnackBar({
          text: "Evento alterado para produção",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao colocar o evento em produção!",
        });
      }
    },

    async disabledEvent(evento) {
      this.promiseBasedDialogOptions = {
        cardText: "Deseja desativar este evento?",
        cardTitle: null,
        acceptBtn: "prosseguir",
        declineBtn: "cancelar",
        width: "300px",
      };
      const data = await this.$refs.promiseBasedDialog.openDialog();
      if (!data) return;
      let payload = {
        id: evento.id,
        status: "ARQUIVADA",
      };
      try {
        const response = await this.salvarEvento(payload);
        evento.status = response.status;
        this.showSnackBar({
          text: "Evento desativado com sucesso!",
        });
      } catch (e) {
        this.showSnackBar({
          text: "Erro ao desativar o evento!",
        });
      }
    },

    async carregarEventosRegua() {
      this.loading = true;
      this.erroCarregarEventos = false;
      let payload = {
        empresa_id: this.$store.state.empresa.empresaSelecionada.id,
        limite: 1000,
        regua_id: this.$route.params.regua_id,
        ordem: "dia",
      };

      return serviceEventos
        .get(payload)
        .then(({ data }) => {
          this.eventos = data.objects;
        })
        .catch((error) => {
          console.error(error);
          this.erroCarregarEventos = true;
        })
        .finally(() => (this.loading = false));
    },

    novoEvento(tipo, day = null) {
      this.$router.push({
        name: "Evento",
        query: {
          tipo: tipo,
          regua_id: this.$route.params.regua_id,
        },
        params: {
          day: day,
        },
      });
    },

    openEventDialog(event) {
      if (event.tipo == "WHATSAPP") {
        this.dialogWhatsapp = true;
        this.event = event;
      } else if (event.tipo == "EMAIL") {
        this.dialogEmail = true;
        this.event = event;
      } else if (event.tipo == "SMS") {
        this.dialogSms = true;
        this.event = event;
      } else if (event.tipo == "PUSH") {
        this.dialogPush = true;
        this.event = event;
      } else if (event.tipo == "COMANDO") {
        this.dialogComando = true;
        this.event = event;
      } else if (event.tipo.includes("LIGACAO")) {
        this.dialogLigacao = true;
        this.event = event;
      } else if (event.tipo == "RCS") {
        this.dialogRcs = true;
        this.event = event;
      }
      this.$router.replace({ query: { evento_id: event.id } });
    },

    editEvent(event) {
      const newEvents = [...this.eventos];
      const index = newEvents.map((e) => e.id).indexOf(event.id);

      if (index > -1) {
        newEvents[index] = event;
      } else {
        newEvents.push(event);
      }

      this.eventos = newEvents;
    },
  },
};
</script>
