<template>
  <v-container>
    <div>
      <v-app-bar app>
        <v-toolbar-title>Carteira de Clientes</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-menu offset-y transition="scale-transition">
          <template v-slot:activator="{ on, attrs }">
            <v-card
              style="background-color: #e0e0e0; height: 40px"
              class="d-flex align-center ml-4 pa-2 pl-4"
              v-on="on"
              v-bind="attrs"
              v-model="dataConsolidacaoFormatada"
            >
              {{ dataConsolidacaoFormatada }}
              <v-btn icon small class="ml-1">
                <v-icon small>mdi-pencil</v-icon>
              </v-btn>
            </v-card>
          </template>
          <v-date-picker
            locale="pt-br"
            v-model="dataConsolidacao"
            @change="carregaTotal(dataConsolidacao)"
          >
            <v-btn
              class="d-flex mx-auto"
              color="primary"
              text
              @click="carregaTotal('ultima_atualizacao')"
            >
              Última consolidação
            </v-btn>
          </v-date-picker>
        </v-menu>
      </v-app-bar>
      <v-row class="justify-center mb-5">
        <v-col class="pa-0 pt-10" style="flex-grow: 0">
          <v-row class="ma-0">
            <v-card
              style="width: 190px; height: 80px"
              class="rounded-lg mb-7 mr-2"
            >
              <div v-if="!carregandoTotais">
                <v-card-title
                  class="font-weight-bold textos-ml justify-center pt-2"
                >
                  Contratos Ativos
                </v-card-title>
                <v-card-subtitle
                  class="font-weight-medium text-center text-h5 pb-2"
                >
                  <v-icon class="mr-1 pb-1">mdi-database</v-icon
                  >{{ contratosAtivos | numero }}
                </v-card-subtitle>
              </div>
              <div v-if="carregandoTotais" class="ml-carregando">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-card>
            <v-card style="width: 225px; height: 80px" class="rounded-lg mb-7">
              <div v-if="!carregandoTotais">
                <v-card-title
                  class="font-weight-bold textos-ml justify-center pt-2"
                >
                  Valor Carteira
                </v-card-title>
                <v-card-subtitle
                  class="font-weight-medium text-center text-h6 pb-2"
                >
                  {{ valorCarteira | fomatadorReal }}
                </v-card-subtitle>
              </div>
              <div v-if="carregandoTotais" class="ml-carregando">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
              </div>
            </v-card>
          </v-row>
          <ContratosPorOrigem
            ref="contratosPorOrigem"
            :dataSelecionada="this.$route.query.data_referencia"
            :dataReferencia="dataConsolidacao"
          />
        </v-col>

        <v-col class="pa-0 pt-10 ma-0 d-flex flex-column" style="flex-grow: 0">
          <ContratosPorTipo
            ref="contratosPorTipo"
            :dataSelecionada="this.$route.query.data_referencia"
          />
          <ContratosPorRisco
            ref="contratosPorRisco"
            :dataSelecionada="this.$route.query.data_referencia"
          />
          <ContratosPorCanal
            ref="contratosPorCanal"
            :dataSelecionada="this.$route.query.data_referencia"
          />
        </v-col>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import dashboardService from "@/services/dashboards";
import { mapGetters } from "vuex";

import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart from "vue-echarts";

// import componentes dos graficos
import ContratosPorTipo from "../carteira/componentes/ContratoPorTipo.vue";
import ContratosPorRisco from "../carteira/componentes/ContratoPorRisco.vue";
import ContratosPorCanal from "./componentes/ContratoPorCanal.vue";
import ContratosPorOrigem from "../carteira/componentes/ContratoPorOrigem.vue";
// import ContratosPorDimensao from "./componentes/ContratoPorDimensao.vue";
// import ContratosPorPlano from "../carteira/componentes/ContratoPorPlano.vue";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);

export default {
  components: {
    VChart,
    ContratosPorTipo,
    ContratosPorRisco,
    ContratosPorCanal,
    ContratosPorOrigem,
    // ContratosPorDimensao,
    // ContratosPorPlano,
  },
  data: () => ({
    dataConsolidacao: null,
    dataConsolidacaoFormatada: null,
    contratosAtivos: 0,
    valorCarteira: 0,

    carregandoTotais: false,
  }),
  async created() {
    if (Object.keys(this.$route.query).length == 0) {
      await this.carregaTotal("ultima_atualizacao");
    } else {
      this.dataConsolidacao = this.$route.query.data_referencia;
      await this.carregaTotal(this.$route.query.data_referencia);
    }
  },
  methods: {
    formataData(data) {
      if (!data) return null;
      const [ano, mes, dia] = data.split("-");
      return `${dia}/${mes}/${ano}`;
    },

    async carregaTotal(dataReferencia) {
      this.carregandoTotais = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        data_referencia: dataReferencia,
      };
      try {
        const { data } = await dashboardService.getTotal(payload);
        if (dataReferencia == "ultima_atualizacao") {
          this.dataConsolidacao = data?.ultima_atualizacao;
        }
        this.contratosAtivos = data?.total?.ativos;
        this.valorCarteira = data?.total?.valor_carteira;
        this.carregandoTotais = false;
      } catch {
        this.carregandoTotais = false;
      }
    },

    alteraUrlParam(data) {
      this.$router.push({ query: { data_referencia: data } }).catch(() => {});
    },
  },
  watch: {
    dataConsolidacao() {
      this.alteraUrlParam(this.dataConsolidacao);
      this.dataConsolidacaoFormatada = this.formataData(this.dataConsolidacao);
      if (!this.dataConsolidacao) return;
    },

    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregaTotal(this.dataConsolidacao);
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>

<style scoped lang="scss">
.textos-ml {
  word-break: break-word;
}

.tamanhoCardGrafico {
  height: 260px;
  width: 400px;
}

.chart {
  height: 230px;
  width: 350px;
}

.bar-chart {
  height: 540px;
  width: 450px;
}

:deep(.v-input) {
  flex: none !important;
}
:deep(.v-date-picker-table) {
  height: 210px !important;
}
</style>
