<template>
  <div v-click-outside="() => setSelectedDash()">
    <div class="gap-2 flex flex-col">
      <div
        v-for="(item, index) in value"
        :key="index"
        class="p-2 text-sm elevation-2 dashboard smooth"
        transition="scale-transition"
      >
        <v-slide-y-transition hide-on-leave>
          <div v-show="selectedDash === index" :key="index">
            <v-text-field
              dense
              outlined
              :rules="[(v) => !!v || 'Campo obrigatório']"
              @input="value[index].nome = $event"
              :value="item.nome"
              :label="'Nome'"
              class="mb-2 text-sm"
            ></v-text-field>
            <v-text-field
              dense
              outlined
              :rules="[isValidURL, isPublicURL]"
              @input="value[index].url = $event"
              :value="item.url"
              :label="'URL'"
              class="text-sm"
            ></v-text-field>
            <div class="actions flex justify-end">
              <v-btn
                @click.stop="setSelectedDash()"
                x-small
                :disabled="!canInclude"
                text
                class="mt-2"
                >incluir</v-btn
              >
              <v-btn
                @click.stop="removeDashBoard(index)"
                x-small
                text
                class="mt-2"
                >remover</v-btn
              >
            </div>
          </div>
        </v-slide-y-transition>

        <v-slide-y-reverse-transition hide-on-leave>
          <div
            @click="setSelectedDash(index)"
            v-show="selectedDash != index"
            class="flex flex-col cursor-pointer"
          >
            <span class="text-lg">{{ item.nome }}</span>
            <span
              class="text-xs whitespace-nowrap overflow-ellipsis overflow-hidden text-primary"
              >{{ item.url }}</span
            >
          </div>
        </v-slide-y-reverse-transition>
      </div>
    </div>
    <div class="flex justify-end">
      <v-btn
        :disabled="selectedDash !== null"
        @click="adicionarDashBoard"
        small
        text
        color="primary"
        class="mt-4"
        ><span class="">Adicionar</span></v-btn
      >
    </div>
  </div>
</template>

<script>
import { codemirror } from "vue-codemirror";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material-darker.css";
import "codemirror/mode/javascript/javascript.js";
import "codemirror/addon/edit/closebrackets.js";
import "codemirror/addon/edit/matchbrackets.js";
import "codemirror/addon/edit/trailingspace.js";
import "codemirror/addon/selection/active-line.js";
import "codemirror/addon/lint/lint.css";
import "codemirror/addon/lint/lint.js";

export default {
  components: {
    codemirror,
  },
  model: {
    prop: "value",
    event: "input",
  },
  props: {
    cmOptions: {
      required: false,
      default: () => {
        return {
          mode: "application/json",
          lineNumbers: true,
          indentUnit: 2,
          tabSize: 2,
          indentWithTabs: false,
          autoCloseBrackets: true,
          matchBrackets: true,
          lint: true,
          gutters: ["CodeMirror-lint-markers"],
          styleActiveLine: true,
          extraKeys: {
            "Ctrl-Q": function (cm) {
              cm.foldCode(cm.getCursor());
            },
            "Ctrl-Space": "autocomplete",
          },
          foldGutter: true,
          gutters: [
            "CodeMirror-linenumbers",
            "CodeMirror-foldgutter",
            "CodeMirror-lint-markers",
          ],
        };
      },
    },
    value: {
      required: true,
    },
    attrs: {
      required: false,
    },
  },
  data() {
    return {
      selectedDash: null,
    };
  },
  methods: {
    isValidURL(str) {
      const pattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
      return pattern.test(str) || "Insira uma url válida";
    },
    isPublicURL(str) {
      const pattern = /\/public\//;
      return pattern.test(str) || "A URL deve ser um link público do Metabase.";
    },
    adicionarDashBoard() {
      this.value.push({
        id: null,
        nome: "",
        url: "",
      });
      this.selectedDash = this.value.length - 1;
    },
    removeDashBoard(index) {
      this.value.splice(index, 1);
      this.setSelectedDash(null);
    },
    setSelectedDash(dashIndex = null) {
      const error = this.someError;
      this.value.forEach((value, index) => {
        if (!value.id && !value.nome && !value.url && index != dashIndex) {
          this.value.splice(index, 1);
        }
      });
      if (error && dashIndex == null) {
        this.selectedDash = null;
        return;
      }
      this.selectedDash = dashIndex;
    },
  },
  computed: {
    canInclude() {
      return (
        this.value[this.selectedDash]?.nome &&
        this.isValidURL(this.value[this.selectedDash]?.url) === true &&
        this.isPublicURL(this.value[this.selectedDash]?.url) === true
      );
    },
    someError() {
      const campos = this.$children;
      const someError = campos.some(
        (children) => children.errorBucket?.length > 0
      );
      return someError;
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  border-radius: 4px;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
