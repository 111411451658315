<template>
  <div>
    <v-form
      @submit="pesquisar"
      class="ma-auto mt-16 pa-4 formulario text-center"
      :class="{ 'pb-0': resultadoPesquisa }"
    >
      <h1 class="mb-5 text-h5 text-center text--secondary">
        Pesquise por CPF, telefone, e-mail ou código do contrato
      </h1>

      <v-text-field
        v-model="filtros.consulta"
        hide-details
        prepend-inner-icon="mdi-magnify"
        solo
        autofocus
        required
        filled
      >
        <template v-slot:append-outer>
          <v-btn fab color="primary" small type="submit">
            <v-icon>mdi-arrow-right</v-icon>
          </v-btn>
        </template>
      </v-text-field>
      <div
        class="mt-4"
        v-if="
          !pesquisando &&
          (contratos?.objects?.length || consultaClientes?.objects?.length)
        "
      >
        <span class="grey--text">
          Resultados da pesquisa: {{ resultadoPesquisa }}
        </span>
        <v-btn icon @click="limparPesquisa()">
          <v-icon color="grey lighten-1">mdi-close</v-icon>
        </v-btn>
      </div>
    </v-form>

    <div v-if="pesquisando" class="ml-carregando">
      <v-progress-circular indeterminate color="primary"></v-progress-circular>
    </div>

    <v-alert
      v-if="!pesquisando && erroPesquisa"
      type="error"
      max-width="400"
      class="mx-auto text-center"
      text
    >
      Não foi possível pesquisar. Tente novamente.
    </v-alert>

    <div
      v-if="
        !pesquisando &&
        !erroPesquisa &&
        contratos.num_results == 0 &&
        consultaClientes?.objects?.length == 0
      "
      class="mt-4 pa-4 text-center text-h6 text--secondary font-weight-regular"
    >
      Nenhum contrato ou cliente encontrado
    </div>

    <v-card
      v-if="!pesquisando && contratos.objects.length"
      max-width="1000"
      class="ma-auto mt-4"
    >
      <v-list class="pa-0">
        <template v-for="(contrato, index) in contratos.objects">
          <v-list-item
            :to="{ name: 'VerContrato', params: { id: contrato.id } }"
            :key="'contrato-' + contrato.id"
          >
            <v-list-item-content>
              <v-row class="pt-2">
                <v-col cols="12" sm="3">
                  <div class="font-weight-bold mb-1">
                    {{ contrato.cliente.nome }}
                  </div>
                  <div class="text--secondary">
                    {{ contrato.cliente.documento | documentoCliente }}
                  </div>
                </v-col>

                <v-col cols="12" sm="7">
                  <div class="font-weight-medium mb-1">
                    {{ contrato.composicao }}
                  </div>
                  <div>
                    {{ contrato.ref_externa }}
                  </div>
                </v-col>

                <v-col cols="12" sm="2" class="text-right">
                  <div
                    class="font-weight-medium mb-1 status-contrato-fonte"
                    :class="contrato.status"
                  >
                    {{ contrato.status | statusContrato }}
                  </div>
                  <div class="text-body-2">
                    <span v-if="contrato.qtde_parcelas > 0">
                      {{ contrato.qtde_parcelas }}
                      {{ contrato.qtde_parcelas > 1 ? "parcelas" : "parcela" }}
                    </span>
                    <span v-else>Sem parcelas</span>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card>

    <v-card
      v-if="!pesquisando && consultaClientes?.objects.length"
      max-width="1000"
      class="ma-auto mt-4"
    >
      <v-list class="pa-0">
        <template v-for="(cliente, index) in consultaClientes.objects">
          <v-list-item
            :to="{ name: 'VerCliente', params: { id: cliente.id } }"
            :key="'cliente-' + cliente.id"
          >
            <v-list-item-content>
              <v-row class="pt-2">
                <v-col cols="12" sm="3">
                  <div class="font-weight-bold mb-1">
                    {{ cliente.nome }}
                  </div>
                  <div class="text--secondary">
                    {{ cliente.documento | documentoCliente }}
                  </div>
                </v-col>
                <v-col class="text-right">
                  <div
                    class="font-weight-medium mb-1 subtitle-1"
                    style="color: #716868 !important"
                  >
                    {{ cliente.contratos.length }}
                    {{
                      cliente.contratos.length == 1 ? "contrato" : "contratos"
                    }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>

          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card>

    <div
      v-if="ultimosContratosAcessados.length"
      class="mt-16 px-4 text-center text--secondary"
    >
      Últimos acessos
    </div>

    <v-card
      v-if="ultimosContratosAcessados.length"
      max-width="1000"
      class="ma-auto my-5"
    >
      <v-list class="pa-0">
        <template v-for="(logUsuario, index) in ultimosContratosAcessados">
          <v-list-item
            :to="{ name: 'VerContrato', params: { id: logUsuario.id } }"
            :key="'contrato-' + logUsuario.id"
            v-if="logUsuario.tipo == 'contrato'"
          >
            <v-list-item-content>
              <v-row class="pt-2">
                <v-col cols="12" sm="3">
                  <div class="font-weight-bold mb-1">
                    {{ logUsuario.cliente.nome }}
                  </div>
                  <div class="text--secondary">
                    {{ logUsuario.cliente.documento | documentoCliente }}
                  </div>
                </v-col>

                <v-col cols="12" sm="7">
                  <div class="font-weight-medium mb-1">
                    {{ logUsuario.composicao }}
                  </div>
                  <div>
                    {{ logUsuario.ref_externa }}
                  </div>
                </v-col>

                <v-col cols="12" sm="2" class="text-right">
                  <div
                    class="font-weight-medium mb-1 status-contrato-fonte"
                    :class="logUsuario.status"
                  >
                    {{ logUsuario.status | statusContrato }}
                  </div>
                  <div class="text-body-2">
                    <span v-if="logUsuario.parcelas > 0"
                      >{{ logUsuario.parcelas }}
                      {{
                        logUsuario.parcelas > 1 ? "parcelas" : "parcela"
                      }}</span
                    >
                    <span v-else>Sem parcelas</span>
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-list-item
            :to="{ name: 'VerCliente', params: { id: logUsuario.id } }"
            :key="logUsuario.tipo + logUsuario.id"
            v-if="logUsuario.tipo == 'cliente'"
          >
            <v-list-item-content>
              <v-row class="pt-2">
                <v-col cols="12" sm="3">
                  <div class="font-weight-bold mb-1">
                    {{ logUsuario.nome }}
                  </div>
                  <div class="text--secondary">
                    {{ logUsuario.documento | documentoCliente }}
                  </div>
                </v-col>

                <v-spacer></v-spacer>

                <v-col cols="12" sm="2" class="text-right">
                  <div
                    class="font-weight-medium mb-1 subtitle-1"
                    style="color: #716868 !important"
                  >
                    {{ numeroContratos(logUsuario.contratos) }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
          <v-divider :key="index"></v-divider>
        </template>
      </v-list>
    </v-card>

    <FloatingMultipleBtn v-if="empresaSelecionada.fake">
      <template v-slot:content>
        <v-chip
          class="text-subtitle-2 white--text orange darken-2"
          @click="abrirFormulario('FINANCIAMENTO')"
        >
          Financiamento</v-chip
        >
        <v-chip
          class="text-subtitle-2 white--text blue darken-2 mx-2"
          @click="abrirFormulario('SEGURO')"
        >
          Seguro</v-chip
        >
        <v-chip
          class="text-subtitle-2 ml-2 red darken-2 white--text"
          @click="abrirFormulario('CONSORCIO')"
        >
          Consórcio
        </v-chip>
        <v-chip
          class="text-subtitle-2 ml-2 white--text green darken-2"
          @click="abrirFormulario('COBRANCA')"
        >
          Cobrança
        </v-chip>
        <v-chip
          class="text-subtitle-2 white--text grey"
          @click="abrirFormulario('GENERICO')"
        >
          Genérico
        </v-chip>
      </template>
    </FloatingMultipleBtn>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import logUsuario from "@/services/log-usuario";
import FloatingMultipleBtn from "../../components/comum/FloatingMultipleBtn.vue";

export default {
  components: {
    FloatingMultipleBtn,
  },
  data() {
    return {
      erroPesquisa: false,
      pesquisando: false,
      filtros: {
        consulta: null,
      },
      ultimosContratosAcessados: [],
      floatingBtn: false,
    };
  },
  async created() {},
  mounted() {
    this.filtros.consulta = this.$route.query.consulta;
    this.pesquisarLogsAcesso();

    if (this.filtros.consulta) {
      this.pesquisar();
    }
  },
  beforeRouteUpdate(to, from, next) {
    this.pesquisar();
    return next();
  },
  methods: {
    ...mapActions("contratos", {
      getContratos: "getContratos",
      limparContratos: "limparContratos",
    }),
    ...mapActions("cliente", {
      getClientes: "getClientes",
      limparClientes: "limparClientes",
    }),
    ...mapActions("geral", {
      alteraResultadoPesquisa: "alteraResultadoPesquisa",
    }),
    abrirFormulario(tipo) {
      this.$router
        .push({ name: "ContratoFake", query: { tipo: tipo } })
        .catch(() => {});
    },
    limparPesquisa() {
      this.limparContratos();
      this.limparClientes();
      this.filtros.consulta = null;
      this.alteraResultadoPesquisa(null);
    },
    async pesquisar(evento) {
      if (evento) {
        evento.preventDefault();
      }

      if (this.pesquisando) {
        return false;
      }

      if (!this.filtros.consulta) {
        return false;
      }

      const params = {
        consulta: this.filtros.consulta,
        limite: 100,
      };

      this.$router
        .push({
          query: {
            consulta: this.filtros.consulta,
          },
        })
        .catch(() => {});

      this.pesquisando = true;
      this.erroPesquisa = false;

      try {
        await this.getClientes(params);
        await this.getContratos(params);
        this.ordenarContratos();
        this.alteraResultadoPesquisa(this.filtros.consulta);
      } catch (erro) {
        console.error("Não foi possível pesquisar os contratos.", erro);
        this.erroPesquisa = true;
      } finally {
        this.pesquisando = false;
      }
    },
    ordenarContratos() {
      if (!this.contratos.objects) return;
      this.contratos.objects.forEach((contrato) => {
        if (contrato.status == "ATIVO") {
          contrato["ordem"] = 1;
        } else if (contrato.status == "FINALIZADO") {
          contrato["ordem"] = 2;
        } else {
          contrato["ordem"] = 3;
        }
      });
      this.contratos.objects.sort((a, b) => (a.ordem > b.ordem ? 1 : -1));
    },
    /** Pesquisa pelos últimos contratos acessados. */
    pesquisarLogsAcesso() {
      let parametros = {
        limite: 10,
      };

      logUsuario
        .logUsuario(parametros)
        .then((resposta) => {
          this.ultimosContratosAcessados = resposta.data;
        })
        .catch((erro) => {
          console.error("Não foi possível pesquisar os logs de acesso.", erro);
        });
    },

    numeroContratos(contratos) {
      if (contratos == 0) {
        return "Nenhum contrato";
      } else if (contratos == 1) {
        return contratos + " contrato";
      } else {
        return contratos + " contratos";
      }
    },
  },
  computed: {
    ...mapGetters("contratos", {
      loading: "loading",
      contratos: "contratos",
    }),
    ...mapGetters("geral", {
      resultadoPesquisa: "resultadoPesquisa",
    }),
    ...mapGetters("cliente", {
      consultaClientes: "consultaClientes",
    }),
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
  watch: {
    empresaSelecionada: {
      handler() {
        this.pesquisarLogsAcesso();
        this.limparPesquisa();
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.formulario {
  max-width: 800px;
}
</style>
