<template>
  <div class="pa-8">
    <v-row class="graficos">
      <!-- gráfico CONTRATOS ATIVOS POR ORIGEM -->
      <v-col cols="6" class="grafico">
        <v-chart
          class="chart"
          :option="graficos.contratos_ativos_por_origem"
          :loading="graficos.contratos_ativos_por_origem.carregando"
          :loadingOptions="OpcoesLoading"
          v-if="!graficos.contratos_ativos_por_origem.erro"
        />
        <div class="msg-erro" v-if="graficos.contratos_ativos_por_origem.erro">
          <v-alert
            type="error"
            class="alerta-erro"
          >
            Ocorreu um erro.
          </v-alert>
          <v-btn class="tentar-novamente" color="primary" @click="contratos_ativos_por_origem">Tente novamente</v-btn>
        </div>
      </v-col>

      <!-- gráfico TAXA DE PAGAMENTO -->
      <v-col cols="6" class="grafico">
        <v-chart
          class="chart"
          :option="graficos.taxa_de_pagamento"
          :loading="graficos.taxa_de_pagamento.carregando"
          v-if="!graficos.taxa_de_pagamento.erro"
          :loadingOptions="OpcoesLoading"
        />
        <div class="msg-erro" v-if="graficos.taxa_de_pagamento.erro">
          <v-alert
            type="error"
            class="alerta-erro"
          >
            Ocorreu um erro.
          </v-alert>
          <v-btn class="tentar-novamente" color="primary" @click="taxa_de_pagamento">Tente novamente</v-btn>
        </div>
      </v-col>

      <!-- gráfico QUANTIDADE DE CONTRATOS -->
      <v-col cols="6" class="grafico">
        <v-chart
          class="chart"
          :option="graficos.quantidade_de_contratos"
          :loading="graficos.quantidade_de_contratos.carregando"
          v-if="!graficos.quantidade_de_contratos.erro"
          :loadingOptions="OpcoesLoading"
        />
        <div class="msg-erro" v-if="graficos.quantidade_de_contratos.erro">
          <v-alert
            type="error"
            class="alerta-erro"
          >
            Ocorreu um erro.
          </v-alert>
          <v-btn class="tentar-novamente" color="primary" @click="quantidade_de_contratos">Tente novamente</v-btn>
        </div>
      </v-col>

    <!-- gráfico PERFIL DE CONTATO -->
    <v-col cols="6" class="grafico">
      <v-chart
        class="chart"
        :option="graficos.perfil_de_contato"
        :loading="graficos.perfil_de_contato.carregando"
        v-if="!graficos.perfil_de_contato.erro"
        :loadingOptions="OpcoesLoading"
      />
      <div class="msg-erro" v-if="graficos.perfil_de_contato.erro">
        <v-alert
          type="error"
          class="alerta-erro"
        >
          Ocorreu um erro.
        </v-alert>
        <v-btn class="tentar-novamente" color="primary" @click="perfil_de_contato">Tente novamente</v-btn>
      </div>
    </v-col>

    <!-- gráfico TIPO DE CONTRATO -->
    <v-col cols="6" class="grafico">
      <v-chart
        class="chart"
        :option="graficos.tipo_de_contrato"
        :loading="graficos.tipo_de_contrato.carregando"
        v-if="!graficos.tipo_de_contrato.erro"
        :loadingOptions="OpcoesLoading"
      />
      <div class="msg-erro" v-if="graficos.tipo_de_contrato.erro">
        <v-alert
          type="error"
          class="alerta-erro"
        >
          Ocorreu um erro.
        </v-alert>
        <v-btn class="tentar-novamente" color="primary" @click="tipo_de_contrato">Tente novamente</v-btn>
      </div>
    </v-col>

    <!-- gráfico QUANTIDADE DE PARCELAS -->
    <v-col cols="6" class="grafico">
      <v-chart
        class="chart"
        :option="graficos.quantidade_de_parcelas"
        :loading="graficos.quantidade_de_parcelas.carregando"
        v-if="!graficos.quantidade_de_parcelas.erro"
        :loadingOptions="OpcoesLoading"
      />
      <div class="msg-erro" v-if="graficos.quantidade_de_parcelas.erro">
        <v-alert
          type="error"
          class="alerta-erro"
        >
          Ocorreu um erro.
        </v-alert>
        <v-btn class="tentar-novamente" color="primary" @click="quantidade_de_parcelas">Tente novamente</v-btn>
      </div>
    </v-col>

    <!-- gráfico CATEGORIA DE CONTRATO -->
    <v-col cols="6" class="grafico">
      <v-chart
        class="chart"
        :option="graficos.categoria_de_contrato"
        :loading="graficos.categoria_de_contrato.carregando"
        v-if="!graficos.categoria_de_contrato.erro"
        :loadingOptions="OpcoesLoading"
      />
      <div class="msg-erro" v-if="graficos.categoria_de_contrato.erro">
        <v-alert
          type="error"
          class="alerta-erro"
        >
          Ocorreu um erro.
        </v-alert>
        <v-btn class="tentar-novamente" color="primary" @click="categoria_de_contrato">Tente novamente</v-btn>
      </div>
    </v-col>

    <!-- gráfico RISCO DE CONTRATO -->
    <v-col cols="6" class="grafico">
      <v-chart
        class="chart"
        :option="graficos.risco_de_contrato"
        :loading="graficos.risco_de_contrato.carregando"
        v-if="!graficos.risco_de_contrato.erro"
        :loadingOptions="OpcoesLoading"
      />
      <div class="msg-erro" v-if="graficos.risco_de_contrato.erro">
        <v-alert
          type="error"
          class="alerta-erro"
        >
          Ocorreu um erro.
        </v-alert>
        <v-btn class="tentar-novamente" color="primary" @click="risco_de_contrato">Tente novamente</v-btn>
      </div>
    </v-col>

      <!-- gráfico DIRETORIA DE CONTRATO -->
      <v-col cols="6" class="grafico">
        <v-chart
          class="chart"
          :option="graficos.diretoria_de_contrato"
          :loading="graficos.diretoria_de_contrato.carregando"
          v-if="!graficos.diretoria_de_contrato.erro"
          :loadingOptions="OpcoesLoading"
        />
        <div class="msg-erro" v-if="graficos.diretoria_de_contrato.erro">
          <v-alert
            type="error"
            class="alerta-erro"
          >
            Ocorreu um erro.
          </v-alert>
          <v-btn class="tentar-novamente" color="primary" @click="diretoria_de_contrato">Tente novamente</v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import graficos from "@/services/grafico";

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
]);
export default {
  name: "HelloWorld",
  components: {
    VChart,
  },
  provide: {},
  data() {
    return {
      OpcoesLoading: {
        text: "Carregando gráfico...",
        color: "#4ea397",
        maskColor: "rgba(255, 255, 255, 0.4)",
      },
      graficos: {
        contratos_ativos_por_origem: {
          carregando: true,
          erro: false,
          title: {
            text: "Contratos Ativos por Origem",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        tipo_de_contrato: {
          carregando: true,
          erro: false,
          title: {
            text: "Tipo de Contrato",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        quantidade_de_contratos: {
          carregando: true,
          erro: false,
          title: {
            text: "Quantidade de Contratos",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        categoria_de_contrato: {
          carregando: true,
          erro: false,
          title: {
            text: "Categoria dos Contratos",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        diretoria_de_contrato: {
          carregando: true,
          erro: false,
          title: {
            text: "Diretoria dos Contratos",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        risco_de_contrato: {
          carregando: true,
          erro: false,
          title: {
            text: "Risco dos Contratos",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        quantidade_de_parcelas: {
          carregando: true,
          erro: false,
          title: {
            text: "Quantidade de Parcelas dos Contratos",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        taxa_de_pagamento: {
          carregando: true,
          erro: false,
          title: {
            text: "Taxa de Pagamento (tolerância últimos 7 dias)",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
        perfil_de_contato: {
          carregando: true,
          erro: false,
          title: {
            text: "Perfil de Contato",
            left: "center",
          },
          tooltip: {
            trigger: "item",
            formatter: "{c} ({d}%)",
          },
          legend: {
            orient: "vertical",
            left: "left",
          },
          series: [],
        },
      },
    };
  },
  mounted() {
    this.contratos_ativos_por_origem();
    this.tipo_de_contrato();
    this.quantidade_de_contratos();
    this.categoria_de_contrato();
    this.diretoria_de_contrato();
    this.risco_de_contrato();
    this.quantidade_de_parcelas();
    this.taxa_de_pagamento();
    this.perfil_de_contato();
  },
  methods: {
    contratos_ativos_por_origem() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "contratos_ativos_por_origem")
        .then((resposta) => {
          this.graficos.contratos_ativos_por_origem.series.push({
            name: "Origem",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.contratos_ativos_por_origem.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.contratos_ativos_por_origem.series[0].data.push({
              value: serie.total,
              name: serie.origem,
            });
          });
          this.graficos.contratos_ativos_por_origem.erro = false;
        })
        .catch((erro) => {
          this.graficos.contratos_ativos_por_origem.erro = true;
        });
    },
    tipo_de_contrato() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "tipo_de_contrato")
        .then((resposta) => {
          this.graficos.tipo_de_contrato.series.push({
            name: "Tipo de Contrato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.tipo_de_contrato.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.tipo_de_contrato.series[0].data.push({
              value: serie.total,
              name: serie.tipo,
            });
          });
          this.graficos.tipo_de_contrato.erro = false;
        })
        .catch((erro) => {
          this.graficos.tipo_de_contrato.erro = true;
        });
    },
    quantidade_de_contratos() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "quantidade_de_contratos")
        .then((resposta) => {
          this.graficos.quantidade_de_contratos.series.push({
            name: "Quantidade de Contrato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.quantidade_de_contratos.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.quantidade_de_contratos.series[0].data.push({
              value: serie.clientes,
              name: serie.qtde_contratos,
            });
          });
          this.graficos.quantidade_de_contratos.erro = false;
        })
        .catch((erro) => {
          this.graficos.quantidade_de_contratos.erro = true;
        });
    },
    categoria_de_contrato() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "categoria_de_contrato")
        .then((resposta) => {
          this.graficos.categoria_de_contrato.series.push({
            name: "Categoria de Contrato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.categoria_de_contrato.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.categoria_de_contrato.series[0].data.push({
              value: serie.total,
              name: serie.categoria,
            });
          });
          this.graficos.categoria_de_contrato.erro = false;
        })
        .catch((erro) => {
          this.graficos.categoria_de_contrato.erro = true;
        });
    },
    diretoria_de_contrato() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "diretoria_de_contrato")
        .then((resposta) => {
          this.graficos.diretoria_de_contrato.series.push({
            name: "Diretoria de Contrato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.diretoria_de_contrato.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.diretoria_de_contrato.series[0].data.push({
              value: serie.total,
              name: serie.dimensao_4,
            });
          });
          this.graficos.diretoria_de_contrato.erro = false;
        })
        .catch((erro) => {
          this.graficos.diretoria_de_contrato.erro = true;
        });
    },
    risco_de_contrato() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "risco_de_contrato")
        .then((resposta) => {
          this.graficos.risco_de_contrato.series.push({
            name: "Risco de Contrato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.risco_de_contrato.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.risco_de_contrato.series[0].data.push({
              value: serie.total,
              name: serie.risco,
            });
          });
          this.graficos.risco_de_contrato.erro = false;
        })
        .catch((erro) => {
          this.graficos.risco_de_contrato.erro = true;
        });
    },
    quantidade_de_parcelas() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "quantidade_de_parcelas")
        .then((resposta) => {
          this.graficos.quantidade_de_parcelas.series.push({
            name: "Quantidade de Parcelas",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.quantidade_de_parcelas.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.quantidade_de_parcelas.series[0].data.push({
              value: serie.total,
              name: serie.numero_de_parcelas,
            });
          });
          this.graficos.quantidade_de_parcelas.erro = false;
        })
        .catch((erro) => {
          this.graficos.quantidade_de_parcelas.erro = true;
        });
    },
    taxa_de_pagamento() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "taxa_de_pagamento")
        .then((resposta) => {
          this.graficos.taxa_de_pagamento.series.push({
            name: "Taxa de Pagamento",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.taxa_de_pagamento.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.taxa_de_pagamento.series[0].data.push({
              value: serie.sum,
              name: serie.status,
            });
          });
          this.graficos.taxa_de_pagamento.erro = false;
        })
        .catch((erro) => {
          this.graficos.taxa_de_pagamento.erro = true;
        });
    },
    perfil_de_contato() {
      let empresa = this.$store.state.empresa;
      graficos
        .buscar_grafico(empresa.url_instancia, "perfil_de_contato")
        .then((resposta) => {
          this.graficos.perfil_de_contato.series.push({
            name: "Perfil de Contato",
            type: "pie",
            radius: "55%",
            center: ["50%", "60%"],
            data: [],
          });

          this.graficos.perfil_de_contato.carregando = false;
          resposta.data.forEach((serie) => {
            this.graficos.perfil_de_contato.series[0].data.push({
              value: serie.clientes,
              name: serie.tipos_contato,
            });
          });
          this.graficos.perfil_de_contato.erro = false;
        })
        .catch((erro) => {
          this.graficos.perfil_de_contato.erro = true;
        });
    },
  },
};
</script>

<style lang="scss">
.chart {
  height: 250px;
}
.graficos {
  .grafico {
    background-color: #fff;
    border: 1px solid #edf2f5;
    border-radius: 6px;
    box-shadow: none;
    margin: 8px;
  }
}
.msg-erro {
  margin-left: 300px;
  margin-top: 70px;
  .alerta-erro {
    width: 200px;
    margin-bottom: 5px;
  }
  .tentar-novamente{
    margin-left: 8px;
  }
}
</style>
