<template>
  <!-- GRAFICO PIZZA - CONTRATOS POR RISCO -->
  <v-card class="rounded-lg d-flex align-center mt-7 tamanhoCardGrafico">
    <div v-if="carregandoContrato" class="mx-auto">
      <v-progress-circular
        :size="50"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <v-col
      v-if="
        contratos?.length > 0 && !erroCarregarContrato && !carregandoContrato
      "
    >
      <v-chart class="chart" :option="grafico.risco_contrato" />
    </v-col>

    <v-alert
      style="width: fit-content"
      type="error"
      outlined
      class="text-center mx-10"
      v-if="erroCarregarContrato && !carregandoContrato"
    >
      Não foi possível carregar!
      <v-btn
        color="primary"
        class="ml-1 mt-2"
        @click="carregaContratosPorRisco(dataSelecionada)"
      >
        Tentar novamente
      </v-btn>
    </v-alert>

    <v-alert
      type="info"
      class="mx-auto"
      outlined
      style="width: fit-content"
      v-if="
        contratos?.length == 0 && !erroCarregarContrato && !carregandoContrato
      "
    >
      Nenhum dado para a data selecionada.
    </v-alert>
  </v-card>
</template>

<script>
import dashboardService from "@/services/dashboards";
import { mapGetters } from "vuex";

export default {
  props: {
    dataSelecionada: null,
  },
  data: () => ({
    grafico: {
      risco_contrato: {
        carregando: true,
        erro: false,
        title: {
          text: "Contrato por Risco",
          left: "center",
        },
        tooltip: {
          trigger: "item",
          formatter: "{b} - {c} ({d}%)",
        },
        series: [
          {
            type: "pie",
            radius: "55%",
            center: ["58%", "58%"],
            data: [],
          },
        ],
      },
    },

    contratos: null,
    carregandoContrato: false,
    erroCarregarContrato: false,
  }),
  async created() {
    if (!this.dataSelecionada) {
      this.carregaContratosPorRisco("ultima_atualizacao");
    } else {
      this.carregaContratosPorRisco(this.dataSelecionada);
    }
  },
  methods: {
    async carregaContratosPorRisco(data) {
      this.carregandoContrato = true;
      let payload = {
        empresa_id: this.empresaSelecionada.id,
        data_referencia: data,
        parametro: "risco",
      };

      try {
        const { data } = await dashboardService.getCarteiraClientes(payload);
        this.contratos = data.risco;
        this.adicionaValorGrafico();
        this.carregandoContrato = false;
        this.erroCarregarContrato = false;
      } catch {
        this.carregandoContrato = false;
        this.erroCarregarContrato = true;
      }
    },
    adicionaValorGrafico() {
      this.grafico.risco_contrato.series[0].data = [];
      this.contratos?.forEach((contrato) => {
        this.grafico.risco_contrato.series[0].data.push({
          value: contrato.total,
          name: contrato.nome,
        });
      });
    },
  },
  watch: {
    dataSelecionada() {
      this.carregaContratosPorRisco(this.dataSelecionada);
    },
    empresaSelecionada: {
      deep: true,
      handler() {
        this.carregaContratosPorRisco(this.dataSelecionada);
      },
    },
  },
  computed: {
    ...mapGetters("empresa", {
      empresaSelecionada: "empresaSelecionada",
    }),
  },
};
</script>
<style scoped lang="scss">
.tamanhoCardGrafico {
  height: 260px;
  width: 423px !important;
}

.chart {
  height: 230px;
  width: 350px;
}
</style>
