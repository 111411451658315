import http from "@/plugins/axios";

const VERSION = "/v1";

export default {
  getCarteiraClientes(params) {
    return http.apiDados.get(`/v1/carteira_clientes`, { params });
  },
  getTotal(params) {
    return http.apiDados.get(`/v1/carteira_clientes/total`, {params});
  },
  getContratosPorPlano(params) {
    return http.apiDados.get(`/v1/carteira_clientes/contratos_por_plano`, {params})
  }
};