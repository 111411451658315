var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(!_vm.erroCarregarResumoContratos && _vm.resumoContratos)?_c('v-card',{staticClass:"table-wraper mx-auto mt-10 mb-2"},[(_vm.resumoContratos)?_c('v-card-title',[_vm._v(" Contratos Ativos "),_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","loading":_vm.carregandoResumoContratos},on:{"click":function($event){return _vm.$emit('recarregarTabela', true)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"color":"black"}},[_vm._v(" mdi-cached ")])],1)]}}],null,false,372112482)},[_c('span',[_vm._v("Recarregar")])])],1):_vm._e(),_c('v-data-table',{staticClass:"tabela",attrs:{"hide-default-footer":"","headers":_vm.cabecalhoContratos,"items":_vm.linhasTabelaFiltrado,"no-data-text":"Nenhum conteúdo para ser mostrado!","loading":_vm.carregandoResumoContratos,"items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [_c('tr',_vm._l((item),function(quantidadeContratos,chave,index){return _c('td',{key:index,class:{ 'text-center': chave != 'perfil_contrato' },on:{"mouseover":function($event){return _vm.selecionaItem(chave, item)},"mouseleave":function($event){return _vm.desmarcaItem()}}},[(chave == 'total' && item.perfil_contrato != 'total')?_c('strong',[_vm._v(" "+_vm._s(item.total.toLocaleString("pt-BR") || 0)+" ")]):_vm._e(),(item.perfil_contrato == 'total')?_c('strong',{class:{ 'text-h5 font-weight-bold': chave == 'total' }},[_vm._v(" "+_vm._s(_vm.verificaCampoTotal(item, chave))+" ")]):_vm._e(),(chave == 'perfil_contrato')?_c('strong',{staticStyle:{"white-space":"nowrap"}},[_vm._v(" "+_vm._s(_vm._f("perfilContrato")(item.perfil_contrato))+" ")]):_c('div',{staticClass:"d-flex justify-center align-center",class:{
                'perfil-sem-regua':
                  _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length ==
                    0 &&
                  chave != 'total' &&
                  item.perfil_contrato != 'total',
              },staticStyle:{"position":"relative"}},[(chave != 'total' && item.perfil_contrato != 'total')?_c('p',{staticClass:"ma-0 pa-0 mx-auto"},[_vm._v(" "+_vm._s(quantidadeContratos.toLocaleString("pt-BR") || 0)+" ")]):_vm._e(),(
                  _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length >
                    0 &&
                  _vm.checkCredentials(['admin', 'editor_regua']) &&
                  chave != 'total' &&
                  item.perfil_contrato != 'total'
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"icone-quantidade-contratos",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.abrirDialogLinkarRegua(
                        item.perfil_contrato,
                        chave,
                        true
                      )}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular régua")])]):_vm._e(),(
                  _vm.filtrarReguasPerfil(item.perfil_contrato, chave).length ==
                    0 &&
                  _vm.checkCredentials(['admin', 'editor_regua']) &&
                  chave != 'total' &&
                  item.perfil_contrato != 'total' &&
                  item === _vm.itemSelecionado &&
                  chave === _vm.chaveSelecionada
                )?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn',_vm._g(_vm._b({staticClass:"icone-quantidade-contratos",attrs:{"icon":"","color":"primary"},on:{"click":function($event){return _vm.abrirDialogLinkarRegua(item.perfil_contrato, chave)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"22"}},[_vm._v("mdi-link-variant")])],1)]}}],null,true)},[_c('span',[_vm._v("Vincular régua")])]):_vm._e()],1)])}),0)]}}],null,false,412721045)})],1):_vm._e(),_c('DialogLinkarRegua',{ref:"dialogLinkarRegua",on:{"filtrarReguasPerfil":_vm.filtrarReguasPerfil}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }