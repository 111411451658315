<template>
  <v-dialog
    v-model="dialog"
    v-if="historico"
    width="500"
    @click:outside="$emit('fechar')"
  >
    <v-card>
      <v-card-title class="text-h5 blue-grey lighten-5">
        Histórico {{ title }}
        <v-spacer></v-spacer>
        <v-btn class="botao-fechar" text @click="$emit('fechar')">
          <v-icon style="font-size: 26px">mdi-close</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pt-5">
        <v-list-item
          v-for="(item, index) in historico"
          :key="item.status + index"
          class="pa-0"
        >
          <v-list-item-content>
            <div class="d-flex flex-row justify-space-between">
              <div class="d-flex flex-column">
                <span>{{ item.atualizado_em | dataLocal("L LT") }}</span>
                <span class="caption">{{ item.atualizado_por }}</span>
              </div>
              <div class="d-flex justify-end align-center font-weight-bold">
                <v-chip
                  class="status-contrato"
                  :class="{ [item.status]: item.status }"
                >
                  {{ item.status | statusContrato }}
                </v-chip>
              </div>
            </div>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from "moment";
export default {
  data() {
    return {
      moment: moment,
    };
  },
  props: ["dialog", "historico", "title"],
};
</script>

<style lang="scss" scoped>
.botao-fechar {
  padding: 0 !important;
  min-width: 40px !important;
}
</style>
