import { render, staticRenderFns } from "./ReguaExpansionTesteAb.vue?vue&type=template&id=68f4d14c&scoped=true"
import script from "./ReguaExpansionTesteAb.vue?vue&type=script&lang=js"
export * from "./ReguaExpansionTesteAb.vue?vue&type=script&lang=js"
import style0 from "./ReguaExpansionTesteAb.vue?vue&type=style&index=0&id=68f4d14c&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "68f4d14c",
  null
  
)

export default component.exports