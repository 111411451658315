import { render, staticRenderFns } from "./ContratoPorRisco.vue?vue&type=template&id=14723159&scoped=true"
import script from "./ContratoPorRisco.vue?vue&type=script&lang=js"
export * from "./ContratoPorRisco.vue?vue&type=script&lang=js"
import style0 from "./ContratoPorRisco.vue?vue&type=style&index=0&id=14723159&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14723159",
  null
  
)

export default component.exports