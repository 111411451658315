<template>
  <v-dialog  
    scrollable
    width="600"
    @keydown.esc="fecharDialog()"
    @click:outside="fecharDialog()"
    v-model="dialog"
  >
    <v-form lazy-validation ref="form" class="d-flex">
      <v-card class="d-flex flex-column elevation-0 rounded-0 card-pre-visualizar">
        <v-card-title class="text-h5 title-card">
          Pré-visualização Iniciativa de Whatsapp
        </v-card-title>
        <v-card-text>
          <IniciativaWhatsapp :iniciativa="iniciativa"/>
        </v-card-text>
        <v-divider></v-divider>
        <div class="d-flex">
          <v-spacer></v-spacer>
          <v-card-actions>
            <v-btn color="primary" text @click="fecharDialog()"> FECHAR </v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import IniciativaWhatsapp from "@/views/reguas/components/previewIniciativaWhatsapp/PreviewIniciativaWhatsapp.vue";
export default {
  components: {
    IniciativaWhatsapp
  },
  props: {
    iniciativa: null,
  },
  data: () => ({
    dialog: false,
  }),
  methods: {
    abrirDialog() {
      this.dialog = true;
    },
    fecharDialog() {
      this.dialog = false;
    },
  },
};
</script>

<style scoped lang="scss">
.card-pre-visualizar{
  width: 570px;
  min-height: 85vh;
}
::-webkit-scrollbar {
  width: 4px;
  margin-left: 2px;
}

::-webkit-scrollbar-track {
  background: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #bdbdbd;
  border-radius: 25px;
  padding-right: 0px;
}
</style>
